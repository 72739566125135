import * as React from 'react';
import styles from './add.module.css';
import axios from 'axios';
import { useForm, Controller,useFieldArray } from "react-hook-form";
import { Plus, Trash2 } from 'react-feather';
import {isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import Select,{ components } from "react-select";
import {authData} from "../../../admin/components/getAuth";
import Autocomplete from "react-google-autocomplete";
import MapComponent from '../../../frontend/components/Map';

function Step2({ saveStep2Data,backToTab,bookingData }) {
  const [defaultAddr, setDefaultAddr] = React.useState({});
  const [userAddressList, setUserAddressList] = React.useState([]);
  const [mapCenter, setMapCenter] = React.useState({ lat: 51.509865, lng: -0.118092 });
  const [isMarker, setIsMarker] = React.useState(false);
  const [zoom, setZoom] = React.useState(12);
  const { register, handleSubmit,control, formState: { errors },setValue } = useForm({
    mode: 'onChange'
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "alt_contact_nos"
  });

  React.useEffect(()=>{
    setValue(`name`,bookingData?.pickUpData?.name);
    setValue(`email`,bookingData?.pickUpData?.email);
    setValue(`phone`,bookingData?.pickUpData?.phone);
    setValue(`address`,bookingData?.pickUpData?.address);
    setValue(`post_code`,bookingData?.pickUpData?.post_code);
    setValue(`latitude`,parseFloat(bookingData?.pickUpData?.latitude));
    setValue(`longitude`,parseFloat(bookingData?.pickUpData?.longitude));
    setDefaultAddr(bookingData?.pickUpData?.addressObj);

    if(bookingData?.pickUpData?.alt_contact_nos && bookingData?.pickUpData?.alt_contact_nos?.length){
      replace(bookingData?.pickUpData?.alt_contact_nos);
    }

    if(bookingData?.pickUpData?.latitude && bookingData?.pickUpData?.longitude){
      setMapCenter({ lat: parseFloat(bookingData?.pickUpData?.latitude), lng: parseFloat(bookingData?.pickUpData?.longitude) });
      setIsMarker(true);
      setZoom(18);
    }

  },[bookingData,setValue,replace]);

  React.useState(()=>{
    if(authData()?.customer?.id){
      axios.get(process.env.REACT_APP_API_URL + 'v1/adresses/'+authData()?.customer?.id+'/pickup').then(response => {
        if(response.data.status === 'SUCCESS'){
          let addrListTemp = response.data.data;
          if(!bookingData?.pickUpData){
            let defaultAddrTemp = addrListTemp[0];
            setValue(`name`,defaultAddrTemp?.label);
            setValue(`email`,defaultAddrTemp?.email);
            setValue(`phone`,defaultAddrTemp?.phone);
            setValue(`address`,defaultAddrTemp?.address);
            setValue(`post_code`,defaultAddrTemp?.post_code);
            setValue(`latitude`,parseFloat(defaultAddrTemp?.latitude));
            setValue(`longitude`,parseFloat(defaultAddrTemp?.longitude));
            if(defaultAddrTemp?.alt_phones && defaultAddrTemp?.alt_phones?.length){
              replace(defaultAddrTemp?.alt_phones);
            }
            setDefaultAddr(defaultAddrTemp);

            if(defaultAddrTemp?.latitude && defaultAddrTemp?.longitude){
              setMapCenter({ lat: parseFloat(defaultAddrTemp?.latitude), lng: parseFloat(defaultAddrTemp?.longitude) });
              setIsMarker(true);
              setZoom(18);
            }
          }
          addrListTemp = [{value: -1, label:'Select or add record'}].concat(addrListTemp);
          setUserAddressList(addrListTemp);
        }
      });
    }
  },[]);

  const onSubmit = (data) =>{
    data = {...data, addressObj:defaultAddr };
    saveStep2Data(data);
  }

  const Option = props => {
    return (
      <components.Option {...props}>
        <div>{props.data.label}</div>
        <div style={{ fontSize: 12 }}>{props.data.address}</div>
      </components.Option>
    );
  };

  const chnageAdrsOption = (e)=>{
    setDefaultAddr(e);

    if(e?.value === -1){
      setValue(`name`, '');
      setValue(`email`, '');
      setValue(`phone`, '');
      setValue(`address`, '');
      setValue(`post_code`, '');
      setIsMarker(false);
      setZoom(18);

      fields.forEach((item,index)=>{
        remove(index);
      });
    }else{
      setValue(`name`,e?.label);
      setValue(`email`,e?.email);
      setValue(`phone`,e?.phone);
      setValue(`address`,e?.address);
      setValue(`post_code`,e?.post_code);
      setValue(`latitude`,parseFloat(e?.latitude));
      setValue(`longitude`,parseFloat(e?.longitude));
      if(e?.alt_phones && e?.alt_phones?.length){
        replace(e?.alt_phones);
      }
      if(e?.latitude && e?.longitude){
        setMapCenter({ lat: parseFloat(e?.latitude), lng: parseFloat(e?.longitude) });
        setIsMarker(true);
        setZoom(18);
      }
    }

  }

  const removePhn = (e,index)=>{
    remove(index);
  }
  
  return (
    <><div className={`${styles.StepOneForm}`}>
      <form id="step2-form" onSubmit={handleSubmit(onSubmit)}>
        <div className={`${styles.StepOneFormRow}`}>
          <div className={`${styles.StepOneFormBig}`}>
            <div className={`${styles.FormFieldFull}`}>
              <label>Select or add record</label>
              <Select 
                onChange={(e)=> chnageAdrsOption(e)}
                options={userAddressList}
                components={{ Option }}
                className={`${styles.CustomSelect}`}
                value={defaultAddr}
              />
            </div>
            <div className={`${styles.FormFieldFull}`}>
              <label>Name<span>*</span></label>
              <input type="text" placeholder="" className={`${styles.TextField}`} {...register('name',{required:'Please enter name'})} />
              {errors.name && <p className={`${styles.ErrorM}`}>{errors.name.message}</p>}
            </div>
            <div className={`${styles.FormFieldFull}`}>
              <label>Email Address<span>*</span></label>
              <input type="text" placeholder="" className={`${styles.TextField}`} {...register('email',{required:'Please enter email',pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
              }})} />
              {errors.email && <p className={`${styles.ErrorM}`}>{errors.email.message}</p>}
            </div>
            <div className={`${styles.FormFieldFull}`}>
              <label>Contact No<span>*</span></label>
              <Controller
                control={control}
                {...register('phone',{required:'Please enter phone no',validate:isValidPhoneNumber})}
                className={`${styles.TextField}`}
                render={({ field }) => (
                    <Input 
                      {...field}
                      country="GB"
                      international
                      withCountryCallingCode
                      className={`${styles.TextField}`}
                    />
                  )}
                />
              {(errors.phone && errors.phone.type !== "validate") && <p className={`${styles.ErrorM}`}>{errors.phone.message}</p>}
              {(errors.phone && errors.phone.type === "validate") && <p className={`${styles.ErrorM}`}>Please enter valid phone</p>}
            </div>
            {fields.map((item, index) => {
              return (<div className={`${styles.FormFieldFull}`} key={item.id}>
                    <label>Alt Contact No</label>
                    <div className={`${styles.AltConSec}`}>
                    <Controller
                      key={item.id}
                      control={control}
                      {...register(`alt_contact_nos[${index}].value`,{required:'Please enter phone no',validate:isValidPhoneNumber})}
                      className={`${styles.TextField}`}
                      render={({ field }) => (
                          <Input 
                            {...field}
                            country="GB"
                            international
                            withCountryCallingCode
                            className={`${styles.TextField}`}
                          />
                        )}
                      />
                      <button type='button' onClick={(e)=>removePhn(this,index)} className={`${styles.ConNoBU}`}><Trash2/></button>
                    </div>
                  {(errors?.alt_contact_nos?.length > 0 && errors?.alt_contact_nos[index]?.value && errors?.alt_contact_nos[index]?.value?.type !== "validate") && <p className={`${styles.ErrorM}`}>{errors?.alt_contact_nos[index]?.value?.message}</p>}
                  {(errors?.alt_contact_nos?.length > 0 && errors?.alt_contact_nos[index]?.value && errors?.alt_contact_nos[index]?.value?.type === "validate") && <p className={`${styles.ErrorM}`}>Please enter valid phone</p>}
                </div>)
            })}
            <button type='button' onClick={() => { append({ value: '' }); }} className={`${styles.AddAltConNO}`}><Plus/> Alt contact no</button>
            <div className={`${styles.FormFieldFull} ${styles.AddAnoPadd}`}>
              <label>Address<span>*</span></label>
              <Controller
                control={control}
                {...register(`address`,{required:'Please enter address'})}
                autoComplete="off"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    className={`${styles.TextField} ${styles.TextFieldAutoComplete}`}
                    apiKey={process.env.REACT_APP_MAP_API_KEY}
                    onPlaceSelected={(place) => {
                      setValue('address',place.formatted_address);
                      setValue('latitude',place.geometry.location.lat());
                      setValue('longitude',place.geometry.location.lng());

                     setMapCenter({ lat: parseFloat(place.geometry.location.lat()), lng: parseFloat(place.geometry.location.lng()) });
                     setIsMarker(true);
                     setZoom(18);

                      place.address_components.forEach(item=>{
                        if(item.types.indexOf('postal_code') > -1){
                          setValue('post_code',item.long_name);
                        }
                      })
                    }}
                    options={{
                      types: ["address"],
                      componentRestrictions: { country: "uk" },
                    }}
                  />
                )}
              />
            {(errors?.address) && <p className={`${styles.ErrorM}`}>{errors?.address?.message}</p>}
            </div>

            
          <div className={`${styles.FormRowHSFull}`}>
            <div className={`${styles.FormFieldFull}`}>
              <label>Post Code<span>*</span></label>
              <input type="text" className={`${styles.TextField}`} {...register('post_code',{required:'Please enter post code'})} />
              {errors.post_code && <p className={`${styles.ErrorM}`}>{errors.post_code.message}</p>}
            </div>
          </div>

          </div>
          
          <div className={`${styles.StepOneFormSmall2}`}>
            <div className={`${styles.FormFieldFull}`}>
              <label>Add pickup location<span>*</span></label>
              <MapComponent center={mapCenter} zoom={zoom} isMarker={isMarker} />
            </div>
            <div className={`${styles.FormFieldFull}`}>
              <div className={`${styles.FormInsideRow}`}>
                <input type="text" placeholder="Please enter post code" className={`${styles.TextField}`} />
                <button className={`${styles.FindLocationBU}`}>Find Location</button>
              </div>
            </div>
          </div>
          
          <div className={`${styles.StepBUSec}`}>
            <button type='button' onClick={backToTab} className={`${styles.Previous}`}>Back</button>
            <button  type='submit' className={`${styles.Next}`}>Next</button>
          </div>
          
        </div>
      </form>
    </div></>
  );
}

export default Step2;