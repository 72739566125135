import React, {useState,useEffect} from 'react';
import styles from './list.module.css';
import {  Link ,useParams} from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Typography, Button, Checkbox  } from '@mui/material';
import { Plus } from 'react-feather';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { TableLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import { WithoutThemeRouteList } from '../../../routes';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Select from "react-select";
import {Accordion,AccordionSummary,AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import withAdminAuth from "../../components/withAdminAuth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
const userTypeOptions= [
  { label:'UK Admin',value:'UK Admin'},
  {label:'Pickup Driver',value:'Pickup Driver'},
  {label:'GH Admin',value:'GH Admin'},
  {label:'GH Driver',value:'GH Driver'},
  {label:'Receptionist',value:'Receptionist'}
]

function AddPermission({ modalClose,addPermission }) {
  const auth = authData();
  const API_Token = auth?.api_token;
  let { id } = useParams();
  const isAddMode = !id;
  const [contentLoading, setContentLoading] = useState(true);
  const [userPermissionParentOptions, setUserPermissionParentOptions] = useState([{label:'Parent',value:0}]);
  
  let schema;
  schema = yup.object().shape({
    title: yup.string().required('This field is Required').max(50, 'Title cannot be more than 50 characters')
  });
  
  const { register, handleSubmit,control, watch,setValue,  formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange', defaultValues: {parent: {label:'Parent',value:0}}
  });
  
  useEffect(()=>{
    if(contentLoading){
      axios.get(process.env.REACT_APP_API_URL + 'v1/users-permission-parent-list', {
        headers: {
          Authorization: `Bearer ${API_Token}`
        }
      }).then(response => {
        setContentLoading(false);
        if(response.data.status === 'SUCCESS'){
          let itemData = response.data.data;
          let obj = userPermissionParentOptions;
          setUserPermissionParentOptions([...obj,...itemData]);
        }
      }).catch(error => {
        setContentLoading(false);
      });
    }
  },[isAddMode,id,setValue,contentLoading,userPermissionParentOptions,API_Token]);
  
  async function onSubmit(data) {
    addPermission(data);
  }
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`${styles.FormRow}`}>
        <div className={`${styles.FormGroupFull}`}>
          <label className={`${styles.FormLabel}`}>Title*</label>
          <input {...register("title")} placeholder='Title' className={`${styles.FormControl}`} />
          {errors.title && !watch().title && <span className={`${styles.ErrorM}`}>{errors.title.message}</span>}
        </div>
      </div>
      <div className={`${styles.FormRow}`}>
        <div className={`${styles.FormGroupFull}`}>
          <label className={`${styles.FormLabel}`}>Parent</label>
          <Controller
            control={control}
            options={userPermissionParentOptions}
            {...register('parent')}
            render={({ field }) => (
              <Select {...field} options={userPermissionParentOptions}  />
            )}
          />
        </div>
      </div>
      
      <div className={`${styles.FormButtonArea}`}>
        <Button className='PopUpCanBU' onClick={modalClose}>Cancel</Button>
        <Button className='PopUpSubmitBU'  type="submit" >Add</Button>
      </div>
    </form>
  )}

class UserPermissionList extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.state = { allData: [], data: [],filterText:'',modalShow:false,loading:false, contentLoading:true, currentItem:0  };

    this.app_folder_path = process.env.REACT_APP_FOLDER_PATH;
    this.authData = authData();
  }
  
  
  async componentDidMount() {
    try {
      axios(process.env.REACT_APP_API_URL + 'v1/users-permission-list', {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        }
      }).then(response => {
        if(response.data.status === 'SUCCESS'){
          let allData = response.data.data;
          this.setState({ ...this.state, allData: allData, data: allData,contentLoading:false});
        }else{
          this.setState({ ...this.state, contentLoading:false});
        }
      }).catch(error=>{
        this.setState({ ...this.state, contentLoading:false});
      });
    } catch (error) {
      this.setState({ ...this.state, contentLoading:false});
    }
  }

  statusChange(id,status){
    this.setState({...this.state,loading:true});
    axios.put(process.env.REACT_APP_API_URL + 'v1/users/'+id,{status:status}, {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        let allData = this.state.allData;
        let data = this.state.data;
        let allDataTemp = allData.map(i=> {
         if(i.id === id)
            return {...i,status:status}
          return i;
        });
        let dataTemp = data.map(i=> {
          if(i.id === id)
            return {...i,status:status}
          return i;
        });
        this.setState({ ...this.state, allData: allDataTemp, data: dataTemp,loading:false});
      }else{
        this.setState({...this.state,loading:false});
      }
    }).catch(error => {
      this.setState({...this.state,loading:false});
    });
  }

  modalOpen(){
    this.setState({...this.state,modalShow:WithoutThemeRouteList});
  }

  modalClose(){
    this.setState({...this.state,modalShow:false});
  }

  addPermission(data){
    const postData = data;
    this.setState({...this.state,loading:true});
    
    return axios.post(process.env.REACT_APP_API_URL + 'v1/users-permission-add', postData, {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        let resData = response.data.data;
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        let tempData = this.state.data;
        if(resData.parent > 0){
          tempData = tempData.map(item=>{
            if(item.id === resData.parent){
              let childI = item.children;
              childI.push({id:resData.id,title:resData.title,parent:resData.parent,permissions:[]});
              return {...item,children:childI};
            }
            return item;
          });
        }else{
          tempData.push({id:resData.id,title:resData.title,children:[]});
        }
        this.setState({...this.state,data:tempData,loading:false,modalShow:false});
      }else{
        this.setState({...this.state,loading:false});
      }
    }).catch(error => {
      this.setState({...this.state,loading:false});
    });
  }

  handleChange(itemId,uType,event){
    if(event.target.checked){
      axios.post(process.env.REACT_APP_API_URL + 'v1/add-role-permission', {permission_id:itemId,user_type:uType}, {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        }
      });
    }else{
      axios.post(process.env.REACT_APP_API_URL + 'v1/delete-role-permission', {permission_id:itemId,user_type:uType}, {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        }
      });
    }
  }


  render() {
    return (
      <>
      {this.state.loading && <><Loader />sdfsd</>}
      {this.state.contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>}
      {!this.state.contentLoading && <div className={`${styles.MainDiv}`}>
        <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>User Permissions</p>
        </div>
        
        <div className={`${styles.MainCard}`}>
          <div className='TableFilterHead'>
            <Link onClick={this.modalOpen.bind(this)}  className="TableAddBU"><Plus/></Link>
          </div>

          {this.state?.data?.map((item,index)=>{
            return <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={"panel"+index+"a-content"} id={"panel"+index+"a-header"}>
              <Typography>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {item?.children?.map((itemChildren,indexChildren)=>{
                return( <li style={{display:'flex'}} key={indexChildren}>
                  <strong style={{float:'left', marginRight:'30px'}}>{itemChildren.title}</strong>
                  {userTypeOptions?.map((itemUSerType,indexuserType)=>{
                    return( <div style={{float:'left'}}>
                      <span style={{marginLeft:'20px'}}>
                        <Checkbox defaultChecked={(itemChildren.permissions.indexOf(itemUSerType.value) > -1)} onChange={this.handleChange.bind(this,itemChildren.id,itemUSerType.value)} />
                        <label style={{marginLeft:'5px'}}>{itemUSerType.value}</label>
                      </span>
                    </div>)
                  })}
                </li>)
              })}  
            </AccordionDetails>
          </Accordion>
          })}
          
          

          {/*<Accordion>

        
        <div className="accordion custom-accordion" id="accordionMain" style={{margin:'15px'}}>
        {this.state?.data?.map((item,index)=>{
        return( 
        <div className="card" key={index}>
        <div className="card-header" id="heading0">
        <button className="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse${index}`} aria-expanded="true" aria-controls={`#collapse${index}`}>{item.title}</button>
        </div>
        <div id={`#collapse${index}`} className="collapse in show" aria-labelledby={`#collapse${index}`} aria-expanded="true">
          <div className="card-body">
          <ul className="SM-PerBodyUL">

        
          {item?.children?.map((itemChildren,indexChildren)=>{
        return( <li style={{display:'flex'}}>
        <strong style={{float:'left', marginRight:'30px'}}>{itemChildren.title}</strong>
        {userTypeOptions?.map((itemUSerType,indexuserType)=>{
        return( <div style={{float:'left'}}>
          <span style={{marginLeft:'20px'}}>
        <input className="assignRoleChk" checked="" type="checkbox" data-perm-id="14" data-role-id="1" style={{width:'auto'}} />
        <label style={{marginLeft:'5px'}}>{itemUSerType.value}</label>
        </span>
        </div>)
         })}  
        
        </li>)
        })}  

        </ul>
        </div>
        </div>
        </div>)
          })}
        </div>
        </Accordion>*/}
          
        </div>
      </div>}
      
      <Modal show={this.state.modalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Add Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <AddPermission modalClose={this.modalClose.bind(this)} addPermission={this.addPermission.bind(this)} />
        </Modal.Body>
        {/* <Modal.Footer>
          <Button className='PopUpCanBU' onClick={this.modalClose.bind(this)}>Cancel</Button>
          <Button className='PopUpSubmitBU' onClick={this.deleteConfirm.bind(this)}>Add</Button>
        </Modal.Footer> */}
      </Modal>
      </>
    );
  }
}

export default withAdminAuth(UserPermissionList);