import React, { useEffect } from 'react';
import styles from './dashboard.module.css';
import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2'; 
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement} from 'chart.js';
import withAdminAuth from "../../components/withAdminAuth";
import { useState } from 'react';
import axios from 'axios';
import { Activity, Calendar,Users } from 'react-feather';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

function Dashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [doughnutData, setDoughnutData] = useState({
    labels: ['Booked - UK', 'Assigned To Driver - UK', 'Picked Up - UK', 'In Warehouse - UK', 'Shipped From Warehouse - UK', 'Received In Warehouse - Ghana', 'Out For Delivery - Ghana', 'Delivered - Ghana'],
    datasets: [{
      label: '# of Bookings',
      data: [0, 0, 0, 0, 0, 0,0,0],
      backgroundColor: [
        'rgba(230, 79, 34, 0.1)',
        'rgba(230, 79, 34, 0.2)',
        'rgba(230, 79, 34, 0.4)',
        'rgba(230, 79, 34, 0.7)',
        'rgba(42, 146, 245, 0.3)',
        'rgba(42, 146, 245, 0.5)',
        'rgba(5, 156, 42, 0.5)',
        'rgba(5, 156, 42, 0.9)',
      ],
      borderWidth: 1
    }]
  });
  const [barData, setBarData] = useState({
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [{
      label: 'Revenue',
      data: [45,85,96,41,5,7,2,88,22,10,45,12],
      backgroundColor: 'rgba(230, 79, 34, 0.6)',
    }]
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
      }
    }
  };

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/dashboard/get-data').then(response => {
      if(response.data.status === 'SUCCESS'){
        setDashboardData(response.data.data);
      }
    });
  },[]);

  useEffect(()=>{
    setDoughnutData({
      labels: ['Booked - UK', 'Assigned To Driver - UK', 'Picked Up - UK', 'In Warehouse - UK', 'Shipped From Warehouse - UK', 'Received In Warehouse - Ghana', 'Out For Delivery - Ghana', 'Delivered - Ghana'],
      datasets: [{
        label: '# of Bookings',
        data: dashboardData?.doughnut_data,
        backgroundColor: [
          'rgba(230, 79, 34, 0.1)',
          'rgba(230, 79, 34, 0.2)',
          'rgba(230, 79, 34, 0.4)',
          'rgba(230, 79, 34, 0.7)',
          'rgba(42, 146, 245, 0.3)',
          'rgba(42, 146, 245, 0.5)',
          'rgba(5, 156, 42, 0.5)',
          'rgba(5, 156, 42, 0.9)',
        ],
        borderWidth: 1
      }]
    });

    setBarData({
      labels: dashboardData?.bar_data?.labels,
      datasets: [{
        label: 'Revenue',
        data: dashboardData?.bar_data?.data,
        backgroundColor: 'rgba(230, 79, 34, 0.6)',
      }]
    });
  },[dashboardData]);
  
  return (<div className={`${styles.MainDiv}`}>
    <div className={`${styles.PageTitleWrap}`}>
      <p className={`${styles.PageTitle}`}>Dashboard</p>
    </div>
    
    <div className={`${styles.MainCard}`}>
      <div className="row">
        
        <div className='col-3'>
          <div className={`${styles.Card}`}>
            <div className={`${styles.CardBody}`}>
              <div>
                <h2 className={`${styles.CardTitle}`}>New Bookings</h2>
                <p className={`${styles.CardValue}`}>{dashboardData?.new_bookings}</p>
              </div>
              <div className={`${styles.CardIcon}`}>
                <Calendar />
              </div>
            </div>
          </div>
        </div>
        
        <div className='col-3'>
          <div className={`${styles.Card}`}>
            <div className={`${styles.CardBody}`}>
              <div>
                <h2 className={`${styles.CardTitle}`}>Total Bookings</h2>
                <p className={`${styles.CardValue}`}>{dashboardData?.total_bookings}</p>
              </div>
              <div className={`${styles.CardIcon}`}>
                <Calendar />
              </div>
            </div>
          </div>
        </div>
        
        <div className='col-3'>
          <div className={`${styles.Card}`}>
            <div className={`${styles.CardBody}`}>
              <div>
                <h2 className={`${styles.CardTitle}`}>Total Customers</h2>
                <p className={`${styles.CardValue}`}>{dashboardData?.total_customers}</p>
              </div>
              <div className={`${styles.CardIcon}`}>
                <Users />
              </div>
            </div>
          </div>
        </div>
        
        <div className='col-3'>
          <div className={`${styles.Card}`}>
            <div className={`${styles.CardBody}`}>
              <div>
                <h2 className={`${styles.CardTitle}`}>Total Revenue</h2>
                <p className={`${styles.CardValue}`}>&pound;{dashboardData?.total_revenue}</p>
              </div>
              <div className={`${styles.CardIcon}`}>
                <Activity />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className='row'>
        <div className='col-8'>
          <div className={`${styles.Card}`}>
            <div className={`${styles.CardBody}`}>
              <div className={`${styles.CardHead}`}>
                <h2 className={`${styles.CardTitle}`}>Revenue</h2>
              </div>
              <Bar options={options} data={barData} className={`${styles.BarChart}`} />
            </div>
          </div>
        </div>
        
        <div className='col-4'>
          <div className='col-12'>
            <div className={`${styles.Card}`}>
              <div className={`${styles.CardBody}`}>
                <div className={`${styles.CardHead}`}>
                  <h2 className={`${styles.CardTitle}`}>Latest bookings</h2>
                </div>
                <div className={`${styles.UpcomingBookTable}`}>
                  <table>
                    <tr>
                      <td className={`${styles.TableHead}`}>Booking Id</td>
                      <td className={`${styles.TableHead}`}>Customer name</td>
                      <td className={`${styles.TableHead}`}>Booking date</td>
                      <td className={`${styles.TableHead}`}>Total Value</td>
                    </tr>
                    {dashboardData?.latest_bookings?.map(item=>{
                      return <tr key={item?.id}>
                        <td className={`${styles.TableBody}`}>{item?.reference_id}</td>
                        <td className={`${styles.TableBody}`}>{item?.customer_name}</td>
                        <td className={`${styles.TableBody}`}>{moment(item?.created_at).format('DD/MM/YYYY')}</td>
                        <td className={`${styles.TableBody}`}>&pound;{parseFloat(item?.total).toFixed(2)}</td>
                      </tr>
                    })}
                    
                  </table>
                </div>
              </div>
            </div>
          </div>
          
          <div className='col-12'>
            <div className={`${styles.Card}`}>
              <div className={`${styles.CardBody}`}>
                <div className={`${styles.CardHead}`}>
                  <h2 className={`${styles.CardTitle}`}>Total Bookings</h2>
                </div>
                <Doughnut options={options} data={doughnutData} className={`${styles.DoughnutChart}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default withAdminAuth(Dashboard);