import React from 'react';
import styles from './changepassword.module.css';
import { useForm } from "react-hook-form";
import withCustomerAuth from "../../components/withCustomerAuth";
import {authData} from "../../../admin/components/getAuth";
import Loader from  '../../../admin/components/Loader';
import {  useNavigate} from "react-router-dom";
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import SideBar from "../../components/sideBar";
import { toast } from 'react-toastify';	
import 'react-toastify/dist/ReactToastify.css';

const schema = yup.object().shape({
  password: yup.string().required('This field is Required').min(6,'Password length should be 6-15').max(15,'Password length should be 6-15'),
  conf_password: yup.string().when('password', (password, field) =>
    password ? field.oneOf([yup.ref('password')],'Passwords must match') : field
  )
});

function ChangePassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  const onSubmit = (data) => {
    setLoading(true);

    return axios.post(process.env.REACT_APP_API_URL + 'v1/change-password', {password:data?.password}, {
      headers: {
        Authorization: `Bearer ${authData()?.api_token}`,
      }
    }).then(response => {
      setLoading(false);
      if(response.data.status === 'SUCCESS'){
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        localStorage.removeItem('authData');
        navigate('/');
      }
    }).catch(error => {
      setLoading(false);
    });
  };
  
  return (
    <>
    {loading && <Loader /> }

    <div className={`${styles.MainDivCustomer}`}>
      <img src="/images/booking-bg.jpg" alt="" className={`${styles.BackGroundGra}`} />
      <div className={`${styles.Container}`}>
        <div className={`${styles.CusRow}`}>
          <div className={`${styles.CustomerSideBar}`}><SideBar/></div>
          <div className={`${styles.UserProCard}`}>
            <div className={`${styles.UserHead}`}>
              <p className={`${styles.UserProName}`}>Change Password</p>
            </div>
            <div className={`${styles.UserProDe}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label class={`${styles.FormLabel}`}>Password*</label>
                    <input type="password" {...register("password", {required:'Please enter name'})} className={`${styles.FormControl}`} />
                    {errors?.password && <span className={`${styles.ErrorM}`}>{errors.password.message}</span>}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label class={`${styles.FormLabel}`}>Confirm Password*</label>
                    <input type="password" {...register("conf_password")} className={`${styles.FormControl}`} />
                    {errors?.conf_password && <span className={`${styles.ErrorM}`}>{errors.conf_password.message}</span>}
                  </div>
                </div>
                <div className={`${styles.MyProBUSec}`}>
                  <button className={`${styles.MyProUpdateBU}`}>Change Password</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default withCustomerAuth(ChangePassword);