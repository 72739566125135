import Home from '../frontend/pages/home/home';
import Login from '../frontend/pages/login/login';
import Signup from '../frontend/pages/signup/signup';
import Forgotpassword from '../frontend/pages/forgotpassword/forgotpassword';
import Resetpassword from '../frontend/pages/resetpassword/resetpassword';
import Booking from '../frontend/pages/booking/booking';
import TrackBooking from '../frontend/pages/track-booking/track-booking';
import AbooutUs from '../frontend/pages/aboutus/aboutus';
import Contact from '../frontend/pages/contactus/contactus';
import AdminDashboard from '../admin/pages/dashboard/dashboard';
import UserList from '../admin/pages/user/list';
import UserPermissionList from '../admin/pages/user-permission/list';
import CustomerList from '../admin/pages/customer/list';
import CategoryList from '../admin/pages/category/list';
import ProductList from '../admin/pages/product/list';
import DeliveryCategoryList from '../admin/pages/delivery-category/list';
import DeliveryProductList from '../admin/pages/delivery-product/list';
import AddBooking from '../admin/pages/add-bookings/add';
import EditBooking from '../admin/pages/edit-bookings/add';
import BookingList from '../admin/pages/bookings/list';
import BookingEnquery from '../admin/pages/bookings/enquiryList';
import BookingToAssign from '../admin/pages/bookingtoassign/list';
import AssignedBooking from '../admin/pages/assignedbooking/list';
import PickedBooking from '../admin/pages/pickedupitems/list';
import DriverReport from '../admin/pages/driver-report/list';
import DriverList from '../admin/pages/driver/list';
import ShippingList from '../admin/pages/shipping/list';
import AddShipping from '../admin/pages/shipping/add';
import ContainerList from '../admin/pages/shipping/container';
import MyBooking from '../customer/pages/mybooking/mybooking';
import CustomerBooking from '../customer/pages/add-bookings/add';
import MyProfile from '../customer/pages/myprofile/myprofile';
import UpdateProfile from '../customer/pages/updateprofile/updateprofile';
import ChangePassword from '../customer/pages/changepassword/changepassword';
import TrackBookingCustomer from '../customer/pages/track-booking/track-booking';
import DReceiveShipping from '../admin/pages/delivery/ReceiveShipping';
import DAssignToDriver from '../admin/pages/delivery/AssignToDriver';
import DDriverJob from '../admin/pages/delivery/AssignedDriver';

const HomeRoutes = {
  path:'/',
  component: Home
};
const LoginRoutes = {
  path:'/sign-in',
  component: Login
};
const SignupRoutes = {
  path:'/sign-up',
  component: Signup
};
const ForgotpasswordRoutes = {
  path:'/forgot-password',
  component: Forgotpassword
};
const ResetpasswordRoutes = {
  path:'/reset-password',
  component: Resetpassword
};
const BookingRoutes = {
  path:'/make-booking',
  component: Booking
};
const TrackBookingRoutes = {
  path:'/track-booking/:bookigId',
  component: TrackBooking
};
const AbooutUsRoutes = {
  path:'/about-us',
  component: AbooutUs
};

const ContactUsRoutes = {
  path:'/contact-us',
  component: Contact
};

export const AdminRoutes = {
  routeList:[
    {
      path:'/dashboard',
      component: AdminDashboard,
      menuName: 'dashboard'
    },
    {
      path:'/customers',
      component: CustomerList,
      menuName: 'customer'
    },
    {
      path:'/drivers',
      component: DriverList,
      menuName: 'driver'
    },
    {
      path:'/bookings',
      component: BookingList,
      menuName: 'booking'
    },
    {
      path:'/bookings/assign-list',
      component: BookingToAssign,
      menuName: 'booking'
    },
    {
      path:'/bookings/driver-jobs',
      component: AssignedBooking,
      menuName: 'booking'
    },
    {
      path:'/bookings/picked-items',
      component: PickedBooking,
      menuName: 'booking'
    },
    {
      path:'/bookings/driver-report',
      component: DriverReport,
      menuName: 'booking'
    },
    {
      path:'/booking/enquery/:bookingId',
      component: BookingEnquery,
      menuName: 'booking'
    },
    {
      path:'/add-booking',
      component: AddBooking,
      menuName: 'booking'
    },
    {
      path:'/edit-booking/:bookingId',
      component: EditBooking,
      menuName: 'booking'
    },
    {
      path:'/users',
      component: UserList,
      menuName: 'settings'
    },
    {
      path:'/user-permissions',
      component: UserPermissionList,
      menuName: 'settings'
    },
    {
      path:'/products',
      component: ProductList,
      menuName: 'settings'
    },
    {
      path:'/product-category',
      component: CategoryList,
      menuName: 'settings'
    },
    {
      path:'/delivery-products',
      component: DeliveryProductList,
      menuName: 'settings'
    },
    {
      path:'/delivery-product-category',
      component: DeliveryCategoryList,
      menuName: 'settings'
    },
    {
      path:'/shippings',
      component: ShippingList,
      menuName: 'shipping'
    },
    {
      path:'/add-shipping',
      component: AddShipping,
      menuName: 'shipping'
    },
    {
      path:'/view-shipping/:shippingId',
      component: AddShipping,
      menuName: 'shipping'
    },
    {
      path:'/shipping/container-list',
      component: ContainerList,
      menuName: 'shipping'
    },
    {
      path:'/delivery/receive-shipping',
      component: DReceiveShipping,
      menuName: 'delivery'
    },
    {
      path:'/delivery/assign-to-driver',
      component: DAssignToDriver,
      menuName: 'delivery'
    },
    {
      path:'/delivery/driver-jobs',
      component: DDriverJob,
      menuName: 'delivery'
    },
  ]
};

export const CustomerRoutes = {
  routeList:[
    {
      path:'/my-bookings',
      component: MyBooking
    },
    {
      path:'/add-booking',
      component: CustomerBooking
    },
    {
      path:'/my-profile',
      component: MyProfile
    },
    {
      path:'/update-profile',
      component: UpdateProfile
    },
    {
      path:'/change-password',
      component: ChangePassword
    },
    {
      path:'/track-booking',
      component: TrackBookingCustomer
    },
    {
      path:'/track-booking/:bookigId',
      component: TrackBookingCustomer
    }
    
  ]
};

export const FrontRouteList = [
  HomeRoutes,
  BookingRoutes,
  AbooutUsRoutes,
  ContactUsRoutes,
  TrackBookingRoutes
];

export const WithoutThemeRouteList = [
  LoginRoutes,
  SignupRoutes,
  ForgotpasswordRoutes,
  ResetpasswordRoutes
];