import React from 'react';

class Page404 extends React.Component {

  render() {
    return (
      <>
      404
      </>
  )}
}

export default Page404;