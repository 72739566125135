import React, {useState,useEffect} from 'react';
import styles from './add.module.css';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { FromLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import Select from "react-select";
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ProductAddForm({ onSubmit,isAddMode, id, modalClose }) {
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [parentList, setParentList] = useState([]);
  
  let schema;
  schema = yup.object().shape({
    title: yup.string().required('This field is Required').max(50, 'Title cannot be more than 50 characters'),
    parent_id: yup.object(),
    amount: yup.string().required('This field is Required')
  });
  
  const { register, handleSubmit,control, watch,setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });
  
  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/delivery-category-list', {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        let itemData = response.data.data;
        itemData = [{value: 0, label:'No Category'}].concat(itemData);
        setParentList(itemData);
      }
    });
  },[API_TOKEN]);

  useEffect(()=>{
    if(!isAddMode){
      if(contentLoading){
        axios.get(process.env.REACT_APP_API_URL + 'v1/delivery-products/'+id, {
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
          }
        }).then(response => {
          setContentLoading(false);
          if(response.data.status === 'SUCCESS'){
            let itemData = response.data.data;
            setValue('title',itemData.title);
            setValue('amount',itemData.amount);
            let catItem = parentList.filter(i=>i.value === itemData.parent_id);
          
            if(catItem.length){
              setValue('parent_id', catItem[0]);
            }
          }
        }).catch(error => {
          setContentLoading(false);
        });
      }
    }else{
      setContentLoading(false);
    }
  },[API_TOKEN,parentList,id,isAddMode,setValue,contentLoading]);

  async function onSubmitLocal(data) {
  
    if(data.parent_id ){
      data.parent_id=data.parent_id.value;
    }
    const postData = data;

    setLoading(true);

    if(isAddMode){
      return axios.post(process.env.REACT_APP_API_URL + 'v1/delivery-products', postData, {
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          onSubmit();
        }
      }).catch(error => {
        setLoading(false);
      });
    }else{
      return axios.post(process.env.REACT_APP_API_URL + 'v1/delivery-products/'+id, postData, {
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          onSubmit();
        }
      }).catch(error => {
        setLoading(false);
      });
    }
  }
  
  return (
    <React.Fragment>
      {loading && <Loader /> }
      {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div> }
      {!contentLoading && <div className={`${styles.AddUserMain}`}>
        <form onSubmit={handleSubmit(onSubmitLocal)}>
          <div className={`${styles.FormRow}`}>
            <div className={`${styles.FormGroupFull}`}>
              <label className={`${styles.FormLabel}`}>Title*</label>
              <input {...register("title")} className={`${styles.FormControl}`} />
              {errors.title && <span className={`${styles.ErrorM}`}>{errors.title.message}</span>}
            </div>
          </div>
          <div className={`${styles.FormRow}`}>
            <div className={`${styles.FormGroupFull}`}>
              <label className={`${styles.FormLabel}`}>Category*</label>
              <Controller
                control={control}
                options={parentList}
                {...register('parent_id')}
                render={({ field }) => (
                  <Select {...field} options={parentList}  />
                )}
              />
              {errors.parent_id && !watch().parent_id && <span className={`${styles.ErrorM}`}>{errors.parent_id.message}</span>}
            </div>
          </div>
          <div className={`${styles.FormRow}`}>
            <div className={`${styles.FormGroup}`}>
              <label className={`${styles.FormLabel}`}>Amount (&pound;)*</label>
              <Controller
                autoComplete="off"
                control={control}
                name="amount"
                className={`${styles.FormControl}`}
                render={({ field: { onChange, name, value } }) => (
                  <NumericFormat decimalScale={2} name={name} value={value} onChange={onChange} className={`${styles.FormControl}`} />
                )}
              />
              {errors.amount && <span className={`${styles.ErrorM}`}>{errors.amount.message}</span>}
            </div>
          </div>
          <div className={`${styles.FormButtonArea}`}>
            <button onClick={modalClose} className={`${styles.CancelBU}`}>Cancel</button>
            <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
          </div>
        </form>
      </div>}
    </React.Fragment>
  )}


export default ProductAddForm;