import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styles from './header.module.css';
import { Mail, Menu, Phone, X } from 'react-feather';
import {Dialog, DialogContent} from '@mui/material';
import PhoneInput,{isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {authData} from "../../admin/components/getAuth";

function Header() {
  const navigate = useNavigate();
  const [fieldType, setFieldType] = React.useState('Email');
  const [emailValue, setEmailValue] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [phoneValue, setPhoneValue] = React.useState('');
  const [phoneError, setPhoneError] = React.useState('');
  const [open1, setOpen] = React.useState(false);
  const [open21, setOpen21] = React.useState(false);
  const [smallMenu, setSmallMenu] = React.useState(false);
  const [trackBookingId, setTrackBookingId] = React.useState('');
  const [trackError, setTrackError] = React.useState(false);
  
  const goToTrackBooking = ()=>{
    setTrackError(false);
    if(trackBookingId === ''){
      setTrackError(true);
      return false;
    }
    setOpen21(false);
    navigate('/track-booking/'+trackBookingId);
  }

  const bookingModalClose = () => {
    setOpen(false);
  };

  const trackBookingModalOpen = () => {
    setTrackError(false);
    setOpen21(true);
  };

  const trackBookingModalClose = () => {
    setOpen21(false);
  };

  const goToBookingByEmail = ()=>{
    if(emailValue === ''){
      setEmailError('Please enter your email id.');
      return false;
    }
    if(!/\S+@\S+\.\S+/.test(emailValue)){
      setEmailError('Please enter valid email id.');
      return false;
    }
    let bookingData = {
      keyType: 'email',
      keyValue: emailValue,
      activeStep:0
    };
    localStorage.setItem('bookingData', JSON.stringify(bookingData));
    setOpen(false);
    navigate('/make-booking');
  }

  const goToBookingByPhone = () => {
    if(phoneValue === ''){
      setPhoneError('Please enter your phone no.');
      return false;
    }
    if(!isValidPhoneNumber(phoneValue)){
      setPhoneError('Please enter valid phone no.');
      return false;
    }
    let bookingData = {
      keyType: 'phone',
      keyValue: phoneValue,
      activeStep:0
    };
    localStorage.setItem('bookingData', JSON.stringify(bookingData));
    setOpen(false);
    navigate('/make-booking');
  }

  const OpenSmallMenu = () => {
    setSmallMenu(!smallMenu);
  }

  const bookingModalOpen = () => {
    setOpen(true);
  }
  
    return (
      <div className={`${styles.Header}`}>
        <div className={`${styles.Container}`}>
          <div className={`${styles.HeadRow}`}>
            <img src="/images/logo.svg" alt='logo' className={`${styles.HeadLogo}`} />
            {!smallMenu&&<button className={`${styles.HambergerMenu}`} onClick={OpenSmallMenu}><Menu/></button>}
            {smallMenu&&<button className={`${styles.HambergerMenu}`} onClick={OpenSmallMenu}><X/></button>}
            <div className={smallMenu === true ? `${styles.Menu} ${styles.open}` : `${styles.Menu}`}>
              <ul>
                <li><Link to={'/'}>Home</Link></li>
                <li><Link to={'/about-us'}>About</Link></li>
                <li>{authData()?.customer?.id ? <Link to={'/customer/add-booking'}>Make/Add a Booking</Link> : <Link onClick={bookingModalOpen}>Make/Add a Booking</Link>}</li>
                <li><Link to={'/contact-us'}>Contact Us</Link></li>
              </ul>
              <Link  onClick={trackBookingModalOpen} className={`${styles.HeadTrackBU}`}>Track</Link>
              <Link to={'/sign-in'} className={`${styles.HeadLoginBU}`}>Login</Link>
            </div>
          </div>
        </div>

        <Dialog
        open={open1}
        onClose={bookingModalClose}
        PaperProps={{ sx: { position: "fixed", top: 250} }}
        >
          <DialogContent>
            <div className={`${styles.ModalBody}`}>
              <p className={`${styles.PopupHeading} ${styles.Heading}`}>Book Pickup And Delivery</p>

              <div>
                <div className={`${styles.Tabs}`}>
                  <div className={(fieldType === 'Email')?`${styles.TabCard} ${styles.Active}`:`${styles.TabCard}`} onClick={(e)=>setFieldType('Email')}>
                    <Mail/> <p>Email</p>
                  </div>
                  <div className={(fieldType === 'Phone')?`${styles.TabCard} ${styles.Active}`:`${styles.TabCard}`} onClick={(e)=>setFieldType('Phone')}>
                    <Phone/> <p>Phone</p>
                  </div>
                </div>
                {(fieldType === 'Email') && <><div className={`${styles.FormSec}`}>
                  <input type="text" placeholder='Please enter your email' onChange={(e)=> {setEmailValue(e.target.value); setEmailError(''); } } />
                  <button type='button' onClick={goToBookingByEmail}>Submit</button>
                </div>
                {(emailError !== '') && <p className={`${styles.ErrorM}`}>{emailError}</p>}
                </>}
                {(fieldType === 'Phone') && <><div className={`${styles.FormSec} FormSec`}>
                  <PhoneInput 
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="GB"
                    value={phoneValue}
                    onChange={(e)=>{setPhoneValue(e); setPhoneError('');}}
                    countries={["GB","GH"]}
                  />
                  <button type='button' onClick={goToBookingByPhone}>Submit</button>
                </div>{(phoneError !== '') && <p className={`${styles.ErrorM}`}>{phoneError}</p>}
              </>}
              </div>

              <button type='button' onClick={bookingModalClose} className={`${styles.ModalCloseBU}`}><X/></button>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
        open={open21}
        onClose={trackBookingModalClose}
        PaperProps={{ sx: { position: "fixed", top: 250} }}
        >
          <DialogContent>
            <div className={`${styles.ModalBody}`}>
              <p className={`${styles.PopupHeading} ${styles.Heading}`}>Track your booking</p>
              <div className={`${styles.FormSec}`}>
                <input type="text" placeholder='Please enter your booking id' value={trackBookingId} onChange={(e)=>setTrackBookingId(e.target.value)} />
                <button type='button' onClick={goToTrackBooking}>Submit</button>
              </div>
              {trackError && <span style={{color: '#ff0000', fontSize: '15px', lineHeight: '25px' }}>Please enter your booking id</span>}
              <button type='button' onClick={trackBookingModalClose} className={`${styles.ModalCloseBU}`}><X/></button>
            </div>
          </DialogContent>
        </Dialog>
      </div>

      
  );
}


export default Header;