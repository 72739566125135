import * as React from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab, Typography, Box, Breadcrumbs} from '@mui/material';
import styles from './add.module.css';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import withAdminAuth from "../../components/withAdminAuth";
import { useParams } from 'react-router';
import axios from 'axios';
import moment from 'moment';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function AddBooking({ history, match }) {
  const params = useParams();
  const [bookingId, setBookingId] = React.useState(0);
  const [tabValue, setTabValue] = React.useState(0);
  const [bookingData, setBookingData] = React.useState({});

  React.useEffect(()=>{
    setBookingId(params?.bookingId);

    axios.get(process.env.REACT_APP_API_URL + 'v1/bookings-details/'+params?.bookingId).then(response => {
      if(response.data.status === 'SUCCESS'){
        let bookingDataTemp = {};
        let dataTemp = response.data.data;
        let customaerData = {...dataTemp?.customer, value: dataTemp?.customer?.id, label: dataTemp?.customer?.name+" ("+dataTemp?.customer?.customer_id+"), "+dataTemp?.customer?.phone+", "+dataTemp?.customer?.address};
        let insuranceTemp = {value: dataTemp?.insurance, label: dataTemp?.insurance};
        if(dataTemp?.insurance === 'Not Interested'){
          insuranceTemp = {value: "Not Interested", label: "I am not interested"};
        }
        bookingDataTemp = {...bookingDataTemp, customer_data: customaerData, registered_company: {value: dataTemp?.registered_company, label: dataTemp?.registered_company},insurance: insuranceTemp, pickup_date: moment(dataTemp?.pickup_date), notes: dataTemp?.description};

        if(dataTemp?.registered_company === "Yes"){
          bookingDataTemp = {...bookingDataTemp, company_shipping: {value: dataTemp?.company_shipping, label: dataTemp?.company_shipping},company_name: dataTemp?.company_name};
        }

        if(dataTemp?.delivery_products2?.length > 0){
          bookingDataTemp = {...bookingDataTemp, is_delivery: true};
          let deliveryProducts = dataTemp?.delivery_products2?.map(item=>{
            if(item?.product_id > 0){
              let category_id = {};
              let pitem = {};
              if(item?.no_of_child > 0){
                category_id = {amount: item.price, label: item?.parent?.title, no_of_child:item?.no_of_child,value: item?.parent?.id };
                pitem = {amount: item.price, label: item.product_title,value: item.product_id };
              }else{
                category_id = {amount: item.price, label: item.product_title,no_of_child:item?.no_of_child,value: item.product_id };
                pitem = {amount: item.price, label: item.product_title,no_of_child:item?.no_of_child,value: item.product_id };
              }
              return {id: item.id, category_id:category_id, item: pitem, quantity: item.quantity, total: item.sub_amount};
            }else{
              return {id: item.id, category_id:{id:0, label:"Other", value:999 }, item:item.product_title, quantity: item.quantity, total: item.sub_amount};
            }
          });
          bookingDataTemp = {...bookingDataTemp, delivery_products: deliveryProducts};
        }else{
          bookingDataTemp = {...bookingDataTemp, is_delivery: false, delivery_products: []};
        }

        if(dataTemp?.products2?.length > 0){
          let products = dataTemp?.products2?.map(item=>{
            if(item?.product_id > 0){
              let category_id = {};
              let pitem = {};
              if(item?.no_of_child > 0){
                category_id = {amount: item.price, label: item?.parent?.title, no_of_child:item?.no_of_child,value: item?.parent?.id };
                pitem = {amount: item.price, label: item.product_title,value: item.product_id };
              }else{
                category_id = {amount: item.price, label: item.product_title,no_of_child:item?.no_of_child,value: item.product_id };
                pitem = {amount: item.price, label: item.product_title,no_of_child:item?.no_of_child,value: item.product_id };
              }
              return {id: item.id, category_id:category_id, item: pitem, quantity: item.quantity, total: item.sub_amount, est_amount: item.sub_amount};
            }else{
              return {id: item.id, category_id:{id:0, label:"Other", value:999 }, item:item.product_title, quantity: item.quantity, total: item.sub_amount, est_amount: item.sub_amount};
            }
          });
          bookingDataTemp = {...bookingDataTemp, products: products};
        }

        if(dataTemp?.pickup_details){
          let pickUpData = dataTemp?.pickup_details;
          pickUpData = {...pickUpData, addressObj: {address: pickUpData?.address, alt_phones: pickUpData?.alt_phones, email: pickUpData?.email, label: pickUpData?.name, latitude: pickUpData?.latitude, longitude: pickUpData?.longitude, name_initials: "", phone: pickUpData?.phone, post_code: pickUpData?.post_code, value: pickUpData?.id }};
          bookingDataTemp = {...bookingDataTemp, pickUpData: pickUpData};
        }

        if(dataTemp?.dropoff_details){
          let dropOffData = dataTemp?.dropoff_details;
          dropOffData = {...dropOffData, addressObj: {address: dropOffData?.address, alt_phones: dropOffData?.alt_phones, email: dropOffData?.email, label: dropOffData?.name, latitude: dropOffData?.latitude, longitude: dropOffData?.longitude, phone: dropOffData?.phone, value: dropOffData?.id }};
          bookingDataTemp = {...bookingDataTemp, dropOffData: dropOffData};
        }

        setBookingData(bookingDataTemp);
      }
    });
  },[params?.bookingId]);
  
  const saveStep1Data = (data)=>{
    let bookingDataTemp = bookingData;
    bookingDataTemp = {...bookingDataTemp, ...data};

    setBookingData(bookingDataTemp);

    setTabValue(prevValue => prevValue+1);
  }


  const saveStep2Data = (pickUpData)=>{
    let bookingDataTemp = bookingData;
    bookingDataTemp = {...bookingDataTemp, pickUpData:pickUpData};

    setBookingData(bookingDataTemp);

    setTabValue(prevValue => prevValue+1);
  }

  const saveStep3Data = (dropOffData) => {
    let bookingDataTemp = bookingData;
    bookingDataTemp = {...bookingDataTemp, dropOffData:dropOffData};

    setBookingData(bookingDataTemp);
    setTabValue(prevValue => prevValue+1);
  }

  const backToTab = ()=>{
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });

    setTabValue(prevValue => prevValue-1);
  }

  return (
      <>
      <div className={`${styles.MainDiv}`}>
        <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>Edit Booking</p>
          <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
            <Typography color="text.primary">Bookings</Typography>
            <Typography color="text.primary">Edit</Typography>
          </Breadcrumbs>
        </div>
        <div className={((tabValue === 2)?`${styles.MainCard} ${styles.MainCard2}`:((tabValue === 1)?`${styles.MainCard} ${styles.MainCard1}`:`${styles.MainCard}`))}>
          <Box sx={{ width: '100%' }} className="AddBookingTab">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} aria-label="basic tabs example">
                <Tab label="Parcel Details" {...a11yProps(0)} />
                <Tab label="Pickup Address" {...a11yProps(1)} />
                <Tab label="Recipient Address" {...a11yProps(2)} />
                <Tab label="Order Summary" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <Step1 saveStep1Data={saveStep1Data.bind(this)} bookingData={bookingData} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Step2  saveStep2Data={saveStep2Data.bind(this)} backToTab={backToTab.bind(this)} bookingData={bookingData} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Step3  saveStep3Data={saveStep3Data.bind(this)} backToTab={backToTab.bind(this)} bookingData={bookingData} />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <Step4  bookingData={bookingData} backToTab={backToTab.bind(this)} bookingId={bookingId} />
            </TabPanel>
          </Box>
        </div>
      </div>
      </>
    );
}

export default withAdminAuth(AddBooking);