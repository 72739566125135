import React from 'react';
import styles from './booking.module.css';
import { Plus, Trash2 } from 'react-feather';
import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller,useFieldArray } from "react-hook-form";
import {isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import Autocomplete from "react-google-autocomplete";
import MapComponent from '../../components/Map';

export default function Step3({saveStep3Data,bookingData}){
  const [mapCenter, setMapCenter] = React.useState({ lat: 5.614818, lng: -0.205874 });
  const [isMarker, setIsMarker] = React.useState(false);
  const [zoom, setZoom] = React.useState(12);
  const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      alt_contact_nos: bookingData?.dropOffData?.alt_contact_nos ? bookingData?.dropOffData?.alt_contact_nos : [],
    }
  });

  const { fields: fields1, append: append1, remove: remove1 } = useFieldArray({
    control,
    name: "alt_contact_nos"
  });

  React.useState(()=>{
    setValue(`name_title`,bookingData?.dropOffData?.name_title);
    setValue(`name`,bookingData?.dropOffData?.name);
    setValue(`email`,bookingData?.dropOffData?.email);
    setValue(`phone`,bookingData?.dropOffData?.phone);
    setValue(`address`,bookingData?.dropOffData?.address);
  },[bookingData]);

  const onSubmit = (data)=>{
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });

    if(data?.email === ''){
      delete data?.email;
    }

    saveStep3Data(data);
  }

  return <>
  <p className={`${styles.StepBoxTitle2} ${styles.Heading}`}>RECIPIENT DETAILS</p>
  <form id="step3-form" onSubmit={handleSubmit(onSubmit)}>

  <div className={`${styles.FormRow}`}>

  <div className={`${styles.FormRowHS}`}>
    <div className={`${styles.FormRowHSFull}`}>
      <div className={`${styles.FormField}`}>
        <label>Add Recipient location<span>*</span></label>
        <MapComponent center={mapCenter} zoom={zoom} isMarker={isMarker} />
              </div>
    </div>
    <div className={`${styles.FormRowHSFull}`}>
      <div className={`${styles.FormField}`}>
        <div className={`${styles.FormInsideRow}`}>
          <input type="text" placeholder="Please enter post code" className={`${styles.TextField}`} />
          <button className={`${styles.FindLocationBU}`}>Find Location</button>
        </div>
      </div>
    </div>
  </div>

  <div className={`${styles.FormRowHS}`}>
      <div className={`${styles.FormRowHSFull} ${styles.FormInsideRow}`}>
        <div className={`${styles.FormField}`}>
          <label>Name<span>*</span></label>
          <input type="text" placeholder="" className={`${styles.TextField}`} {...register('name',{required:'Please enter name'})} />
          {errors.name && <p className={`${styles.ErrorM}`}>{errors.name.message}</p>}
        </div>
      </div>

      <div className={`${styles.FormRowHSFull}`}>
        <div className={`${styles.FormField}`}>
          <label>Contact No<span>*</span></label>
          <Controller
            control={control}
            {...register('phone',{required:'Please enter phone no',validate:isValidPhoneNumber})}
            className={`${styles.TextField}`}
            render={({ field }) => (
                <Input 
                  {...field}
                  country="GH"
                  international
                  withCountryCallingCode
                  className={`${styles.TextField}`}
                />
              )}
            />
          {(errors.phone && errors.phone.type !== "validate") && <p className={`${styles.ErrorM}`}>{errors.phone.message}</p>}
          {(errors.phone && errors.phone.type === "validate") && <p className={`${styles.ErrorM}`}>Please enter valid phone</p>}
        </div>
      </div>
      {fields1.map((item, index) => {
      return (<div className={`${styles.FormRowHSFull}`}>
          <div className={`${styles.FormField}`}>
            <label>Alt Contact No</label>
           <div className={`${styles.AltConSec}`}>
           <Controller
              control={control}
              {...register(`alt_contact_nos[${index}].value`,{required:'Please enter phone no',validate:isValidPhoneNumber})}
              className={`${styles.TextField}`}
              render={({ field }) => (
                  <Input 
                    {...field}
                    country="GH"
                    international
                    withCountryCallingCode
                    className={`${styles.TextField}`}
                  />
                )}
              />
              <button onClick={remove1}><Trash2/></button>
           </div>
          {(errors?.alt_contact_nos?.length > 0 && errors?.alt_contact_nos[index]?.value && errors?.alt_contact_nos[index]?.value?.type !== "validate") && <p className={`${styles.ErrorM}`}>{errors?.alt_contact_nos[index]?.value?.message}</p>}
          {(errors?.alt_contact_nos?.length > 0 && errors?.alt_contact_nos[index]?.value && errors?.alt_contact_nos[index]?.value?.type === "validate") && <p className={`${styles.ErrorM}`}>Please enter valid phone</p>}
          </div>
        </div>)
    })}

    <button type='button' className={`${styles.FormLink}`} onClick={() => { append1({ value: '' }); }}><Plus/> Alt contact no.</button>
    
    
    <div className={`${styles.FormRowHSFull}`}>
      <div className={`${styles.FormField}`}>
        <label>Address<span>*</span></label>
        <div className={`${styles.AllAddSec}`}>
          <Controller
                control={control}
                {...register(`address`,{required:'Please enter address'})}
                autoComplete="off"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    className={`${styles.TextField} ${styles.TextFieldAutoComplete}`}
                    apiKey={process.env.REACT_APP_MAP_API_KEY}
                    onPlaceSelected={(place) => {
                      setValue('address',place.formatted_address);
                      setValue('latitude',place.geometry.location.lat());
                      setValue('longitude',place.geometry.location.lng());

                     setMapCenter({ lat: parseFloat(place.geometry.location.lat()), lng: parseFloat(place.geometry.location.lng()) });
                     setIsMarker(true);
                     setZoom(18);
                    }}
                    options={{
                      types: ["address"],
                      componentRestrictions: { country: "gh" },
                    }}
                  />
                )}
              />
        </div>
        {(errors?.address) && <p className={`${styles.ErrorM}`}>{errors?.address?.message}</p>}
      </div>
    </div>
    
    <div className={`${styles.FormRowHSFull}`}>
        <div className={`${styles.FormField}`}>
          <label>Recipient Email Address</label>
          <input type="text" className={`${styles.TextField}`} {...register('email',{pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "invalid email address"
          }})} />
          {errors.email && <p className={`${styles.ErrorM}`}>{errors.email.message}</p>}
        </div>
      </div>

  </div>

  
</div>
  </form>
  </>
}