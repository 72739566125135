import React from 'react';
import styles from './login.module.css';
import {  useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Home } from 'react-feather';
import LoginForm from '../../components/form/LoginForm';

function Login() {
  const navigate = useNavigate();

  async function onSubmit() {
    setTimeout(()=>{
      navigate('/admin/dashboard');
    },100);
  }

    return (
      <React.Fragment>
        <div className={`${styles.MainDiv}`}>

         <div className={`${styles.LoginBGSec}`}>
            <img src="/images/loginBG1.jpg" alt="" />
            <img src="/images/logo.svg" alt="" className={`${styles.LoginLogo}`} />
         </div>

         <div className={`${styles.LoginFormSec}`}>
         <Link to={'/'} className={`${styles.BackToHome}`}><Home/> Back to home</Link>
          <div className={`${styles.LohinForm}`}>
            <img src="/images/logo.svg" alt="" className={`${styles.LoginLogoSmRe}`} />
            <h2 className={`${styles.BookingFormTitle} ${styles.Heading}`}>Login</h2>
            <p className={`${styles.LoginformSmTitle}`}>Login your account</p>
            <LoginForm onSubmit={onSubmit.bind(this)} isFP={true} />
          </div>
         </div>

        </div>
      </React.Fragment>
  )}


export default Login;