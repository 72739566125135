import React from "react";
import styled from "styled-components/macro";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  background-color: rgba(255,255,255,.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
`;

function Loader() {
  return (<Root>
    <div className="MainLoader">
      <div>
        <img src="/images/app-loader.svg" alt="" />
        <p className="LoadingText">Loading...</p>
      </div>
    </div>
  </Root>);
}

export default Loader;