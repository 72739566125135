import React from 'react';
import styles from './booking.module.css';
import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import moment from 'moment';
import Loader from  '../../../admin/components/Loader';
import LoginForm from '../../components/form/LoginForm';
import BookinSignUpForm from '../../components/form/BookinSignUpForm';

export default function Step5({bookingData,handleNext}){
  const [loading, setLoading] = React.useState(false);
  const [isSignIN, setIsSignIN] = React.useState(false);
  const [isSignUp, setIsSignUp] = React.useState(false);


  const makeABooking = (customerId)=>{
    let noOfItems = 0;
    let subTotal = 0;
    let delivery_total = 0;

    setLoading(true);

    let delivery_products = [];
    if(bookingData?.is_delivery){
      delivery_products = bookingData.delivery_products.map(item=>{
        delivery_total += parseFloat(item.total);
        if(item?.category_id?.value === 999){
          return {product_id: 0, product_title: item?.item, price: (parseInt(item.total)/parseInt(item.quantity)), quantity: item.quantity, sub_amount: item.total };
        }else{
          return {product_id: item?.item?.value, product_title: item?.item?.label, price: item?.item?.amount, quantity: item.quantity, sub_amount: item.total };
        }
      })
    }
     

    let products = bookingData.products.map(item=>{
      noOfItems += 1;
      subTotal += parseFloat(item.total);
      if(item?.category_id?.value === 999){
        return {product_id: 0, product_title: item?.item, price: item.total, quantity: 1, sub_amount: item.total, is_fragile: item?.is_fragile ? 1:0 };
      }else{
        return {product_id: item?.item?.value, product_title: item?.item?.label, price: item?.item?.amount, quantity: 1, sub_amount: item.total, is_fragile: item?.is_fragile ? 1:0 };
      }
    });

    let postData = {key_type: bookingData?.keyType, key_value: bookingData?.keyValue, customer_id: customerId, description: bookingData?.notes, no_of_items: noOfItems, is_delivery: (bookingData?.is_delivery)?1:0, insurance: bookingData?.insurance?.value, company_shipping: bookingData?.company_shipping?.value, company_name: bookingData?.company_name, registered_company: bookingData?.registered_company?.value, pickup_date:  moment(bookingData?.pickup_date).utc().format('YYYY-MM-DD'), total: (subTotal+delivery_total), vat: 0, amount:subTotal,delivery_total:delivery_total, shipping:0, post_code:bookingData?.pickUpData?.post_code, delivery_products: delivery_products, products: products, pickUpData: bookingData?.pickUpData, dropOffData: bookingData?.dropOffData };

    axios.post(process.env.REACT_APP_API_URL + 'v1/add-booking',postData).then(response => {
      setLoading(false);
      if(response.data.status === 'SUCCESS'){
        localStorage.removeItem('bookingData');
        localStorage.setItem('bookingSuccessData', JSON.stringify(response.data.data));

        document.documentElement.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant"
        });

        handleNext();
      }
    }).catch(eroor=>{
      setLoading(false);
    });
  }

  const onSubmit = () =>{
    let authData = localStorage.getItem('authData');
    if(authData){
      authData = JSON.parse(authData);
      makeABooking(authData?.id);
    }else{
      setIsSignIN(false);
      setIsSignUp(false);
    }
  }

  const onSignUpSubmit = () =>{
    let authData = localStorage.getItem('authData');
    if(authData){
      authData = JSON.parse(authData);
      makeABooking(authData);
    }else{
      setIsSignIN(false);
      setIsSignUp(false);
    }
  }

return <>
  {loading && <Loader /> }
  {(!isSignIN && !isSignUp) && <div className={`${styles.Checkout}`}>
    <button className={`${styles.GuestBU}`} type='button' onClick={makeABooking.bind(this,0)}>Continue as a guest</button>
    <p className={`${styles.CheckoutText}`}><span>OR</span></p>
    <button className={`${styles.SignUp}`} type='button' onClick={()=>setIsSignUp(true)}>Register</button>
    {/*<button className={`${styles.SignUpNew}`} type='button' onClick={()=>setIsSignIN(true)}>Create Account</button>*/}
  </div>}
  {isSignIN && <div className={`${styles.Checkout} ${styles.LohinForm}`}>
    <LoginForm onSubmit={onSubmit.bind(this)} isFP={false} />
    <button className={`${styles.SignUpNew}`} type='button' onClick={()=>{setIsSignIN(false); setIsSignUp(true);}}>Create Account</button>
  </div>}
  {isSignUp && <div className={`${styles.Checkout} ${styles.LohinForm}`}>
    <BookinSignUpForm onSubmit={onSignUpSubmit.bind(this)} bookingData={bookingData} />
    {/*<button className={`${styles.SignUpNew}`} type='button' onClick={()=>{setIsSignUp(false); setIsSignIN(true);}}>Login</button>*/}
  </div>}
  </>
}