import React from 'react';

export const ComponentToPrintList = React.forwardRef((props, ref) => {
  return (<div ref={ref} style={{padding: '20px 20px'}}>
    
    <table>
      <tr>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Booking Id</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Customer Name</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Recepient Name</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Delivery Address</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Delivery Notes</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Order Status</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Contact No</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Container</th>
      </tr>
      {props?.data?.map(row=>{
        return <tr>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.reference_id}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.customer_name}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.dropoff_details?.name}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.dropoff_details?.address}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.delivery_note}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{((row?.payment_status === 1 || row?.payment_status === '1') ? 'Paid':'Unpaid')}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.dropoff_details?.phone}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.container_name}</td>
        </tr>
      })}
    </table>


  </div>);
});