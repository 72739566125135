import React from 'react';
import styles from './list.module.css';
import axios from 'axios';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import { Controller, useForm } from "react-hook-form";
import moment from 'moment';
import Select from "react-select";
import { NumericFormat } from 'react-number-format';

export default function PaymentPopup ({modalClose,bookingId,paymentItem}){
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = React.useState(false);
  const [bookingData, setBookingData] = React.useState({});

  const { register, handleSubmit, formState: { errors },control, setValue,clearErrors} = useForm({
    mode: 'onChange'
  });

  React.useEffect(()=>{
    setLoading(true);
    axios(process.env.REACT_APP_API_URL + 'v1/bookings/'+bookingId, {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        setLoading(false);
        let bookingDataTemp = response.data.data;
        setBookingData(bookingDataTemp);
      }else{
        setLoading(false);
      }
    }).catch(error=>{
      setLoading(false);
    });
  },[API_TOKEN,bookingId]);

  const onSubmitLocal = (data)=>{
    let postData = {amount: data?.amount, note: data?.note, payment_method: data?.payment_method?.value };
    modalClose();
    setLoading(true);
    data={...data, payment_status:1};

    axios.post(process.env.REACT_APP_API_URL + 'v1/add-payment/'+bookingId,postData, {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        paymentItem(response.data.message,response.data.data);
      }else{
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }


  return (<React.Fragment>
    {loading && <Loader /> }

    <div className={`${styles.FullDiv}`}>
      <form onSubmit={handleSubmit(onSubmitLocal)}>
        <div className={`${styles.FormGroupFull}`}>
            <label className={`${styles.FormLabel}`}>Payment Type*</label>
              <Controller
                control={control}
                {...register('payment_type',{required:'Please select payment type'})}
                options={[{value: "full", label: "Due Amount"},{value: "part", label: "Part Payment"}]}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[{value: "full", label: "Due Amount"},{value: "part", label: "Part Payment"}]}
                    onChange={(e)=>{
                      field.onChange(e);
                      if(e?.value === 'part'){
                        setValue('amount','');
                      }else{
                        let dueamnt = parseFloat(bookingData?.total) - parseFloat(bookingData?.paid_amount);
                        setValue('amount',dueamnt);
                        clearErrors();
                      }
                    }}
                  />
                )}
              />
            {errors.payment_type && <span className={`${styles.ErrorM}`}>{errors.payment_type.message}</span>}
        </div>
        <div className={`${styles.FormGroupFull}`}>
            <label className={`${styles.FormLabel}`}>Amount*</label>
            <Controller
              control={control}
              {...register('amount',{required:'Please enter amount'})}
              render={({ field }) => (
                <NumericFormat
                  {...field } 
                  className={`${styles.FormControlTextArea}`}
                  style={{height:'auto'}} 
                  allowLeadingZeros={false} 
                  allowNegative={false} 
                  decimalScale={2}
                  onChange={(e)=>{
                    field.onChange(e);
                  }}
                />
              )}
            />
            {errors.amount && <span className={`${styles.ErrorM}`}>{errors.amount.message}</span>}
        </div>
        <div className={`${styles.FormGroupFull}`}>
            <label className={`${styles.FormLabel}`}>Payment Method*</label>
              <Controller
                control={control}
                {...register('payment_method',{required:'Please select payment method'})}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[{value: "Cash", label: "Cash"},{value: "Bank Transfer", label: "Bank Transfer"},{value: "Card Payment", label: "Card Payment"}]}
                    onChange={(e)=>{
                      field.onChange(e);
                    }}
                  />
                )}
              />
            {errors.payment_method && <span className={`${styles.ErrorM}`}>{errors.payment_method.message}</span>}
        </div>
        <div className={`${styles.FormGroupFull}`}>
            <label className={`${styles.FormLabel}`}>Payment Note*</label>
            <textarea className={`${styles.FormControlTextArea}`} {...register("note",{required:'Please enter note'})}></textarea>
            {errors.note && <span className={`${styles.ErrorM}`}>{errors.note.message}</span>}
        </div>
        <div className={`${styles.FormButtonArea}`}>
          <input className={`${styles.CancelBU}`} value="Cancel" type="button" onClick={modalClose.bind(this)} />
          <input className={`${styles.SubmitBU}`} value="Payment" type="submit" />
        </div>
      </form>
    </div>
    
    {(bookingData?.payments?.length > 0) && <div className={`${styles.MondalBodyDeRow2}`}>
      <p className={`${styles.FullTitle}`}>Payment Details</p>
      <div className={`${styles.ModalDeFullSec}`}>
        <div className={`${styles.TableSec}`}>
          <table>
            <tr>
              <td className={`${styles.TableHead}`} style={{background:'#E64F22', color:'#ffffff'}}>Paid Amount</td>
              <td className={`${styles.TableHead}`} style={{background:'#E64F22', color:'#ffffff'}}>Payment Method</td>
              <td className={`${styles.TableHead}`} style={{background:'#E64F22', color:'#ffffff'}}>Payment Notes</td>
              <td className={`${styles.TableHead}`} style={{background:'#E64F22', color:'#ffffff'}}>Created By</td>
              <td className={`${styles.TableHead}`} style={{background:'#E64F22', color:'#ffffff'}}>Created Date</td>
            </tr>
            {bookingData?.payments?.map((item,index)=>{
              return (<tr key={index}>
                <td className={`${styles.TableCon}`}>£{parseFloat(item.amount).toFixed(2)}</td>
                <td className={`${styles.TableCon}`}>{item.payment_method}</td>
                <td className={`${styles.TableCon}`}>{item.note}</td>
                <td className={`${styles.TableCon}`}>{item.name}</td>
                <td className={`${styles.TableCon}`}>{moment(item.created_at).format('DD/MM/YYYY LT')}</td>
              </tr>);
            })}
          </table>
        </div>
      </div>
    </div>}
  </React.Fragment>)
}