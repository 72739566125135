import React ,{useState} from 'react';
import styles from './resetpassword.module.css';
import {  useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import Loader from  '../../../admin/components/Loader';
import { Link, } from 'react-router-dom';
import { Home, Lock } from 'react-feather';

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const navigate = useNavigate();
  
  const schema = yup.object().shape({
    password: yup.string().required('This field is Required').min(6,'Password length should be 6-15').max(15,'Password length should be 6-15'),
    password_confirmation: yup.string().when('password', (password, field) =>
      password ? field.oneOf([yup.ref('password')],'Passwords must match') : field
    ),
  });


  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });
  
  async function onSubmit(data) {
    const token = '';
    const postData = {...data,token:localStorage.getItem('passwordToken')};
    setLoading(true);
    seterrorMsg('');
    setSuccessMsg('');
    
    return axios.post(process.env.REACT_APP_API_URL + 'v1/forgot-password/reset', postData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      setLoading(false);
      if(response.data.status === 'SUCCESS'){
        setTimeout(()=>{
          navigate('/sign-in');
        },100);
      }
      if(response.data.status === 'ERROR'){
        seterrorMsg(response?.data?.message);
      }
  }).catch(error => {
      if(error && error?.response && error?.response?.data && error?.response?.data?.message){
        seterrorMsg(error?.response?.data?.message);
      }
      setLoading(false);
    });   
  }
  
  return (
    <React.Fragment>
      {loading && <Loader /> }
      <div className={`${styles.MainDiv}`}>
        <div className={`${styles.LoginBGSec}`}>
          <img src="/images/loginBG1.jpg" alt="" />
          <img src="/images/logo.svg" alt="" className={`${styles.LoginLogo}`} />
        </div>
        
        <div className={`${styles.LoginFormSec}`}>
          <Link to={'/'} className={`${styles.BackToHome}`}><Home/> Back to home</Link>
          <div className={`${styles.LohinForm}`}>
            <img src="/images/logo.svg" alt="" className={`${styles.LoginLogoSmRe}`} />
            <h2 className={`${styles.BookingFormTitle} ${styles.Heading}`}>Forgot Password</h2>
            <form onSubmit={handleSubmit(onSubmit)} >
              <p className={`${styles.SuccessM}`}>{successMsg}</p>
              <p className={`${styles.ErrorM}`}>{errorMsg}</p>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Password</label>
                  <label className={`${styles.login_FormLabel}`}>
                    <Lock/><input {...register("password")} type="password" className={`${styles.FormControl}`} />
                  </label>                  
                  {errors.password && <span className={`${styles.ErrorM}`}>{errors.password.message}</span>}
                </div>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Confirm Password</label>
                  <label className={`${styles.login_FormLabel}`}>
                    <Lock/><input {...register("password_confirmation")} type="password" className={`${styles.FormControl}`} />
                  </label>                  
                  {errors.password_confirmation && <span className={`${styles.ErrorM}`}>{errors.password_confirmation.message}</span>}
                </div>
              </div>
              <button type="submit" className={`${styles.LoginBU}`}><span>Reset Password</span></button>
              <p className={`${styles.LoginFormSText}`}><Link to={'/sign-in'}>Login</Link></p>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )}


export default ResetPassword;