import React from 'react';
import styles from './AssignedDriver.module.css';
import moment from 'moment';

export const ComponentToPrint = React.forwardRef((props, ref) => {

  return (<div ref={ref}>

    {props?.bookingData?.map((item,index)=>{
      return (<div style={{pageBreakBefore: 'always'}}>
        <div style={{padding: '50px'}}>
          <div style={{textAlign:'center',borderBottom:'#000 1px solid', margin:0, fontSize:'18px', fontWeight : '600'}}>AgyaKoo Shipping</div>
          <div style={{textAlign:'center',borderBottom:'#000 1px solid', margin:0, fontSize:'20px', fontWeight : '600'}}>Delivery Notes</div>
          <div style={{textAlign:'center',borderBottom:'#000 1px solid', margin:0, fontSize:'16px', fontWeight : '500'}}>31 Waleys Close, Luton, Lu3 3rz<br />Tel: +44 7853 607006</div>

          <div className={`${styles.PrintClass1}`} style={{marginTop: '0px'}}>
            <h4>{item?.delivery_driver?.name}</h4>
            <h3>{moment(item?.delivery_driver?.updated_at).format('dddd, D MMM YYYY')}</h3>
          </div>

          <div className={`${styles.PrintClass2}`}>
            <div className={`${styles.PrintClass3}`}>SL No.<br />{index+1}</div>
            <div className={`${styles.PrintClass4}`}>
              <h4>Booking Ref.</h4>
              <p>{item?.reference_id}</p>
              <h4>Total</h4>
              <p>£{parseFloat(item?.total).toFixed(2)}</p>
            </div>
            <div className={`${styles.PrintClass5}`}>
              <h4 style={{padding: '0px', margin: '0px'}}>{item?.pickup_details?.name}</h4>
              <p style={{borderBottom: '1px dashed #000', margin: '0px 0px 5px 0px'}}>{item?.pickup_details?.phone}</p>
              <h4 style={{padding: '0px', margin: '10px 0px 0px'}}>{item?.dropoff_details?.name}</h4>
              <p style={{margin: '0px 0px 5px 0px'}}>{item?.dropoff_details?.phone}</p>
              <h5 style={{padding: '0px', margin: '10px 0px 0px'}}>Delivery Details</h5>
              <p style={{ margin: '0px 0px 5px 0px'}}>{item?.dropoff_details?.address}</p>
              <h5 style={{padding: '0px', margin: '10px 0px 0px'}}>Delivery Note</h5>
              <p style={{margin: '0px 0px 5px 0px'}}>{item?.delivery_note}</p>
            </div>

            <div className={`${styles.PrintClass5}`}>
              {item?.products?.map(subItem=>{
                return <div style={{border:'#000 2px solid', margin: '5px 0 5px 30px', padding: '10px', fontWeight: '600'}}>{subItem?.item_id} - {subItem?.product_title_new}</div>
              })}
            </div>

          </div>
        </div>

        <div style={{clear:'both'}}>&nbsp;</div>

        <div style={{padding: '0 50px'}}>
          <div style={{textAlign:'center',borderBottom:'#000 1px solid', margin:0, fontSize:'18px', fontWeight : '600'}}>AgyaKoo Shipping</div>
          <div style={{textAlign:'center',borderBottom:'#000 1px solid', margin:0, fontSize:'20px', fontWeight : '600'}}>Delivery Notes</div>
          <div style={{textAlign:'center',borderBottom:'#000 1px solid', margin:0, fontSize:'16px', fontWeight : '500'}}>31 Waleys Close, Luton, Lu3 3rz<br />Tel: +44 7853 607006</div>

          <div className={`${styles.PrintClass1}`} style={{marginTop: '0px'}}>
            <h4>{item?.delivery_driver?.name}</h4>
            <h3>{moment(item?.delivery_driver?.updated_at).format('dddd, D MMM YYYY')}</h3>
          </div>

          <div className={`${styles.PrintClass2}`}>
            <div className={`${styles.PrintClass3}`}>SL No.<br />{index+1}</div>
            <div className={`${styles.PrintClass4}`}>
              <h4>Booking Ref.</h4>
              <p>{item?.reference_id}</p>
              <h4>Total</h4>
              <p>£{parseFloat(item?.total).toFixed(2)}</p>
            </div>
            <div className={`${styles.PrintClass5}`}>
              <h4 style={{padding: '0px', margin: '0px'}}>{item?.pickup_details?.name}</h4>
              <p style={{borderBottom: '1px dashed #000', margin: '0px 0px 5px 0px'}}>{item?.pickup_details?.phone}</p>
              <h4 style={{padding: '0px', margin: '10px 0px 0px'}}>{item?.dropoff_details?.name}</h4>
              <p style={{margin: '0px 0px 5px 0px'}}>{item?.dropoff_details?.phone}</p>
              <h5 style={{padding: '0px', margin: '10px 0px 0px'}}>Delivery Details</h5>
              <p style={{ margin: '0px 0px 5px 0px'}}>{item?.dropoff_details?.address}</p>
              <h5 style={{padding: '0px', margin: '10px 0px 0px'}}>Delivery Note</h5>
              <p style={{margin: '0px 0px 5px 0px'}}>{item?.delivery_note}</p>
            </div>
          </div>

          <div className={`${styles.PrintClass5}`}>
            {item?.products?.map(subItem=>{
              return <div style={{border:'#000 2px solid', margin: '5px 0 5px 30px', padding: '10px', fontWeight: '600'}}>{subItem?.item_id} - {subItem?.product_title_new}</div>
            })}
          </div>

        </div>

        <div style={{clear:'both'}}>&nbsp;</div>
      </div>);
    })}
  </div>);
});