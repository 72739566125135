import React from 'react';
import styles from './booking.module.css';
import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";

export default function Step6(){
  const [bookingSuccessData,setBookingSuccessData] = React.useState({});
  React.useState(()=>{
    let bookingDataTemp = localStorage.getItem("bookingSuccessData");
    if(bookingDataTemp){
      bookingDataTemp = JSON.parse(bookingDataTemp);
      setBookingSuccessData(bookingDataTemp);
    }
  },[]);

return <>
  
  <div className={`${styles.BookingDone}`}>
    <img src="/images/check.png" alt="" />
    <p className={`${styles.BookingTitle}`}>Thank You!</p>
    <p className={`${styles.BookingSmTitle}`}>You successfully created your booking.</p>
    {(bookingSuccessData?.key_type === 'email') && <p className={`${styles.BookingSmTitle}`}>Email: <strong>{bookingSuccessData?.key_value}</strong></p>}
    {(bookingSuccessData?.key_type === 'phone') && <p className={`${styles.BookingSmTitle}`}>Phone: <strong>{bookingSuccessData?.key_value}</strong></p>}    
    <p className={`${styles.BookingSmTitle}`}>Reference Id: <strong>{bookingSuccessData?.reference_id}</strong></p>
  </div>

  </>
}