import React from 'react';
import moment from 'moment';

export const ComponentToPrintList = React.forwardRef((props, ref) => {
  return (<div ref={ref} style={{padding: '20px 20px'}}>
    
    <table>
      <tr>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Booking Id</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Customer Name</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Order Date</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Total Value</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Paid Amount</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Order Status</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Pickup Post Code</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Pickup Date</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Created User</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Registered Or Guest User</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Complaint Status</th>
      </tr>
      {props?.data?.map(row=>{
        return <tr>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.reference_id}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.customer_name}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{moment(row.created_at).format('DD/MM/YYYY LT')}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{'£'+parseFloat(row.total).toFixed(2)}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{'£'+parseFloat(row.paid_amount).toFixed(2)}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{(row?.status === -1 || row?.status === '-1') ? 'Cancel':((row?.payment_status === 1 || row?.payment_status === '1') ? 'Paid':'Unpaid')}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.post_code}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{moment(row.pickup_date).format('DD/MM/YYYY')}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.created_user}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.created_user_type}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{'No Complaints'}</td>
        </tr>
      })}
    </table>


  </div>);
});