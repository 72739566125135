import React, { useCallback, useEffect, useState } from 'react';
import styles from './AssignedDriver.module.css';
import { TextField, Button,Tooltip, Breadcrumbs, Typography} from '@mui/material';
import DataTable from 'react-data-table-component';
import {  X, ArrowRight, Printer, Plus, Edit } from 'react-feather';
import axios from 'axios';
import { TableLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import AssignPopup from "./AssignPopup";
import withAdminAuth from "../../components/withAdminAuth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { ComponentToPrint } from './AssignedDriverPrint';
import { ComponentToPrintList } from './AssignedDriverPrintList';
import { useReactToPrint } from 'react-to-print';
import NotePopup from './NotePopup';
import PaymentPopup from '../bookings/PaymentPopup';

function DataTableWithExport ({columns,data,customStyles,onSelectedRowsChange,rowDisabledCriteria,selectedRows2}){
  const componentRef = React.useRef();
  const componentRef2 = React.useRef();

  const [printData, setPrintData] = useState([]);

  const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)} className="TableAddBU" style={{width:'auto'}}>Export To CSV</Button>;

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = ['driver_name', 'booking_id', 'address', 'contact_no', 'recepient_name', 'order_status', 'delivery_notes','assigned_date'];

    result = '';
    //result += keys.join(columnDelimiter);
    keys.forEach(key => {
      if(key === 'driver_name'){
        result += 'Driver Name';
      }else if(key === 'booking_id'){
        result += 'Booking Id';
      }else if(key === 'address'){
        result += 'Address';
      }else if(key === 'contact_no'){
        result += 'Contact No';
      }else if(key === 'recepient_name'){
        result += 'Recepient Name';
      }else if(key === 'order_status'){
        result += 'Order Status';
      }else if(key === 'delivery_notes'){
        result += 'Delivery Notes';
      }else if(key === 'assigned_date'){
        result += 'Assigned Date';
      }else{
        result += key;
      }
      result += columnDelimiter;
    });
    result += lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }

  const downloadCSV = React.useCallback(() => {
    let newArray = data.map(row=>{
      return {'driver_name': row?.delivery_driver?.name, 'booking_id': row?.reference_id, 'address': row?.dropoff_details?.address.replace(/,/g, ""), 'contact_no': row?.dropoff_details?.phone, 'recepient_name': row?.dropoff_details?.name, 'order_status': ((row?.payment_status === 1 || row?.payment_status === '1') ? 'Paid':'Unpaid'), 'delivery_notes': row?.delivery_note,'assigned_date': moment(row?.delivery_driver?.updated_at).format('DD/MM/YYYY')}
    });

    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(newArray);
    if (csv == null) return;
    
    const filename = 'driver-jobs.csv';
    
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  },[data]);


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint22 = useReactToPrint({
    content: () => componentRef2.current,
  });

  useEffect(()=>{
    let printDataTemp = data?.filter(i => selectedRows2.indexOf(i?.id) > -1);
    setPrintData(printDataTemp);
  },[selectedRows2,data]);

  const handlePrint2 = useCallback(()=>{
    if(selectedRows2.length === 0){
      toast.error('Please select item(s)', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });

      return false;
    }

    handlePrint22();
  },[selectedRows2,handlePrint22]);

  const actionsMemo = React.useMemo(() => <><Export onExport={() => downloadCSV()} /><Button onClick={handlePrint} className="TableAddBU" style={{width:'auto'}}>Print Bookings</Button><Button onClick={()=> handlePrint2()} className="TableAddBU" style={{width:'auto'}}>Print Driver Jobs</Button></>, [downloadCSV,handlePrint,handlePrint2]);

  return <>
    <div style={{ display: "none" }}>
      <ComponentToPrintList ref={componentRef} data={data} />
    </div>
    <div style={{ display: "none" }}>
      <ComponentToPrint ref={componentRef2} bookingData={printData} />
    </div>
    <DataTable 
      className='DataTable'
      columns={columns}
      data={data}
      pagination
      customStyles={customStyles}
      selectableRows
      selectableRowDisabled={rowDisabledCriteria}
      onSelectedRowsChange={onSelectedRowsChange.bind(this)}
      actions={actionsMemo}
      noContextMenu={true}
    />
  </>
}


class BookingList extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.state = { allData: [], data: [],filterText:'', assignModalShow:false, selectedRows2: [], selectedRows: [], bookingData: null, datefilterData: {start_date: '', end_date: '' } ,noteModalShow:false, editNote:null, paymentModalShow:false };
    this.authData = authData();
    this.componentRef = [];

    this.columns = [
      {
        name: 'Driver Name',
        selector: row => row?.delivery_driver?.name,
        sortable: true,
      },
      {
        name: 'Booking Id',
        selector: row =>  row?.reference_id,
        sortable: true,
      },
      {
        name: 'Address',
        selector: row => row?.dropoff_details?.address,
        sortable: true,
      },
      {
        name: 'Contact No',
        selector: row => row?.dropoff_details?.phone,
        sortable: true,
      },
      {
        name: 'Recepient Name',
        selector: row => row?.dropoff_details?.name,
        sortable: true,
      },
      {
        name: 'Order Status',
        selector: tableProps => (<>
          {((tableProps?.payment_status === 1 || tableProps?.payment_status === '1') ? <Button className={`${styles.StatusBU} ${styles.DisaBU}`}>Paid</Button>:<Button className={`${styles.StatusBU}`} onClick={this.paymentModalOpen.bind(this,tableProps?.id)}>Unpaid</Button>)}
        </>),
        sortable: true,
      },
      {
        name: 'Delivery Notes',
        selector: row => ((row?.delivery_note && row?.delivery_note !== '') ? <><div style={{marginRight:'25px'}}>{row?.delivery_note}</div><div style={{position: 'absolute', right: '3px', top: '5px'}}><Button className={`${styles.StatusBU2}`} onClick={this.noteModalOpen.bind(this,row, false)}><Edit /></Button></div></>: <Button className={`${styles.StatusBU}`} onClick={this.noteModalOpen.bind(this,row, true)}>Add Note</Button> ),
        sortable: true,
      },
      {
        name: 'Assigned Date',
        selector: row => moment(row?.delivery_driver?.delivered_date).format('DD/MM/YYYY'),
        sortable: true
      },
      {
        name: 'Action',
        cell: (tableProps, index)  => (
          <div className={`${styles.ActionDiv}`}>
          <div style={{ display: "none" }}><ComponentToPrint ref={el => (this.componentRef[index] = el)} bookingData={[tableProps]} /></div>
          <ReactToPrint content={() => this.componentRef[index]}>
            <PrintContextConsumer>
              {({ handlePrint }) => ( <Button onClick={handlePrint}><Printer /></Button> )}
            </PrintContextConsumer>
          </ReactToPrint>
          <Tooltip title="Re-assign or un-assign" onClick={this.assignModalOpen.bind(this,tableProps?.id)}><Button><ArrowRight /></Button></Tooltip>
        </div> ),
        sortable: false
      }
    ];

    this.customDataTableStyles = {
      head: {
        style: {
          zIndex:0
        },
      },
      headCells: {
        style: {
            backgroundColor: '#1e1e2d',
            color: '#fff'
        },
      },
    } 
  }
  
  
  async componentDidMount() {
    try {
      axios(process.env.REACT_APP_API_URL + 'v1/delivery/assigned-bookings', {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        }
      }).then(response => {
        if(response.data.status === 'SUCCESS'){
          let allData = response.data.data;
          this.setState({ ...this.state, allData: allData, data: allData,contentLoading:false});

          allData.forEach((element,index) => {
            this.componentRef[index] = React.createRef();
          });
        }else{
          this.setState({ ...this.state, contentLoading:false});
        }
      }).catch(error=>{
        this.setState({ ...this.state, contentLoading:false});
      });

    } catch (error) {
      this.setState({ ...this.state, contentLoading:false});
    }
  }

  onFilter(e){
    this.setState({ ...this.state, filterText: e.target.value },this.filterHandler);
  }
  clearFilter(){
    this.setState({ ...this.state, filterText: '' },this.filterHandler);
  }

  filterHandler() {
    let filteredData = this.state.allData;

    if (this.state.filterText !== '') {
      let inputVal = this.state.filterText;
      inputVal = inputVal.toLowerCase();
      filteredData = filteredData.filter((item) => {
        return item?.delivery_driver?.name.toLowerCase().includes(inputVal)|| item?.reference_id.toLowerCase().includes(inputVal) || item?.dropoff_details?.address.toLowerCase().includes(inputVal) || item?.dropoff_details?.phone.toLowerCase().includes(inputVal) || item?.dropoff_details?.name.toLowerCase().includes(inputVal);
      });
    }

    let datefilterData = this.state.datefilterData;

    if(datefilterData?.start_date !== '' && datefilterData?.end_date !== ''){
      filteredData = filteredData.filter((item) => moment(item.pickup_date).isBetween(moment(datefilterData?.start_date),moment(datefilterData?.end_date), 'days', '[]'));
    }

    this.setState({ ...this.state, data: filteredData });
  }

  noteModalOpen(item, isAdd){
    if(isAdd){
      this.setState({...this.state,noteModalShow:true, currentItem:item?.id, editNote: null});
    }else{
      this.setState({...this.state,noteModalShow:true, currentItem:item?.id, editNote: item?.delivery_note});
    }
  }

  paymentModalOpen(id){
    this.setState({...this.state,paymentModalShow:true, currentItem:id});
  }

  assignModalOpen(id){
    this.setState({...this.state, assignModalShow:true, selectedRows: [id]});
  }

  modalClose(){
    this.setState({...this.state, addModalShow:false,noteModalShow: false, paymentModalShow: false,assignModalShow:false});
  }

  handleChange({ selectedRows }){
    let selectedRowTemp = selectedRows.map(item=>{
      return item.id;
    });
    this.setState({...this.state, selectedRows:selectedRowTemp});
  }

  rowDisabledCriteria(e){
    return parseInt(e?.payment_status) !== 1;
  }

  assignDriver(message){
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });

    axios(process.env.REACT_APP_API_URL + 'v1/delivery/assigned-bookings', {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        let allData = response.data.data;
        this.setState({ ...this.state, allData: allData, data: allData});
      }
    })
  }
  changeDate(e){
    let datefilterData = this.state.datefilterData;
    if(e){
      datefilterData = {start_date: moment(e[0]).format('YYYY-MM-DD'), end_date: moment(e[1]).format('YYYY-MM-DD') };
    }else{
      datefilterData = {start_date: '', end_date: '' };
    }
    this.setState({ datefilterData: datefilterData},this.filterHandler);
  }

  handleChange2({ selectedRows }){
    let selectedRowTemp = selectedRows.map(item=>{
      return item.id;
    });
    this.setState({...this.state, selectedRows2:selectedRowTemp});
  }

  makeDelivered(){
    if(this.state.selectedRows2.length === 0){
      toast.error('Please select item(s)', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });

      return false;
    }

    this.setState({ ...this.state, loading:true});

    let postData = {bookingIds:this.state.selectedRows2 };

    axios.post(process.env.REACT_APP_API_URL + 'v1/delivery/make-as-delivered',postData, {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){

        axios(process.env.REACT_APP_API_URL + 'v1/delivery/assigned-bookings', {
          headers: {
            Authorization: `Bearer ${this.authData?.api_token}`,
          }
        }).then(response => {
          if(response.data.status === 'SUCCESS'){
            let allData = response.data.data;
            this.setState({ ...this.state, allData: allData, data: allData,loading:false});
          }else{
            this.setState({ ...this.state, loading:false});
          }
        }).catch(error=>{
          this.setState({ ...this.state, loading:false});
        });
      }else{
        this.setState({ ...this.state, loading:false});
      }
    }).catch(error => {
      this.setState({ ...this.state, loading:false});
    });
  }

  noteItemAdd(message,item){
    toast.success(message);
    
    
    let allData = this.state.allData;
    let data = this.state.data;
    let allDataTemp = allData.map(i=> {
      if(i.id === item?.id)
        return {...i,delivery_note:item?.delivery_note}
      return i;
    });
    let dataTemp = data.map(i=> {
      if(i.id === item?.id)
        return {...i,delivery_note:item?.delivery_note}
      return i;
    });
    this.setState({ ...this.state, allData: allDataTemp, data: dataTemp});
  }

  paymentItem(message,id){
    toast.success(message);
    
    let allData = this.state.allData;
    let data = this.state.data;
    let allDataTemp = allData.map(i=> {
      if(i.id === id)
        return {...i,payment_status:1}
      return i;
    });
    let dataTemp = data.map(i=> {
      if(i.id === id)
        return {...i,payment_status:1}
      return i;
    });
    this.setState({ ...this.state, allData: allDataTemp, data: dataTemp,loading:false});
  }



  render() {
    return (
      <>
      {this.state.loading && <Loader />}
      {this.state.contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>}
      {!this.state.contentLoading && <div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>View Driver Job For Delivery</p>
          <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
            <Typography color="text.primary">Ghana Delvery</Typography>
            <Typography color="text.primary">Driver Job</Typography>
          </Breadcrumbs>
        </div>
        <div className={`${styles.MainCard}`}>
          <div className='TableFilterHead'>
            <div className="DataTableSearch">
              <TextField id="search" type="text" placeholder="Search" aria-label="Search Input" value={this.state.filterText} onChange={this.onFilter.bind(this)} autoComplete='off' />
              {this.state.filterText && <Button type="button"  className="CloseBU" onClick={this.clearFilter.bind(this)}><X/></Button>}
            </div>
            <div className='TableFilterBUSec'>
              <Button className="TableAddBU" onClick={this.makeDelivered.bind(this)}><Plus /> Delivered</Button>
            </div>
          </div>
          
          <div className='TableContent'>
            <DataTableWithExport columns={this.columns} data={this.state.data} customStyles={this.customDataTableStyles} rowDisabledCriteria={this.rowDisabledCriteria.bind(this)} onSelectedRowsChange={this.handleChange2.bind(this)} selectedRows2={this.state.selectedRows2} />
          </div>
        </div>
      </div>}

      <Modal show={this.state.assignModalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Assign Booking Details To Driver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssignPopup onSubmit={this.assignDriver.bind(this)} modalClose={this.modalClose.bind(this)} selectedRows={this.state.selectedRows} />
        </Modal.Body>
      </Modal>

      <Modal show={this.state.noteModalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.editNote ? 'Edit Note':'Add Note'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NotePopup modalClose={this.modalClose.bind(this)} noteItem={this.noteItemAdd.bind(this)} bookingId={this.state.currentItem} editNote={this.state.editNote} />
        </Modal.Body>
      </Modal>

      <Modal show={this.state.paymentModalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentPopup modalClose={this.modalClose.bind(this)} paymentItem={this.paymentItem.bind(this)} bookingId={this.state.currentItem} />
        </Modal.Body>
      </Modal>

      </>
    );
  }
}

export default withAdminAuth(BookingList);