import React, { useState } from 'react';
import styles from './contactus.module.css';
import { MapPin, Map, Mail, Phone } from 'react-feather';
import { useForm } from 'react-hook-form';
import Loader from '../../../admin/components/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contactus = () => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors },reset } = useForm({
    mode: 'onChange'
  });

  const onSubmit = (data) =>{
    setLoading(true);
    axios.post(process.env.REACT_APP_API_URL + 'v1/contact-us',data).then(response => {
      setLoading(false);
      if(response.data.status === 'SUCCESS'){
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });

        reset();
      }
    }).catch(error => {
      setLoading(false);
    });
  }
  
  return (<React.Fragment>
    {loading && <Loader />}
    <div className={`${styles.BannerSec}`}>
      <img src="/images/contact-us-banner1.jpg" alt="" />
      <div className={`${styles.BannerTitleCU}`}>
        <div className={`${styles.Container}`}>
          <h1 className={`${styles.Heading}`}>Contact Us</h1>
        </div>
      </div>
    </div>
    
    <div className={`${styles.contactpg}`}>
      <div className={`${styles.Container}`}>
        <div className={`${styles.ContactFromRow}`}>
          <div className={`${styles.ContactFormBigSec}`}>
            <h2 className={`${styles.Heading}`}>Get In Touch</h2>
            <form className={`${styles.form_group} ${styles.jobdescription}`} onSubmit={handleSubmit(onSubmit)}>
              <div className={`${styles.FormFieldHalf}`}>
                <label>First Name <span>*</span></label>
                <input type="text" className={`${styles.form_control}`} {...register('firstname',{required:'Please enter first name'})} />
                {errors?.firstname && <p style={{color:'#ff0000'}}>{errors?.firstname?.message}</p>}
              </div>
              <div className={`${styles.FormFieldHalf}`}>
                <label>Last Name<span>*</span></label>
                <input type="text" className={`${styles.form_control}`} {...register('lastname',{required:'Please enter last name'})} />
                {errors?.lastname && <p style={{color:'#ff0000'}}>{errors?.lastname?.message}</p>}
              </div>
              <div className={`${styles.FormFieldHalf}`}>
                <label>Phone Number<span>*</span></label>
                <input type="text" className={`${styles.form_control}`} {...register('phone',{required:'Please enter phone no'})} />
                {errors?.phone && <p style={{color:'#ff0000'}}>{errors?.phone?.message}</p>}
              </div>
              <div className={`${styles.FormFieldHalf}`}>
                <label>Email<span>*</span></label>
                <input type="text" className={`${styles.form_control}`} {...register('email',{required:'Please enter email',pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address"
                }})} />
                {errors?.email && <p style={{color:'#ff0000'}}>{errors?.email?.message}</p>}
              </div>
              <div className={`${styles.FormFieldFull}`}>
                <label>Message<span>*</span></label>
                <textarea {...register('message',{required:'Please enter your query'})} className={`${styles.form_control}`}></textarea>
                {errors?.message && <p style={{color:'#ff0000'}}>{errors?.message?.message}</p>}
              </div>
              <div className={`${styles.FormFieldHalf}`}>
                <button type="submit" className={`${styles.SubButton}`}>Submit</button>
              </div>
            </form>
          </div>
          <div className={`${styles.ContactFormSmallSec}`}>
            <h1 className={`${styles.Heading}`}>ADDRESS<span className={`${styles.icon_wrapper}`}><span className={`${styles.icon}`}><MapPin /></span></span></h1>
            <div className={`${styles.address_Area}`}>
              <p className={`${styles.Addstitle} ${styles.Heading}`}>AgyaKoo Shipping</p>
              <a className={`${styles.addsMS}`} rel="noreferrer" href="https://www.google.com/maps/search/International+House,+142+Cromwell+Road+London,+SW7+4ef" target="_blank"><Map />International House, 142 Cromwell Road London, SW7 4ef</a>
              <a className={`${styles.addsMS}`} href="tel:07853607006"><Phone />07853 607006</a>
              <a className={`${styles.addsMS}`} href="tel:+441582512874"><Phone />+44 1582 512874</a>
              <a className={`${styles.addsMS}`} href="mailto:info@eniclic.com"><Mail />info@eniclic.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className={`${styles.map}`}>
      <iframe title="contact map" src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2483.9919254444185!2d-0.18902327301674055!3d51.49501566160756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sInternational%20House%2C%20142%20Cromwell%20Road%20London%2C%20SW7%204ef!5e0!3m2!1sen!2sin!4v1702721426505!5m2!1sen!2sin" width="100%" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </React.Fragment>)
}

export default Contactus;