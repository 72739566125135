import React from 'react';
import styles from './header.module.css';
import { Menu, MenuItem } from '@mui/material';
import {authData} from "../../admin/components/getAuth";
import { useNavigate, Link } from 'react-router-dom';
import { Calendar, FileText, Power, Truck, User } from 'react-feather';

function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const auth = authData();
  const navigate = useNavigate();

  const logout=()=>{
    localStorage.removeItem('authData');
    navigate('/');
  }

  return (
    <div className={`${styles.Header}`}>
      <div className={`${styles.Container}`}>
        <div className={`${styles.HeadRow}`}>
          <img src="/images/logo.svg" alt='logo' className={`${styles.HeadLogo}`} />
          <div className={`${styles.Menu}`}>
            <ul>
              <li><Link to={'/'}>Home</Link></li>
              <li><Link to={'/about-us'}>About</Link></li>
              <li><Link  to={'/customer/add-booking'}>Make/Add a Booking</Link></li>
              <li><Link to={'/contact-us'}>Contact Us</Link></li>
            </ul>
          </div>
          <button
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            className={`${styles.UserProfileDropBU}`}
          >
            {auth?.customer?.name_initials}
          </button>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem className={`${styles.UserDropdownList}`}>
              <Link to={'/customer/my-bookings'}><FileText/> My booking</Link>
            </MenuItem>
            <MenuItem className={`${styles.UserDropdownList}`}>
              <Link to={'/customer/add-booking'}><Calendar/> Add a booking</Link>
            </MenuItem>
            <MenuItem className={`${styles.UserDropdownList}`}>
              <Link to={'/customer/track-booking'}><Truck/> Track a booking</Link>
            </MenuItem>
            <MenuItem className={`${styles.UserDropdownList}`}>
              <Link to={'/customer/my-profile'}><User/> My profile</Link>
            </MenuItem>
            <MenuItem onClick={logout.bind(this)} className={`${styles.UserDropdownListLogout}`}>
              <Power/> Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}


export default Header;