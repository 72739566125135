import React from 'react'
import { GoogleMap, useLoadScript ,MarkerF} from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '250px'
};

function MapComponent({center,zoom,isMarker}) {
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries:["places"]
  });

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
      >
        {isMarker && <MarkerF position={center} />}
      </GoogleMap>
  ) : <></>
}

export default React.memo(MapComponent)