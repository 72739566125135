import React from 'react';
import styles from './list.module.css';
import axios from 'axios';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";

export default function CancelPopup ({modalClose,shippingId,camcelItem}){
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = React.useState(false);

  const onSubmitLocal = ()=>{
    modalClose();
    setLoading(true);

    axios.delete(process.env.REACT_APP_API_URL + 'v1/shippings/'+shippingId, {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        camcelItem(response.data.message,shippingId);
      }else{
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }


  return (<React.Fragment>
    {loading && <Loader /> }
    <div className={`${styles.FullDiv}`}>
      <h2 className={`${styles.PopupTitle}`}>Sure? You want to remove this shipping?</h2>
        <div className={`${styles.FormButtonArea}`}>
          <input className={`${styles.CancelBU}`} value="No" type="button" onClick={modalClose.bind(this)} />
          <input className={`${styles.SubmitBU}`} value="Yes" type="button" onClick={onSubmitLocal.bind(this)} />
        </div>
    </div>
  </React.Fragment>)
}