import React, { useEffect } from 'react';
import styles from '../bookings/list.module.css';
import axios from 'axios';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import { useForm } from "react-hook-form";

export default function NotePopup ({modalClose,bookingId,noteItem,editNote}){
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = React.useState(false);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues:{
      delivery_note: ''
    }
  });

  useEffect(()=>{
    if(editNote){
      setValue('delivery_note',editNote);
    }
  },[editNote,setValue]);

  const onSubmitLocal = (data)=>{
    modalClose();
    setLoading(true);

    axios.put(process.env.REACT_APP_API_URL + 'v1/bookings/'+bookingId,data, {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        if(editNote){
          noteItem('Delivery note updated successfully.',response.data.data);
        }else{
          noteItem('Delivery note added successfully.',response.data.data);
        }
      }else{
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }
  


  return (<React.Fragment>
    {loading && <Loader /> }
    <div className={`${styles.FullDiv}`}>
      <form onSubmit={handleSubmit(onSubmitLocal)}>
        <div className={`${styles.FormGroupFull}`}>
            <label className={`${styles.FormLabel}`}>Delivery Note*</label>
            <textarea className={`${styles.FormControlTextArea}`} {...register("delivery_note",{required:'Please enter note'})}></textarea>
            {errors.delivery_note && <span className={`${styles.ErrorM}`}>{errors.delivery_note.message}</span>}
        </div>
        <div className={`${styles.FormButtonArea}`}>
          <input className={`${styles.CancelBU}`} value="Cancel" type="button" onClick={modalClose.bind(this)} />
          <input className={`${styles.SubmitBU}`} value={editNote?"Update":"Add"} type="submit" />
        </div>
      </form>
    </div>
  </React.Fragment>)
}