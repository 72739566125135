import React from 'react';
import styles from './list.module.css';
import axios from 'axios';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import { useForm,Controller } from "react-hook-form";
import Select from "react-select";

export default function AssignPopup ({modalClose,onSubmit,selectedRows}){
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = React.useState(false);
  const [driverList, setDriverList] = React.useState(false);

  const { register, handleSubmit,control, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  React.useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/p-driver-dd-list', {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        setDriverList(response.data.data);
      }
    });
  },[API_TOKEN]);

  const onSubmitLocal = (data)=>{
    modalClose();
    setLoading(true);

    let postData = {driver_id: data?.driver?.value, bookingIds:selectedRows }

    axios.post(process.env.REACT_APP_API_URL + 'v1/reassign-bookings',postData, {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        onSubmit(response.data.message);
      }else{
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }

  const unAssigned = ()=>{
    modalClose();
    setLoading(true);

    let postData = {bookingIds:selectedRows };

    axios.post(process.env.REACT_APP_API_URL + 'v1/unassign-bookings',postData, {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        onSubmit(response.data.message);
      }else{
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }


  return (<React.Fragment>
    {loading && <Loader /> }
    <div className={`${styles.AddUserMain}`}>
      <form onSubmit={handleSubmit(onSubmitLocal)}>
        <div className={`${styles.FormRow}`}>
          <div className={`${styles.FormGroupFull}`}>
            <label className={`${styles.FormLabel}`}>Select Driver*</label>
            <Controller
              control={control}
              options={driverList}
              {...register('driver',{required:'Please select driver'})}
              render={({ field }) => (
                <Select {...field} options={driverList}  />
              )}
            />
            {errors.driver && <span className={`${styles.ErrorM}`}>{errors.driver.message}</span>}
          </div>
        </div>
        <div className={`${styles.FormButtonArea}`}>
          <input className={`${styles.SubmitBU}`} value="Un-Assign" type="button" onClick={unAssigned.bind(this)} />
          <input className={`${styles.SubmitBU}`} value="Re-Assign" type="submit" />
        </div>
      </form>
    </div>
  </React.Fragment>)
}