import React from 'react';
import styles from './header.module.css';
import { User } from 'react-feather';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router-dom";

export default function  Header() {
  const navigate = useNavigate();
  
  const logout = ()=>{
    localStorage.removeItem('authData');
    navigate('/sign-in');
  }
  
  return (<header className={`${styles.HeaderMain}`}>
    <div className={`${styles.HeaderMainUnder}`}>
      <div className={`${styles.HeaderRow}`}>
        <div className={`${styles.HeadRightSec}`}>
          {/*<button className={`${styles.HeadBU}`}>
            <Bell/>
            <span className={`${styles.HeadBUCount}`}>9</span>
          </button>*/}
          <Dropdown className={`${styles.ProfileBU}`}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <User/>
            </Dropdown.Toggle>
            
            <Dropdown.Menu className={`${styles.ProfileDRBU}`}>
              <Dropdown.Item href="#/action-1">Update Profile</Dropdown.Item>
              <Dropdown.Item onClick={logout}>Sign Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  </header>);
}