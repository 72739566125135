import React from 'react';
import styles from './myprofile.module.css';
import withCustomerAuth from "../../components/withCustomerAuth";
import {authData} from "../../../admin/components/getAuth";
import {  Link as RouterLink } from "react-router-dom";
import moment from 'moment';
import SideBar from "../../components/sideBar";
import { Menu, MenuItem } from '@mui/material';
import { MoreVertical } from 'react-feather';


function MyProfile() {
  const auth = authData();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (

    <div className={`${styles.MainDivCustomer}`}>
      <img src="/images/booking-bg.jpg" alt="" className={`${styles.BackGroundGra}`} />
      <div className={`${styles.Container}`}>
        <div className={`${styles.CusRow}`}>
          <div className={`${styles.CustomerSideBar}`}><SideBar/></div>
          <div className={`${styles.CustomerDeSec}`}>
            <div className={`${styles.UserProCard2}`}>
              <div className={`${styles.UserHead}`}>
                <p className={`${styles.UserProName}`}>{auth?.customer?.name}</p>
                <RouterLink className={`${styles.SideEditBU} ${styles.SideEditBU2}`} to={'/customer/update-profile'}>Edit Profile</RouterLink>
                <RouterLink className={`${styles.SideEditBU} ${styles.SideEditBU3}`} to={'/customer/change-password'}>Change Password</RouterLink>
                <button
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  className={`${styles.MoreDropBU}`}
                >
                  <MoreVertical/>
                </button>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem className={`${styles.UserDropdownList}`}>
                    <RouterLink to={'/customer/update-profile'}>Edit Profile</RouterLink>
                  </MenuItem>
                  <MenuItem className={`${styles.UserDropdownList}`}>
                    <RouterLink to={'/customer/change-password'}>Change Password</RouterLink>
                  </MenuItem>
                </Menu>
              </div>
              <div className={`${styles.UserProDe}`}>
                <p className={`${styles.UserProDeList}`}><span>Customer Id:</span> {auth?.customer?.customer_id}</p>
                <p className={`${styles.UserProDeList}`}><span>Name:</span> {auth?.customer?.name}</p>
                <p className={`${styles.UserProDeList}`}><span>Email:</span> {auth?.customer?.email}</p>
                <p className={`${styles.UserProDeList}`}><span>Phone No:</span> {auth?.customer?.phone}</p>
                <p className={`${styles.UserProDeList}`}><span>Date Of Birth:</span> {moment(auth?.customer?.dob).format('DD/MM/YYYY')}</p>
                <p className={`${styles.UserProDeList}`}><span>Address:</span> {auth?.customer?.address}</p>
                <p className={`${styles.UserProDeList}`}><span>Post Code:</span> {auth?.customer?.post_code}</p>
                <p className={`${styles.UserProDeList} ${styles.UserProDeList2}`}><span>Do you have a Registered Company?:</span> {auth?.customer?.registered_company}</p>
                {(auth?.customer?.registered_company === 'Yes') && <><p className={`${styles.UserProDeList} ${styles.UserProDeList2}`}><span>Are you shipping the goods in the name of the company?:</span> {auth?.customer?.company_shipping}</p>
                <p className={`${styles.UserProDeList} ${styles.UserProDeList2}`}><span>Registered Company Name:</span> {auth?.customer?.company_name}</p></>}
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>

  );
}

export default withCustomerAuth(MyProfile);