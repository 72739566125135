import React from 'react';
import styles from './list.module.css';
import { MapPin, Mail, Phone } from 'react-feather';
import moment from 'moment';

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (<div ref={ref} style={{padding: '20px 20px'}}>
    
    <div style={{borderButtoom: '1px solid #f4f4f4',paddingBottom: '20px;'}}>
      <h4 style={{fontSize: '19px'}}>Order Information: #{props?.bookingData?.reference_id}</h4>
    </div>
    
    <div className={`${styles.BodyHWithIcon}`} style={{paddingTop: '20px'}}>
      <p>Please find the details of order #{props?.bookingData?.reference_id} below:</p>
    </div>

    <div className={`${styles.MondalBodyDeRow}`}>
      <div className={`${styles.ModalDeBigSec}`}>
        <p className={`${styles.StepBoxTitle2}`}>Customer Account Details</p>
        <ul className={`${styles.SummeryListSec}`}>
          <li>
            <p className={`${styles.SummeryHeadTitle}`}>Customer ID:</p>
            <p className={`${styles.SummeryHeadText}`}>{props?.bookingData?.customer_type === 'Guest' ? props?.bookingData?.guest_customer?.customer_id: props?.bookingData?.customer?.customer_id}</p>
          </li>
          <li>
            <p className={`${styles.SummeryHeadTitle}`}>Customer Name:</p>
            <p className={`${styles.SummeryHeadText}`}>{props?.bookingData?.customer_type === 'Guest' ? props?.bookingData?.guest_customer?.name: props?.bookingData?.customer?.name}</p>
          </li>
          <li>
            <p className={`${styles.SummeryHeadTitle}`}>Customer Mobile:</p>
            <p className={`${styles.SummeryHeadText}`}>{props?.bookingData?.customer_type === 'Guest' ? props?.bookingData?.guest_customer?.phone: props?.bookingData?.customer?.phone}</p>
          </li>
          <li>
            <p className={`${styles.SummeryHeadTitle}`}>Customer Email:</p>
            <p className={`${styles.SummeryHeadText}`}>{props?.bookingData?.customer_type === 'Guest' ? props?.bookingData?.guest_customer?.email: props?.bookingData?.customer?.email}</p>
          </li>
        </ul>
      </div>
      <div className={`${styles.ModalDeBigSec}`}>
        <p className={`${styles.StepBoxTitle2}`}>Additional Info</p>
        <ul className={`${styles.SummeryListSec}`}>
          <li>
            <p className={`${styles.SummeryHeadTitle}`}>Booking Taken by:</p>
            <p className={`${styles.SummeryHeadText}`}>{props?.bookingData?.created_user}</p>
          </li>
          <li>
            <p className={`${styles.SummeryHeadTitle}`}>Booking Last Edited by:</p>
            <p className={`${styles.SummeryHeadText}`}>{props?.bookingData?.edited_user}</p>
          </li>
          <li>
            <p className={`${styles.SummeryHeadTitle}`}>Booking in Container:</p>
            <p className={`${styles.SummeryHeadText}`}>-</p>
          </li>
          <li>
            <p className={`${styles.SummeryHeadTitle}`}>Loaded:</p>
            <p className={`${styles.SummeryHeadText}`}>Loaded {props?.bookingData?.loaded_items} Item(s) | Remaining {props?.bookingData?.total_items-props?.bookingData?.loaded_items} Item(s)</p>
          </li>
          <li>
            <p className={`${styles.SummeryHeadTitle}`}>Items:</p>
            <p className={`${styles.SummeryHeadText}`}>{props?.bookingData?.total_items} Item(s)</p>
          </li>
          <li>
            <p className={`${styles.SummeryHeadTitle}`}>Booking Picked by:</p>
            <p className={`${styles.SummeryHeadText}`}>{props?.bookingData?.picked_driver ? props?.bookingData?.picked_driver?.name : '-'}</p>
          </li>
          <li>
            <p className={`${styles.SummeryHeadTitle}`}>Delivered by:</p>
            <p className={`${styles.SummeryHeadText}`}>{props?.bookingData?.delivery_driver ? props?.bookingData?.delivery_driver?.name : '-'}</p>
          </li>
          <li>
            <p className={`${styles.SummeryHeadTitle}`}>Customer Sgnature:</p>
            <p className={`${styles.SummeryHeadText}`}>{props?.bookingData?.customer_signature ? <img src={props?.bookingData?.customer_signature} alt='' style={{maxWidth: '150px',maxHeight:'40px'}} /> : '-'}</p>
          </li>
        </ul>
      </div>
    </div>

    <div className={`${styles.MondalBodyDeRow}`}>
      <div className={`${styles.ModalDeBigSec}`}>
        <p className={`${styles.StepBoxTitle2}`}>Pickup Photo(s)</p>
        <div className={`${styles.ImageRow}`}>
          
          {props?.bookingData?.images?.map((img)=>{
              return <div className={`${styles.imgDiv}`}>
                <img src={img.image_url} alt="" />
              </div>
          })}

        </div>
      </div>
      <div className={`${styles.ModalDeBigSec}`}>
        <p className={`${styles.StepBoxTitle2}`}>Delivery Photo(s)</p>
        <div className={`${styles.ImageRow}`}>
          {props?.bookingData?.delivery_images?.map((img)=>{
              return <div className={`${styles.imgDiv}`}>
                <img src={img.image_url} alt="" />
              </div>
          })}
        </div>
      </div>
    </div>
    
    {(props?.bookingData?.description) && <div className={`${styles.BookingNote}`}>
      <p className={`${styles.Title}`}>Booking Note:</p>
      <p className={`${styles.Text}`}>{props?.bookingData?.description}</p>
    </div>}

    {(props?.bookingData?.delivery_note) && <div className={`${styles.BookingNote}`}>
      <p className={`${styles.Title}`}>Delivery Note:</p>
      <p className={`${styles.Text}`}>{props?.bookingData?.delivery_note}</p>
    </div>}
    
    {(props?.bookingData?.status === -1 || props?.bookingData?.status === "-1") && <div className={`${styles.BookingNote}`}>
      <p className={`${styles.Title}`}>Cancel Note:</p>
      <p className={`${styles.Text}`}>{props?.bookingData?.cancel_note}</p>
    </div>}

    
    <div className={`${styles.MondalBodyDeRow2}`}>
      <p className={`${styles.FullTitle}`}>Collection and delivery details</p>
      <div className={`${styles.ModalDeBigSec}`}>
        <p className={`${styles.StepBoxTitle2}`}>Pickup Address</p>
        <p className={`${styles.PickName}`}>{props?.bookingData?.pickup_details?.name}</p>
        <p className={`${styles.DetailsList}`}><MapPin/>{props?.bookingData?.pickup_details?.address}</p>
        <p className={`${styles.DetailsList}`}><Mail/>{props?.bookingData?.pickup_details?.email}</p>
        <p className={`${styles.DetailsList}`}><Phone/>{props?.bookingData?.pickup_details?.phone}</p>
        {props?.bookingData?.pickup_details?.alt_phones?.map(item=>{
          return <p className={`${styles.DetailsList}`}><Phone/>{item}</p>;
        })}
      </div>
      <div className={`${styles.ModalDeBigSec}`}>
        <p className={`${styles.StepBoxTitle2}`}>Delivery Address</p>
        <p className={`${styles.PickName}`}>{props?.bookingData?.dropoff_details?.name}</p>
        <p className={`${styles.DetailsList}`}><MapPin/>{props?.bookingData?.dropoff_details?.address}</p>
        <p className={`${styles.DetailsList}`}><Mail/>{props?.bookingData?.dropoff_details?.email}</p>
        <p className={`${styles.DetailsList}`}><Phone/>{props?.bookingData?.dropoff_details?.phone}</p>
        {props?.bookingData?.dropoff_details?.alt_phones?.map(item=>{
          return <p className={`${styles.DetailsList}`}><Phone/>{item}</p>;
        })}
      </div>
    </div>

    <div className={`${styles.MondalBodyDeRow2}`}>
      <p className={`${styles.FullTitle}`}>Driver Details</p>
      {props?.bookingData?.picked_driver ? <div className={`${styles.ModalDeBigSec}`}>
        <p className={`${styles.StepBoxTitle2}`}>Pickup Driver name</p>
        <p className={`${styles.PickName}`}>{props?.bookingData?.picked_driver?.name}</p>
        <p className={`${styles.DetailsList}`}><Mail/>{props?.bookingData?.picked_driver?.email}</p>
        <p className={`${styles.DetailsList}`}><Phone/>{props?.bookingData?.picked_driver?.mobile}</p>
      </div>:<div>
        <p className={`${styles.PickName}`} style={{fontSize:'15px', fontWeight:500}}>Driver is not assigned yet.</p>
      </div>}
      {props?.bookingData?.delivery_driver ? <div className={`${styles.ModalDeBigSec}`}>
        <p className={`${styles.StepBoxTitle2}`}>Delivery Driver name</p>
        <p className={`${styles.PickName}`}>{props?.bookingData?.delivery_driver?.name}</p>
        <p className={`${styles.DetailsList}`}><Mail/>{props?.bookingData?.delivery_driver?.email}</p>
        <p className={`${styles.DetailsList}`}><Phone/>{props?.bookingData?.delivery_driver?.mobile}</p>
      </div>:<div>
        <p className={`${styles.PickName}`} style={{fontSize:'15px', fontWeight:500}}>Driver is not assigned yet.</p>
      </div>}
    </div>
    
    <div className={`${styles.MondalBodyDeRow2}`}>
      <p className={`${styles.FullTitle}`}>Item details</p>
      <div className={`${styles.ModalDeFullSec}`}>
        <p className={`${styles.StepBoxTitle2}`}>Pickup Date: {moment(props?.bookingData?.pickup_date).format('ddd MMM D, YYYY')}</p>
        {(props?.bookingData?.is_delivery===1) && <div className={`${styles.TableSec}`}>
          <table>
            <tr>
              <td className={`${styles.TableHead}`} style={{background:'#E64F22', color:'#ffffff'}}>Delivery ID</td>
              <td className={`${styles.TableHead}`} style={{background:'#E64F22', color:'#ffffff'}}>Item</td>
              <td className={`${styles.TableHead}`} style={{background:'#E64F22', color:'#ffffff'}}>Qty</td>
              <td className={`${styles.TableHead}`} style={{background:'#E64F22', color:'#ffffff'}}>Cost</td>
            </tr>
            {props?.bookingData?.delivery_products?.map((item,index)=>{
              return (<tr key={index}>
                <td className={`${styles.TableCon}`}>{item.item_id}</td>
                <td className={`${styles.TableCon}`}>{item.product_title_new}</td>
                <td className={`${styles.TableCon}`}>{item.quantity}</td>
                <td className={`${styles.TableCon}`}>£{parseFloat(item.sub_amount).toFixed(2)}</td>
              </tr>);
            })}
          </table>
        </div>}
        <div className={`${styles.TableSec}`}>
          <table>
            <tr>
              <td className={`${styles.TableHead}`} style={{background:'#002e5b', color:'#ffffff'}}>Pickup ID</td>
              <td className={`${styles.TableHead}`} style={{background:'#002e5b', color:'#ffffff'}}>Item</td>
              <td className={`${styles.TableHead}`} style={{background:'#002e5b', color:'#ffffff'}}>Cost</td>
              <td className={`${styles.TableHead}`} style={{background:'#002e5b', color:'#ffffff'}}>Status</td>
              <td className={`${styles.TableHead}`} style={{background:'#002e5b', color:'#ffffff'}}>Container</td>
            </tr>
            {props?.bookingData?.products?.map((item,index)=>{
              return (<tr>
                <td className={`${styles.TableCon}`}>{item.item_id}</td>
                <td className={`${styles.TableCon}`}>{item.product_title_new}</td>
                <td className={`${styles.TableCon}`}>£{parseFloat(item.sub_amount).toFixed(2)}</td>
                <td className={`${styles.TableCon}`}>{(item?.status_text !== '' )?item.status_text:props?.bookingData?.status_text}</td>
                <td className={`${styles.TableCon}`}>{item?.container_name}</td>
              </tr>);
            })}
          </table>
        </div>
        <div className={`${styles.EstiAmmo}`}>
          <ul>
            <li>
              <p className={`${styles.EstiTitle}`}>Item Subtotal:</p>
              <p className={`${styles.EstiInput}`}>£{parseFloat(props?.bookingData?.amount)?.toFixed(2)}</p>
            </li>
            <li>
              <p className={`${styles.EstiTitle}`}>Total VAT:</p>
              <p className={`${styles.EstiInput}`}>£0.00</p>
            </li>
            <li>
              <p className={`${styles.EstiTitle}`}>Insurance Charges:</p>
              <p className={`${styles.EstiInput}`}>£0.00</p>
            </li>
            {(props?.bookingData?.is_delivery===1) && <li>
              <p className={`${styles.EstiTitle}`}>Delivery Charges:</p>
              <p className={`${styles.EstiInput}`}>£{parseFloat(props?.bookingData?.delivery_total)?.toFixed(2)}</p>
            </li>}
            <li>
              <p className={`${styles.EstiTitle}`}>Order Total:</p>
              <p className={`${styles.EstiInput}`}>£{parseFloat(props?.bookingData?.total)?.toFixed(2)}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    
    {(props?.bookingData?.payments?.length > 0) && <div className={`${styles.MondalBodyDeRow2}`}>
      <p className={`${styles.FullTitle}`}>Payment Details</p>
      <div className={`${styles.ModalDeFullSec}`}>
        <div className={`${styles.TableSec}`}>
          <table>
            <tr>
              <td className={`${styles.TableHead}`} style={{background:'#E64F22', color:'#ffffff'}}>Paid Amount</td>
              <td className={`${styles.TableHead}`} style={{background:'#E64F22', color:'#ffffff'}}>Payment Notes</td>
              <td className={`${styles.TableHead}`} style={{background:'#E64F22', color:'#ffffff'}}>Created By</td>
              <td className={`${styles.TableHead}`} style={{background:'#E64F22', color:'#ffffff'}}>Created Date</td>
            </tr>
            {props?.bookingData?.payments?.map((item,index)=>{
              return (<tr key={index}>
                <td className={`${styles.TableCon}`}>£{parseFloat(item.amount).toFixed(2)}</td>
                <td className={`${styles.TableCon}`}>{item.note}</td>
                <td className={`${styles.TableCon}`}>{item.name}</td>
                <td className={`${styles.TableCon}`}>{moment(item.created_at).format('DD/MM/YYYY LT')}</td>
              </tr>);
            })}
          </table>
        </div>
      </div>
    </div>}


  </div>);
});