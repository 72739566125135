import { Navigate } from "react-router-dom";
const withCustomerAuth = (WrappedComponent) => {
  return (props) => {
    if (typeof window !== "undefined") {
      const authData = localStorage.getItem("authData");
      if(authData){
        return <WrappedComponent {...props} />
      }else{
        return <Navigate to={'/sign-in'} />
      }
    }
    return null;
  };
};

export default withCustomerAuth;