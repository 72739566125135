import React from 'react';
import styles from './aboutus.module.css';
import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import withNavigateHook from '../../../customer/components/withNavigateHook';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const testimonial = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class Aboutus extends React.Component {
  constructor(props){
    super(props);
    this.state={
    };
  }

  render() {
    return (
      <React.Fragment>

      <div className={`${styles.BannerSec}`}>
        <img src="/images/banner1.jpg" alt="" />
        <div className={`${styles.BannerTitleCU}`}>
          <div className={`${styles.container}`}>
            <h1 className={`${styles.Heading}`}>About Us</h1>
          </div>
        </div>
      </div>

      <div className={`${styles.We_AreSec}`}>
        <div className={`${styles.Container}`}>
            <div className={`${styles.head5}`}>
              <p className={`${styles.We_AreTitle} ${styles.Heading}`}>
                Who we <span>Are</span>?
              </p>
            </div>
            <p className={`${styles.We_AreSmallTitle}`}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
              praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
              cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et
              dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta
              nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis
              voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut
              rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque
              earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur
              aut perferendis doloribus asperiores repellat."</p>
        </div>
      </div>

      <div className={`${styles.What_ServeSec}`}>
        <div className={`${styles.Container}`}>
          <p className={`${styles.We_AreTitle} ${styles.Heading}`}>
            What we <span>Serve</span>?
          </p>
          <div className={`${styles.Row}`}>
            <div className={`${styles.ServeIconCard}`}>
              <div className={`${styles.white_box}`}>
                <img src="/images/serve1.png" alt="" />
                <h5>Pickup</h5>
              </div>
            </div>
            <div className={`${styles.ServeIconCard}`}>
              <div className={`${styles.white_box}`}>
                <img src="/images/serv2.png" alt="" />
                <h5>Architecture</h5>
              </div>
            </div>
            <div className={`${styles.ServeIconCard}`}>
              <div className={`${styles.white_box}`}>
                <img src="/images/serv3.png" alt="" />
                <h5>Automotive</h5>
              </div>
            </div>
            <div className={`${styles.ServeIconCard}`}>
              <div className={`${styles.white_box}`}>
                <img src="/images/serv4.png" alt="" />
                <h5>Chemical</h5>
              </div>
            </div>
            <div className={`${styles.ServeIconCard}`}>
              <div className={`${styles.white_box}`}>
                <img src="/images/serv6.png" alt="" />
                <h5>Consumer Products</h5>
              </div>
            </div>
            <div className={`${styles.ServeIconCard}`}>
              <div className={`${styles.white_box}`}>
                <img src="/images/serv7.png" alt="" />
                <h5>Food &Beverage</h5>
              </div>
            </div>
            <div className={`${styles.ServeIconCard}`}>
              <div className={`${styles.white_box}`}>
                <img src="/images/serv8.png" alt="" />
                <h5>Franchising</h5>
              </div>
            </div>
            <div className={`${styles.ServeIconCard}`}>
              <div className={`${styles.white_box}`}>
                <img src="/images/serv9.png" alt="" />
                <h5>Delievery</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.OurMissionSec}`}>
        <div className={`${styles.Container}`}>
          <div className={`${styles.OurMissionRow}`}>
            <div className={`${styles.OurMissionCard}`}>
              <div className={`${styles.OurMissionUnderCard}`}>
                <img src="/images/our-vison.png" alt="" />
                <h3 className={`${styles.OurMissionCardTitle}`}>Our Vision</h3>
                <p className={`${styles.OurMissionCardText}`}>Our aim is to act as an indispensable partner to our clients by helping them build and maximize
                  sustainable competitive advantages. We do this by helping them get their products to ship quickly,
                  efficiently, and safely. In addition to our expertly crafted and reliably delivered logistics solutions,
                  our clients can rest assured that every interaction will be driven by integrity and respect, the
                  fundamental principles of our business.</p>
              </div>
            </div>
            <div className={`${styles.OurMissionCard}`}>
              <div className={`${styles.OurMissionUnderCard}`}>
                <img src="/images/our-mision.png" alt="" />
                <h3 className={`${styles.OurMissionCardTitle}`}>Our Mission</h3>
                <p className={`${styles.OurMissionCardText}`}>Our mission is to deliver a positive, reliable experience to each and every one of our clients while
                  offering exceptional value in the marketplace and setting the standard for professionalism in the logistic
                  solutions we provide. In addition to operating our business profitably, we strive to operate it
                  responsibly both for our clients and our employees, for whom our goal is to provide a safe and secure work
                  environment.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.Offers}`}>
        <img src="/images/deliveryBG.svg" className={`${styles.OffersBG}`} alt="" />
        <div className={`${styles.Container}`}>
          <p className={`${styles.OffersTitle} ${styles.Heading}`}>Testimonial</p>
          <p className={`${styles.OffersTitleSM}`}>What our client say</p>
          <Carousel responsive={testimonial} className={`${styles.Testimonial}`}
          swipeable={true}
          arrows={false}
          draggable={true}
          showDots={false}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          // autoPlay={props.deviceType !== "mobile" ? true : false}
          autoPlay={true}
          autoPlaySpeed={2500}
          keyBoardControl={true}
          customTransition="2000ms all"
          transitionDuration={300}>
            <div className={`${styles.item}`}>
              <div className={`${styles.TestiHead}`}>
                <img src="/images/testi1.png" alt="" />
                <div>
                  <p className={`${styles.TestiName}`}>Winifred Nunez</p>
                  <p className={`${styles.TestiDes}`}>CEO of Lapsas</p>
                </div>
              </div>
              <p className={`${styles.TestiCon}`}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.</p>
            </div>
            <div className={`${styles.item}`}>
              <div className={`${styles.TestiHead}`}>
                <img src="/images/testi2.png" alt="" />
                <div>
                  <p className={`${styles.TestiName}`}>Winifred Nunez</p>
                  <p className={`${styles.TestiDes}`}>CEO of Lapsas</p>
                </div>
              </div>
              <p className={`${styles.TestiCon}`}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.</p>
            </div>
            <div className={`${styles.item}`}>
              <div className={`${styles.TestiHead}`}>
                <img src="/images/testi3.png" alt="" />
                <div>
                  <p className={`${styles.TestiName}`}>Winifred Nunez</p>
                  <p className={`${styles.TestiDes}`}>CEO of Lapsas</p>
                </div>
              </div>
              <p className={`${styles.TestiCon}`}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.</p>
            </div>
          </Carousel>
        </div>
      </div>


      </React.Fragment>
  )}
}

export default withNavigateHook(Aboutus);