import React from 'react';
import styles from './list.module.css';
import { TextField, Button, Menu, Tooltip, Select, MenuItem,Breadcrumbs,Typography  } from '@mui/material';
import DataTable from 'react-data-table-component';
import {  X, Filter, Eye, MapPin, Printer, Trash2 } from 'react-feather';
import axios from 'axios';
import { TableLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import CancelPopup from "./CancelPopup";
import PaymentPopup from "./PaymentPopup";
import DetailsPopup from "./DetailsPopup";
import { Link } from 'react-router-dom';
import withAdminAuth from "../../components/withAdminAuth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import download from 'downloadjs';
import { ComponentToPrintList } from './ComponentToPrintList';
import { useReactToPrint } from 'react-to-print';
import { DateRangePicker } from 'rsuite';
import PaymentCancelPopup from './PaymentCancelPopup';

const brandColor = '#cccccc';
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
    }
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#BFBFBF',
      marginLeft: '10px'
    }
  }
};

function FilterMenu ({filterBooking}){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [defaultStatus, setDefaultStatus] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const applyFilter = () =>{

    let status = '';
    if(defaultStatus || defaultStatus ===0){
      status = defaultStatus;
    }

    filterBooking({status:status});
    setAnchorEl(null);
  }

  const resetFilter = () =>{
    setDefaultStatus(null);
    filterBooking({status:'',customer_id:''});
    setAnchorEl(null);
  }

  const handleChange = (event) => {
    setDefaultStatus(event.target.value);
  };

  return (<>
    <Button disableElevation startIcon={<FilterAltIcon />} onClick={handleClick} className="TableAddBU"><Filter/>Filter</Button>
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <div className={`${styles.FilterSec}`}>
        <div className={`${styles.FilterCard}`}>
          <label>Status:</label>
          {/*<Select styles={customStyles} options={[
            {value:-1, label:'Order Cancelled - UK'},
            {value:0, label:'Order Booked - UK'},
            {value:1, label:'Item Assigned To Driver - UK'},
            {value:2, label:'Item Picked Up - UK'},
            {value:3, label:'Item In Warehouse - UK'},
            {value:4, label:'Item Shipped From Warehouse - UK'},
            {value:5, label:'Item Received In Warehouse - Ghana'},
            {value:6, label:'Item Out For Delivery - Ghana'},
            {value:7, label:'Delivered - Ghana'},
            ]} defaultValue={defaultStatus} onChange={setDefaultStatus} />*/}
            <Select styles={customStyles} onChange={handleChange} value={defaultStatus}>
              <MenuItem value={-1}>Order Cancelled - UK</MenuItem>
              <MenuItem value={0}>Order Booked - UK</MenuItem>
              <MenuItem value={1}>Item Assigned To Driver - UK</MenuItem>
              <MenuItem value={2}>Item Picked Up - UK</MenuItem>
              <MenuItem value={3}>Item In Warehouse - UK</MenuItem>
              <MenuItem value={4}>Item Shipped From Warehouse - UK</MenuItem>
              <MenuItem value={5}>Item Received In Warehouse - Ghana</MenuItem>
              <MenuItem value={6}>Item Out For Delivery - Ghana</MenuItem>
              <MenuItem value={7}>Delivered - Ghana</MenuItem>
            </Select>
        </div>
        <div className={`${styles.FilterBuArea}`}>
          <button type='button' onClick={resetFilter.bind(this)}>Reset</button>
          <button type='button' onClick={applyFilter.bind(this)}>Apply</button>
        </div>
      </div>
    </Menu>
  </>)
}

function DataTableWithExport ({columns,data,customStyles,conditionalRowStyles}){
  const componentRef = React.useRef();

  const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)} className="TableAddBU" style={{width:'auto'}}>Export To CSV</Button>;

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    //const keys = Object.keys(props.data[0]);
    const keys = ['booking_id', 'customer_name', 'order_date', 'total_value', 'paid_amount', 'order_status', 'pickup_post_code', 'pickup_date', 'created_user','registered_or_guest_user', 'complaint_status'];

    result = '';
    //result += keys.join(columnDelimiter);
    keys.forEach(key => {
      if(key === 'booking_id'){
        result += 'Booking Id';
      }else if(key === 'customer_name'){
        result += 'Customer Name';
      }else if(key === 'order_date'){
        result += 'Order Date';
      }else if(key === 'total_value'){
        result += 'Total Value';
      }else if(key === 'paid_amount'){
        result += 'Paid Amount';
      }else if(key === 'order_status'){
        result += 'Order Status';
      }else if(key === 'pickup_post_code'){
        result += 'Pickup Post Code';
      }else if(key === 'pickup_date'){
        result += 'Pickup Date';
      }else if(key === 'created_user'){
        result += 'Created User';
      }else if(key === 'registered_or_guest_user'){
        result += 'Registered Or Guest User';
      }else if(key === 'complaint_status'){
        result += 'Complaint Status';
      }else{
        result += key;
      }
      result += columnDelimiter;
    });
    result += lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }

  const downloadCSV = React.useCallback(() => {
    let newArray = data.map(row=>{
      return {'booking_id': row.reference_id, 'customer_name': row.customer_name, 'order_date': moment(row.created_at).format('DD/MM/YYYY LT'), 'total_value': '£'+parseFloat(row.total).toFixed(2), 'paid_amount': '£'+parseFloat(row.paid_amount).toFixed(2), 'order_status': (row?.status === -1 || row?.status === '-1') ? 'Cancel':((row?.payment_status === 1 || row?.payment_status === '1') ? 'Paid':'Unpaid'), 'pickup_post_code': row.post_code, 'pickup_date': moment(row.pickup_date).format('DD/MM/YYYY'), 'created_user': row.created_user, 'registered_or_guest_user': row.created_user_type, 'complaint_status': 'No Complaints'}
    });

    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(newArray);
    if (csv == null) return;
    
    const filename = 'booking-list.csv';
    
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  },[data]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const actionsMemo = React.useMemo(() => <><Export onExport={() => downloadCSV()} /><Button onClick={handlePrint} className="TableAddBU" style={{width:'auto'}}>Print Bookings</Button></>, [downloadCSV,handlePrint]);

  return <><div style={{ display: "none" }}><ComponentToPrintList ref={componentRef} data={data} /></div>
  <DataTable className='DataTable2'
    columns={columns}
    data={data}
    pagination
    customStyles={customStyles}
    conditionalRowStyles={conditionalRowStyles}
    actions={actionsMemo}
    fixedHeader={true}
		fixedHeaderScrollHeight={'633px'}
  /></>
}

class BookingList extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.state = { allData: [], data: [],filterText:'',modalShow:false,deleteModalShow:false,detailsModalShow:false,paymentModalShow:false,paymentModalShow2:false,loading:false, contentLoading:true, currentItem:0, currentItemStr:'', filterData: {customer_id: '' , status: '',start_date: '', end_date: ''}  };

    this.audience = process.env.REACT_APP_API_URL;
    this.app_folder_path = process.env.REACT_APP_FOLDER_PATH;
    this.authData = authData();

    this.columns = [
      {
        name: 'Booking Id',
        selector: row => row.reference_id,
        sortable: true,
        width: '7%'
      },
      {
        name: 'Customer Name',
        selector: row => row.customer_name,
        sortable: true,
        width: '11%'
      },
      {
        name: 'Order Date',
        selector: row => moment(row.created_at).format('DD/MM/YYYY LT'),
        sortable: true,
        width: '8%'
      },
      {
        name: 'Total Value',
        cell: tableProps => <>&pound; {parseFloat(tableProps.total).toFixed(2)}</>,
        sortable: true,
        width: '8%'
      },
      {
        name: 'Paid Amount',
        cell: tableProps => <>&pound; {parseFloat(tableProps.paid_amount).toFixed(2)}</>,
        sortable: true,
        width: '8%'
      },
      {
        name: 'Due Balance',
        cell: tableProps => <>&pound; {(parseFloat(tableProps.total)-parseFloat(tableProps.paid_amount)).toFixed(2)}</>,
        sortable: true,
        width: '8%'
      },
      {
        name: 'Order Status',
        selector: tableProps => (<>
          {(tableProps?.status === -1 || tableProps?.status === '-1') ? <Button className={`${styles.StatusBU} ${styles.DisaBU}`}>Cancel</Button>:((tableProps?.payment_status === 1 || tableProps?.payment_status === '1') ? <Button className={`${styles.StatusBU} ${styles.DisaBU}`} onClick={this.paymentModalOpen2.bind(this,tableProps?.id)}>Paid</Button>:<Button className={`${styles.StatusBU}`} onClick={this.paymentModalOpen.bind(this,tableProps?.id)}>Unpaid</Button>)}
        </>),
        sortable: true,
        width: '10%'
      },
      {
        name: 'Pickup Post Code',
        selector: row => row.post_code,
        sortable: true,
        width: '8%'
      },
      {
        name: 'Pickup Date',
        selector: row => moment(row.pickup_date).format('DD/MM/YYYY'),
        sortable: true,
        width: '9%'
      },
      {
        name: 'Created User',
        selector: row => row.created_user,
        sortable: true,
        width: '11%'
      },
      /*{
        name: 'Registered Or Guest User',
        selector: row => row.created_user_type,
        sortable: true,
        width: '8%'
      },
      {
        name: 'Complaint Status',
        selector: row => 'No Complaints',
        sortable: true,
        width: '8%'
      },*/
      {
        name: 'Action',
        cell: tableProps  => (
          <div className={(tableProps?.status === -1 || tableProps?.status === '-1') ?`${styles.ActionDiv} ${styles.ActionDivCancel}`:`${styles.ActionDiv}`}>
          <Tooltip title="View"><Button onClick={this.detailsModalOpen.bind(this,tableProps?.id,tableProps?.reference_id)}><Eye /></Button></Tooltip>
          <Tooltip title="Print"><Button onClick={this.downloadItemList.bind(this,tableProps?.id)}><Printer /></Button></Tooltip>
          {/*<Tooltip title="Print"><Button onClick={this.downloadItemList.bind(this,tableProps?.id)}><Printer /></Button></Tooltip>*/}
          {(tableProps?.status === -1 || tableProps?.status === '-1') ? <Tooltip title="Delete this booking"><Button onClick={this.deleteModalOpen.bind(this,tableProps?.id)}><Trash2 /></Button></Tooltip>:<Tooltip title="Cancel or keep this booking"><Button onClick={this.cancelModalOpen.bind(this,tableProps?.id)}><X /></Button></Tooltip>}
          <Tooltip title="Equiry your order"><Link as={Button} to={'/admin/booking/enquery/'+tableProps?.reference_id}><MapPin /></Link></Tooltip>
          {/*<Tooltip title="Request for certificate"><Button><ExternalLink /></Button></Tooltip>*/}
        </div> ),
        sortable: false,
        width: '12%'
      }
    
    ];

    this.customDataTableStyles = {
      head: {
        style: {
          zIndex:0
        },
      },
      headCells: {
        style: {
            backgroundColor: '#1e1e2d',
            color: '#fff'
        },
      },
      rows: {
        style: {
          backgroundColor: '#FFF',
        },
      },
    }  

    this.conditionalRowStyles = [
      {
        when: row => (row?.status === -1 || row?.status === '-1'),
        style: row => ({ backgroundColor: 'rgba(255,0,0,0.7)' }),
      },
      {
        when: row => (row?.status === 1 || row?.status === '1'),
        style: row => ({ backgroundColor: 'rgba(230, 79, 34, 0.1)' }),
      },
      {
        when: row => (row?.status === 2 || row?.status === '2'),
        style: row => ({ backgroundColor: 'rgba(230, 79, 34, 0.4)' }),
      },
      {
        when: row => (row?.status === 3 || row?.status === '3'),
        style: row => ({ backgroundColor: 'rgba(230, 79, 34, 0.7)' }),
      },
      {
        when: row => (row?.status === 4 || row?.status === '4'),
        style: row => ({ backgroundColor: 'rgba(42, 146, 245, 0.4)' }),
      },
      {
        when: row => (row?.status === 5 || row?.status === '5'),
        style: row => ({ backgroundColor: 'rgba(42, 146, 245, 0.7)' }),
      },
      {
        when: row => (row?.status === 6 || row?.status === '6'),
        style: row => ({ backgroundColor: 'rgba(5, 156, 42, 0.5)' }),
      },
      {
        when: row => (row?.status === 7 || row?.status === '7'),
        style: row => ({ backgroundColor: 'rgba(5, 156, 42, 0.9)' }),
      },
    ];
  }
  
  async componentDidMount() {
    try {
      axios(process.env.REACT_APP_API_URL + 'v1/bookings', {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        }
      }).then(response => {
        if(response.data.status === 'SUCCESS'){
          let allData = response.data.data;
          this.setState({ ...this.state, allData: allData, data: allData,contentLoading:false});
        }else{
          this.setState({ ...this.state, contentLoading:false});
        }
      }).catch(error=>{
        this.setState({ ...this.state, contentLoading:false});
      });
    } catch (error) {
      this.setState({ ...this.state, contentLoading:false});
    }
  }
  onFilter(e){
    this.setState({ ...this.state, filterText: e.target.value },this.filterHandler);
  }
  clearFilter(){
    this.setState({ ...this.state, filterText: '' },this.filterHandler);
  }

  filterHandler() {
    let filterData = this.state.filterData;

    let filteredData = this.state.allData;

    if (this.state.filterText !== '') {
      let inputVal = this.state.filterText;
      inputVal = inputVal.toLowerCase();
      filteredData = filteredData.filter((item) => {
        return item?.reference_id.toLowerCase().includes(inputVal) || item?.customer_name.toLowerCase().includes(inputVal) || item?.post_code.toLowerCase().includes(inputVal);
      });
    }

    if(filterData.customer_id !== ''){
      filteredData = filteredData.filter((item) => item.user_id === filterData.customer_id);
    }

    if(filterData.status !== ''){
      filteredData = filteredData.filter((item) => parseInt(item.status) === filterData.status);
    }

    if(filterData?.start_date !== '' && filterData?.end_date !== ''){
      filteredData = filteredData.filter((item) => moment(item.pickup_date).isBetween(moment(filterData?.start_date),moment(filterData?.end_date), 'days', '[]'));
    }

    this.setState({ ...this.state, data: filteredData });
  }

  filterBooking(data){
    let filterData = this.state.filterData;
    filterData = {...filterData,...data};
    this.setState({ ...this.state, filterData: filterData},this.filterHandler);
  }

  downloadItemList(id){
    axios(process.env.REACT_APP_API_URL + 'v1/bookings-item-pdf/'+id,{
      responseType: 'blob'
    }).then(response => {
      const content = response.headers['content-type'];
      download(response.data, 'download.pdf', content)
   });
  }

  camcelItem(message,id){
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
    
    let allData = this.state.allData;
    let data = this.state.data;
    let allDataTemp = allData.map(i=> {
      if(i.id === id)
        return {...i,status:-1}
      return i;
    });
    let dataTemp = data.map(i=> {
      if(i.id === id)
        return {...i,status:-1}
      return i;
    });
    this.setState({ ...this.state, allData: allDataTemp, data: dataTemp,loading:false});
  }

  paymentItem(message,item){
    toast.success(message);
    
    let allData = this.state.allData;
    let data = this.state.data;
    let allDataTemp = allData.map(i=> {
      if(i.id === item.id)
        return item;
      return i;
    });
    let dataTemp = data.map(i=> {
      if(i.id === item.id)
        return item;
      return i;
    });
    this.setState({ ...this.state, allData: allDataTemp, data: dataTemp,loading:false});
  }

  paymentItem2(message,id){
    toast.success(message);
    
    let allData = this.state.allData;
    let data = this.state.data;
    let allDataTemp = allData.map(i=> {
      if(i.id === id)
        return {...i,payment_status:0}
      return i;
    });
    let dataTemp = data.map(i=> {
      if(i.id === id)
        return {...i,payment_status:0}
      return i;
    });
    this.setState({ ...this.state, allData: allDataTemp, data: dataTemp,loading:false});
  }
  
  paymentModalOpen(id){
    this.setState({...this.state,paymentModalShow:true, currentItem:id});
  }

  paymentModalOpen2(id){
    this.setState({...this.state,paymentModalShow2:true, currentItem:id});
  }

  cancelModalOpen(id){
    this.setState({...this.state,modalShow:true, currentItem:id});
  }

  deleteModalOpen(id){
    this.setState({...this.state,deleteModalShow:true, currentItem:id});
  }

  detailsModalOpen(id,idStr){
    this.setState({...this.state, detailsModalShow:true, currentItem:id , currentItemStr:idStr});
  }

  modalClose(){
    this.setState({...this.state, modalShow:false, currentItem:0, detailsModalShow:false, paymentModalShow:false, paymentModalShow2:false, deleteModalShow:false});
  }

  changeDate(e){
    let filterData = this.state.filterData;
    if(e){
      filterData = {...filterData, start_date: moment(e[0]).format('YYYY-MM-DD'), end_date: moment(e[1]).format('YYYY-MM-DD') };
    }else{
      filterData = {...filterData,start_date: '', end_date: '' };
    }
    this.setState({ ...this.state, filterData: filterData},this.filterHandler);
  }

  deleteBooking(){
    this.setState({ ...this.state, loading:true,deleteModalShow:false});

    axios.delete(process.env.REACT_APP_API_URL + 'v1/bookings/'+this.state.currentItem, {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      }
    }).then(response => {
      let allData = this.state.allData;
      let data = this.state.data;
      let allDataTemp = allData.filter(i=> i?.id !== this.state.currentItem);
      let dataTemp = data.filter(i=> i?.id !== this.state.currentItem);
    
      this.setState({ ...this.state, allData: allDataTemp, data: dataTemp,loading:false,currentItem: 0},this.filterHandler);
    }).catch(error => {
      this.setState({ ...this.state, loading:false});
    });
  }
  
  render() {
    return (
      <>
      {this.state.loading && <Loader />}
      {this.state.contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>}
      {!this.state.contentLoading && <div className={`${styles.MainDiv}`}>
        <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>Booking List</p>
          <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
            <Typography color="text.primary">Bookings</Typography>
            <Typography color="text.primary">List</Typography>
          </Breadcrumbs>
        </div>

        <div className={`${styles.MainCard}`}>
          <div className='TableFilterHead'>
            <div className="DataTableSearch">
              <TextField id="search" type="text" placeholder="Search" aria-label="Search Input" value={this.state.filterText} onChange={this.onFilter.bind(this)} autoComplete='off' />
              {this.state.filterText && <Button type="button"  className="CloseBU" onClick={this.clearFilter.bind(this)}><X/></Button>}
            </div>
            <div style={{width:'278px', marginLeft:'16px'}}>
              <DateRangePicker
                showOneCalendar
                format="dd/MM/yyyy"
                placeholder="Search by date"
                ranges={[]}
                onChange={this.changeDate.bind(this)}
              /> 
            </div>
            <div className='TableFilterBUSec'>
              <FilterMenu filterBooking={this.filterBooking.bind(this)} />
            </div>
          </div>
          <div>
            <div style={{float:'left'}}><span style={{display:'inline-block',width:'15px', height: '15px',background:'#FFFFFF', margin: '0 5px 0 15px', border:'#000 1px solid'}}></span><span>Booked - UK</span></div>
            <div style={{float:'left'}}><span style={{display:'inline-block',width:'15px', height: '15px',background:'rgba(255,0,0,0.7)', margin: '0 5px 0 15px'}}></span><span>Cancelled - UK</span></div>
            <div style={{float:'left'}}><span style={{display:'inline-block',width:'15px', height: '15px',background:'rgba(230, 79, 34, 0.1)', margin: '0 5px 0 15px'}}></span><span>Assigned To Driver - UK</span></div>
            <div style={{float:'left'}}><span style={{display:'inline-block',width:'15px', height: '15px',background:'rgba(230, 79, 34, 0.4)', margin: '0 5px 0 15px'}}></span><span>Picked Up - UK</span></div>
            <div style={{float:'left'}}><span style={{display:'inline-block',width:'15px', height: '15px',background:'rgba(230, 79, 34, 0.7)', margin: '0 5px 0 15px'}}></span><span>In Warehouse - UK</span></div>
            <div style={{float:'left'}}><span style={{display:'inline-block',width:'15px', height: '15px',background:'rgba(42, 146, 245, 0.4)', margin: '0 5px 0 15px'}}></span><span>Shipped From Warehouse - UK</span></div>
            <div style={{float:'left'}}><span style={{display:'inline-block',width:'15px', height: '15px',background:'rgba(42, 146, 245, 0.5)', margin: '0 5px 0 15px'}}></span><span>Received In Warehouse - Ghana</span></div>
            <div style={{float:'left'}}><span style={{display:'inline-block',width:'15px', height: '15px',background:'rgba(5, 156, 42, 0.5)', margin: '0 5px 0 15px'}}></span><span>Out For Delivery - Ghana</span></div>
            <div style={{float:'left'}}><span style={{display:'inline-block',width:'15px', height: '15px',background:'rgba(5, 156, 42, 0.9)', margin: '0 5px 0 15px'}}></span><span>Delivered - Ghana</span></div>
          </div>
          
          <div className='TableContent'>
            <DataTableWithExport  columns={this.columns} data={this.state.data} customStyles={this.customDataTableStyles} conditionalRowStyles={this.conditionalRowStyles} />
          </div>
        </div>
      </div>}

      <Modal show={this.state.modalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CancelPopup modalClose={this.modalClose.bind(this)} camcelItem={this.camcelItem.bind(this)} bookingId={this.state.currentItem} />
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={this.state.paymentModalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentPopup modalClose={this.modalClose.bind(this)} paymentItem={this.paymentItem.bind(this)} bookingId={this.state.currentItem} />
        </Modal.Body>
      </Modal>

      <Modal show={this.state.paymentModalShow2} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentCancelPopup modalClose={this.modalClose.bind(this)} paymentItem={this.paymentItem2.bind(this)} bookingId={this.state.currentItem} />
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={this.state.detailsModalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Order Information: #{this.state.currentItemStr}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {(this.state.currentItem > 0) && <DetailsPopup bookingId={this.state.currentItem} />}

        </Modal.Body>
      </Modal>

      <Modal show={this.state.deleteModalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Delete Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`${styles.FullDiv}`}>
            <p className={`${styles.PopupTitle}`}>Are you sure to delete this booking?</p>
            <div className={`${styles.FormButtonArea}`}>
              <input className={`${styles.CancelBU}`} value="No" type="button" onClick={this.modalClose.bind(this)} />
              <input className={`${styles.SubmitBU}`} value="Yes" type="button" onClick={this.deleteBooking.bind(this)}  />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      </>
    );
  }
}

export default withAdminAuth(BookingList);