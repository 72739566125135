import React from 'react';
import styles from './booking.module.css';
import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import withNavigateHook from '../../../customer/components/withNavigateHook';
import {Stepper,Step,Typography,StepLabel} from '@mui/material';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import { Mail, Phone, X } from 'react-feather';
import PhoneInput,{isValidPhoneNumber } from 'react-phone-number-input';
import {Dialog, DialogContent} from '@mui/material';
import axios from 'axios';

const steps = ['Parcel Details', 'Pickup Address', 'Recipient Address', 'Order Summary', 'Checkout'];

class Booking extends React.Component {
  constructor(props){
    super(props);
    this.state={
      bookingData: {},
      activeStep:0,
      skipped: new Set(),
      open1: false,
      phoneError: '',
      phoneValue: '',
      emailValue: '',
      emailError: '',
      fieldType: 'Email',
      allProducts : [],
      deliveryAllProducts : []
    };
  }
  
  componentWillMount(){
    let bookingDataTemp = localStorage.getItem("bookingData");
    if(bookingDataTemp){
      bookingDataTemp = JSON.parse(bookingDataTemp);
      this.setState({...this.state, bookingData : bookingDataTemp, activeStep:bookingDataTemp?.activeStep});
    }else{
      this.setState({...this.state, open1 : true, activeStep:0});
    }

    axios.get(process.env.REACT_APP_API_URL + 'v1/product-dd-list').then(response => {
      if(response.data.status === 'SUCCESS'){
        this.setState({...this.state, allProducts : response.data.data});
      }
    });

    axios.get(process.env.REACT_APP_API_URL + 'v1/delivery-product-dd-list').then(response => {
      if(response.data.status === 'SUCCESS'){
        this.setState({...this.state, deliveryAllProducts : response.data.data});
      }
    });
  }

  saveStep1Data(data){
    let bookingDataTemp = this.state.bookingData;

    let newSkipped = this.state.skipped;
    if (this.isStepSkipped(this.state.activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(this.state.activeStep);
    }

    bookingDataTemp = {...bookingDataTemp, ...data,activeStep: this.state.activeStep+1};
    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));

    this.setState({...this.state,activeStep:(this.state.activeStep+1), skipped:newSkipped, bookingData : bookingDataTemp});
  }

  saveStep2Data(pickUpData){
    let bookingDataTemp = this.state.bookingData;

    let newSkipped = this.state.skipped;
    if (this.isStepSkipped(this.state.activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(this.state.activeStep);
    }

    bookingDataTemp = {...bookingDataTemp, pickUpData:pickUpData,activeStep: this.state.activeStep+1};
    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));

    this.setState({...this.state,activeStep:(this.state.activeStep+1), skipped:newSkipped, bookingData : bookingDataTemp});
  }

  saveStep3Data(dropOffData){
    let bookingDataTemp = this.state.bookingData;

    let newSkipped = this.state.skipped;
    if (this.isStepSkipped(this.state.activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(this.state.activeStep);
    }

    bookingDataTemp = {...bookingDataTemp, dropOffData:dropOffData,activeStep: this.state.activeStep+1};
    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));

    this.setState({...this.state,activeStep:(this.state.activeStep+1), skipped:newSkipped, bookingData : bookingDataTemp});
  }

  saveStep4Data(){
    let newSkipped = this.state.skipped;
    if (this.isStepSkipped(this.state.activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(this.state.activeStep);
    }

    this.setState({...this.state,activeStep:(this.state.activeStep+1), skipped:newSkipped});
  }
  
  isStepOptional(step){
    return step === 1;
  };

  
  isStepSkipped(step){
    return this.state.skipped.has(step);
  };

  handleBack(){
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });

    let activeStep = this.state.activeStep;
    activeStep = activeStep-1;
    this.setState({...this.state,activeStep:activeStep});
  };

  handleNext(){
    let newSkipped = this.state.skipped;
    if (this.isStepSkipped(this.state.activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(this.state.activeStep);
    }

    this.setState({...this.state,activeStep:(this.state.activeStep+1), skipped:newSkipped});
  };

  bookingModalClose(){
    this.setState({...this.state, open1: false});
  };

  cngPhoneValue(e){
    this.setState({...this.state,phoneError: '', phoneValue: e});
  }

  cngEmailValue(e){
    this.setState({...this.state,emailError: '', emailValue: e.target.value});
  }

  goToBookingByPhone(){
    if(this.state.phoneValue === ''){
      this.setState({...this.state,phoneError: 'Please enter your phone no.'});
      return false;
    }
    if(!isValidPhoneNumber(this.state.phoneValue)){
      this.setState({...this.state,phoneError: 'Please enter valid phone no.'});
      return false;
    }
    let bookingData = {
      keyType: 'phone',
      keyValue: this.state.phoneValue,
      activeStep:0
    };
    localStorage.setItem('bookingData', JSON.stringify(bookingData));
    this.setState({...this.state, bookingData : bookingData, open1: false});
  }

  goToBookingByEmail(){
    if(this.state.emailValue === ''){
      this.setState({...this.state,emailError: 'Please enter your email id.'});
      return false;
    }
    if(!/\S+@\S+\.\S+/.test(this.state.emailValue)){
      this.setState({...this.state,emailError: 'Please enter valid email id.'});
      return false;
    }
    let bookingData = {
      keyType: 'email',
      keyValue: this.state.emailValue,
      activeStep:0
    };
    localStorage.setItem('bookingData', JSON.stringify(bookingData));
    this.setState({...this.state, bookingData : bookingData, open1: false});
  }

  cngFieldType(type){
    this.setState({...this.state, fieldType : type});
  }

  render() {
    

    return (
      <React.Fragment>
        <div className={`${styles.BookingSec}`}>
        <img src="/images/booking-bg.jpg" alt='banner' className={`${styles.BackgroundBG}`} />
          <div className={`${styles.Container}`}>
            <div className={((this.state.activeStep === 2)?`${styles.BookingUnderDiv} ${styles.BookingUnderDiv2}`:((this.state.activeStep === 1)?`${styles.BookingUnderDiv} ${styles.BookingUnderDiv1}`:`${styles.BookingUnderDiv}`))}>
              <Stepper activeStep={this.state.activeStep} className={`${styles.StepperSec} StepperSec`}>
                {steps?.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (this.isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption"></Typography>
                    );
                  }
                  if (this.isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (<Step key={label} {...stepProps}><StepLabel {...labelProps}>{label}</StepLabel></Step>);
                })}
              </Stepper>

              <React.Fragment>
                {(this.state.activeStep ===0) && <Step1 saveStep1Data={this.saveStep1Data.bind(this)} bookingData={this.state.bookingData} deliveryAllProducts={this.state.deliveryAllProducts} allProducts={this.state.allProducts} />}
                {(this.state.activeStep ===1) && <Step2 saveStep2Data={this.saveStep2Data.bind(this)} bookingData={this.state.bookingData} />}
                {(this.state.activeStep ===2) && <Step3 saveStep3Data={this.saveStep3Data.bind(this)} bookingData={this.state.bookingData} />}
                {(this.state.activeStep ===3) && <Step4 saveStep4Data={this.saveStep4Data.bind(this)} bookingData={this.state.bookingData} />}
                {(this.state.activeStep ===4) && <Step5 bookingData={this.state.bookingData} handleNext={this.handleNext.bind(this)} />}
                {(this.state.activeStep ===5) && <Step6 />}

                <div className={`${styles.StepperBUSec}`}>
                {(this.state.activeStep < 5) && <button className={`${styles.NextBU}`} onClick={this.handleBack.bind(this)} disabled={this.state.activeStep === 0}>Back</button>}

                  {(this.state.activeStep === 0) && <button type="submit" form="step1-form" className={`${styles.NextBU}`}>Next</button>}
                  {(this.state.activeStep === 1) && <button type="submit" form="step2-form" className={`${styles.NextBU}`}>Next</button>}
                  {(this.state.activeStep === 2) && <button type="submit" form="step3-form" className={`${styles.NextBU}`}>Next</button>}
                  {(this.state.activeStep === 3) && <button type="submit" form="step4-form" className={`${styles.NextBU}`}>Next</button>}
                  {(this.state.activeStep === 4) && <button className={`${styles.NextBU}`} onClick={this.handleNext.bind(this)} disabled>Next</button>}
                </div>

              </React.Fragment>
            </div>
          </div>
        </div>

        <Dialog open={this.state.open1} onClose={this.bookingModalClose.bind(this)} PaperProps={{ sx: { position: "fixed", top: 250} }}>
          <DialogContent>
            <div className={`${styles.ModalBody}`}>
              <p className={`${styles.PopupHeading} ${styles.Heading}`}>Book Pickup And Delivery</p>

              <div>
                <div className={`${styles.Tabs}`}>
                  <div className={(this.state.fieldType === 'Email')?`${styles.TabCard} ${styles.Active}`:`${styles.TabCard}`} onClick={this.cngFieldType.bind(this,'Email')}>
                    <Mail/> <p>Email</p>
                  </div>
                  <div className={(this.state.fieldType === 'Phone')?`${styles.TabCard} ${styles.Active}`:`${styles.TabCard}`} onClick={this.cngFieldType.bind(this,'Phone')}>
                    <Phone/> <p>Phone</p>
                  </div>
                </div>
                {(this.state.fieldType === 'Email') && <><div className={`${styles.FormSec}`}>
                  <input type="text" placeholder='Please enter your email' onChange={this.cngEmailValue.bind(this)} />
                  <button type='button' onClick={this.goToBookingByEmail.bind(this)}>Submit</button>
                </div>
                {(this.state.emailError !== '') && <p className={`${styles.TabErrorM}`}>{this.state.emailError}</p>}
                </>}
                {(this.state.fieldType === 'Phone') && <><div className={`${styles.FormSec} FormSec`}>
                  <PhoneInput 
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="GB"
                    value={this.state.phoneValue}
                    onChange={this.cngPhoneValue.bind(this)}
                    countries={["GB","GH"]}
                  />
                  <button type='button' onClick={this.goToBookingByPhone.bind(this)}>Submit</button>
                </div>{(this.state.phoneError !== '') && <p className={`${styles.TabErrorM}`}>{this.state.phoneError}</p>}
              </>}
              </div>

              <button type='button' onClick={this.bookingModalClose.bind(this)} className={`${styles.ModalCloseBU}`}><X/></button>
            </div>
          </DialogContent>
        </Dialog>

      </React.Fragment>
  )}
}

export default withNavigateHook(Booking);