import React from 'react';
import styles from './LoginForm.module.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Lock, Mail } from 'react-feather';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from  '../../../admin/components/Loader';

const schema = yup.object().shape({
  email: yup.string().required('This field is Required').email('Invalid E-mail'),
  password: yup.string().required('This field is Required'),
});

export default function LoginForm({onSubmit, isFP}) {
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, seterrorMsg] = React.useState('');

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });
  
  async function onSubmitLocal(data) {
    const postData = data;
    setLoading(true);
    seterrorMsg('');
    
    return axios.post(process.env.REACT_APP_API_URL + 'v1/login', postData).then(response => {
      setLoading(false);
      if(response.data.status === 'SUCCESS'){
        const resData = response.data.data;
        localStorage.setItem('authData', JSON.stringify(resData));
        onSubmit();
      }
      if(response.data.status === 'ERROR'){
        seterrorMsg(response?.data?.message);
      }
    }).catch(error => {
      if(error && error?.response && error?.response?.data && error?.response?.data?.message){
        seterrorMsg(error?.response?.data?.message);
      }
      setLoading(false);
    });
  }

  return (
    <>
    {loading && <Loader /> }
    <form onSubmit={handleSubmit(onSubmitLocal)} >
      <p className={`${styles.ErrorM}`}>{errorMsg}</p>
      
      <div className={`${styles.FormRow}`}>
        <div className={`${styles.FormGroup}`}>
          <label className={`${styles.FormLabel}`}>Email</label>
          <label className={`${styles.login_FormLabel}`}>
            <Mail/><input {...register("email")} className={`${styles.FormControl}`} />
          </label>
          {errors.email && <span className={`${styles.ErrorM}`}>{errors.email.message}</span>}
        </div>
        <div className={`${styles.FormGroup}`}>
          <label className={`${styles.FormLabel}`}>Password</label>
          <label className={`${styles.login_FormLabel}`}>
            <Lock/><input {...register("password")} type="password" className={`${styles.FormControl}`} />
          </label>
          {errors.password && <span className={`${styles.ErrorM}`}>{errors.password.message}</span>}
          {isFP && <p className={`${styles.ForhotPassText}`}><Link to={'/forgot-password'}>Forgot Password</Link></p>}
        </div>
      </div>
      
      <button type="submit" className={`${styles.LoginBU}`}><span>Login</span></button>
      {/*<p className={`${styles.LoginFormSText}`}>Don't have an account? <Link to={'/sign-up'}>Sign up</Link></p>*/}
    </form>
    </>
  )
}