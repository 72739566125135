import * as React from 'react';
import styles from './ReceiveShipping.module.css';
import withAdminAuth from "../../components/withAdminAuth";
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import Select from "react-select";
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router';
import {authData} from "../../components/getAuth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Mail, MapPin, Phone, X } from 'react-feather';
import { Alert, Breadcrumbs, Button, Snackbar, TextField, Typography } from '@mui/material';

const brandColor = '#CCCCCC';
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
    }
  })
};

function AddShipping({ history, match }) {
  const auth = authData();
  const navigate = useNavigate();
  const [containerList, setContainerList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [WHItems, setWHItems] = React.useState([]);
  const [WHFilterItems, setWHFilterItems] = React.useState([]);
  const [containerItems, setContainerItems] = React.useState([]);
  const [containerFilterItems, setContainerFilterItems] = React.useState([]);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [shippingData, setShippingData] = React.useState(null);
  const [shippingId, setShippingId] = React.useState(0);

  const { register, control, setValue } = useForm({
    mode: 'onChange'});

  React.useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/delivery/shipping-dd-list').then(response => {
      if(response.data.status === 'SUCCESS'){
        setContainerList(response.data.data);
      }
    })
  },[]);

  React.useEffect(()=>{
    if(shippingId){
      axios.get(process.env.REACT_APP_API_URL + 'v1/delivery/shipping-details/'+shippingId).then(response => {
        if(response.data.status === 'SUCCESS'){
          let shipData = response.data.data;
          setShippingData(shipData);
          setValue('reference_id',shipData.reference_id);
          setValue('container_ref',shipData.container_ref);
          setValue('shipping_from',shipData.shipping_from);
          setValue('shipping_to',shipData.shipping_to);
          setValue('shipping_date',moment(shipData.shipping_date).toDate());
          setValue('eta_of_container',moment(shipData.eta_of_container).toDate());
          setValue('arrival_date',(shipData?.arrival_date)?moment(shipData?.arrival_date).toDate():moment().toDate());
          setValue('consignee',shipData.consignee);
          setValue('warehouse_location',{value: shipData.warehouse_location,label: shipData.warehouse_location});
        }
      })
    }
  },[shippingId,setValue]);

  React.useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/shippings/booking-list-wh').then(response => {
      if(response.data.status === 'SUCCESS'){
        setWHItems(response.data.data);
      }
    });
  },[]);

  React.useEffect(()=>{
    if(shippingId && (shippingData?.status === 2 || shippingData?.status === '2')){
      axios.get(process.env.REACT_APP_API_URL + 'v1/delivery/booking-list-ship/'+shippingId).then(response => {
        if(response.data.status === 'SUCCESS'){
          setContainerItems(response.data.data);
        }
      });
    }
  },[shippingId,shippingData]);
  
  const cngContainer = (e) =>{
    setShippingId(e?.value);
  }
  
  const columns1 = [{
    name: 'Booking Id',
    selector: row => row?.reference_id,
    sortable: false
  },{
    name: 'Items',
    selector: row => row?.no_of_item,
    sortable: false
  },{
    name: 'Loaded',
    selector: row => row?.no_of_item3,
    sortable: false
  },{
    name: 'Remaining',
    selector: row => row?.no_of_item2,
    sortable: false
  },{
    name: '',
    cell: tableProps => (<Button className={`${styles.StatusBU}`} disabled>Add All</Button>),
    sortable: false
  }];
  
  const columns2 = [{
    name: 'Booking Id',
    selector: row => row?.reference_id,
    sortable: false
  },{
    name: 'Items',
    selector: row => row?.no_of_item,
    sortable: false
  },{
    name: 'Loaded',
    selector: row => row?.no_of_item3,
    sortable: false
  },{
    name: 'Remaining',
    selector: row => row?.no_of_item2,
    sortable: false,
  },{
    name: '',
    cell: tableProps => (<Button className={`${styles.StatusBU} ${styles.StatusBU55}`}  onClick={receiveAllItem.bind(this,tableProps)} disabled={(shippingData?.status === 1 || shippingData?.status === '1')}>Receive All</Button>),
    sortable: false
  }];
  
  const columns3 = [{
    name: 'Product Id',
    selector: row => row?.item_id,
    sortable: false
  },{
    name: 'Item',
    selector: row => row?.product_title_new,
    sortable: false
  },{
    name: 'Status',
    selector: row => row?.is_container_loaded ? 'Item Loaded In Container' : 'Item In Warehouse - UK',
    sortable: false
  },{
    name: '',
    cell: tableProps => ((tableProps?.is_container_loaded === 0) ? <Button className={`${styles.StatusBU}`} disabled>Add</Button>:null),
    sortable: false
  }];
  
  const columns4 = [{
    name: 'Product Id',
    selector: row => row?.item_id,
    sortable: false
  },{
    name: 'Item',
    selector: row => row?.product_title_new,
    sortable: false
  },{
    name: 'Status',
    selector: row => row?.is_container_loaded ? 'Item Loaded In Container' : 'Item In Warehouse - UK',
    sortable: false
  },{
    name: '',
    cell: tableProps => ((tableProps?.is_container_loaded === 1) ? <Button className={`${styles.StatusBU} ${styles.StatusBU55}`} onClick={receiveItem.bind(this,tableProps)} disabled={(shippingData?.status === 1 || shippingData?.status === '1')}>Receive</Button>:null),
  }];
  
  const customDataTableStyles = {
    headRow: {
      style: {
          backgroundColor: '#1e1e2d',
          color: '#fff'
      },
    },
    head: {
      style: {
        zIndex:0
      },
    },
    headCells: {
      style: {
          backgroundColor: '#1e1e2d',
          color: '#fff'
      },
    },
    rows: {
      style: {
        backgroundColor: '#FFF',
      }
    }
  }
  
  const customDataTableStyles2 = {
    head: {
      style: {
        zIndex:0
      },
    },
    headCells: {
      style: {
          backgroundColor: '#1e1e2d',
          color: '#fff'
      },
    },
    rows: {
      style: {
        backgroundColor: '#FFF',
      }
    }
  }
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };
  
  const receiveContainer = () =>{
    if(shippingId === 0){
      setSnackOpen(true);
      return false;
    }

    setLoading(true);
    axios.get(process.env.REACT_APP_API_URL + 'v1/delivery/shipping-received/'+shippingId, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      setLoading(false);
      if(response.data.status === 'SUCCESS'){
        navigate(0);
      }
    }).catch(error => {
      setLoading(false);
    });
  }

  const receiveAllItem = (itemDet) =>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/delivery/item-received-all/'+shippingId+'/'+itemDet?.id).then(response => {
      if(response.data.status === 'SUCCESS'){
        axios.get(process.env.REACT_APP_API_URL + 'v1/delivery/booking-list-ship/'+shippingId).then(response => {
          if(response.data.status === 'SUCCESS'){
            setContainerItems(response.data.data);
          }
        });
      }
    });
  }

  const receiveItem = (itemDet) =>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/delivery/item-received/'+shippingId+'/'+itemDet?.booking_id+'/'+itemDet?.id).then(response => {
      if(response.data.status === 'SUCCESS'){
        axios.get(process.env.REACT_APP_API_URL + 'v1/delivery/booking-list-ship/'+shippingId).then(response => {
          if(response.data.status === 'SUCCESS'){
            setContainerItems(response.data.data);
          }
        });
      }
    });
  }

  const ExpandedComponent1 = ({ data }) => <div className={`${styles.ExpandedDiv}`}>
    <div className={`${styles.ExpandedDivHalf} ${styles.ExpandedDivHalf1}`}>
      <p>Pickup Address</p>
      <p className={`${styles.PickName}`}>{data?.pickup_details?.name}</p>
      <p className={`${styles.DetailsList}`}><MapPin/>{data?.pickup_details?.address}</p>
      <p className={`${styles.DetailsList}`}><Mail/>{data?.pickup_details?.email}</p>
      <p className={`${styles.DetailsList}`}><Phone/>{data?.pickup_details?.phone}</p>
    </div>
    <div className={`${styles.ExpandedDivHalf} ${styles.ExpandedDivHalf2}`}>
      <p>Delivery Address</p>
      <p className={`${styles.PickName}`}>{data?.dropoff_details?.name}</p>
      <p className={`${styles.DetailsList}`}><MapPin/>{data?.dropoff_details?.address}</p>
      <p className={`${styles.DetailsList}`}><Mail/>{data?.dropoff_details?.email}</p>
      <p className={`${styles.DetailsList}`}><Phone/>{data?.dropoff_details?.phone}</p>
    </div>

    <div style={{margin:'0 5px 20px'}}>
      <DataTable className='DataTable' columns={columns3} data={data?.products} customStyles={customDataTableStyles2} />
    </div>
    
  </div>;

  const ExpandedComponent2 = ({ data }) => <div className={`${styles.ExpandedDiv}`}>
    <div className={`${styles.ExpandedDivHalf} ${styles.ExpandedDivHalf1}`}>
      <p>Pickup Address</p>
      <p className={`${styles.PickName}`}>{data?.pickup_details?.name}</p>
      <p className={`${styles.DetailsList}`}><MapPin/>{data?.pickup_details?.address}</p>
      <p className={`${styles.DetailsList}`}><Mail/>{data?.pickup_details?.email}</p>
      <p className={`${styles.DetailsList}`}><Phone/>{data?.pickup_details?.phone}</p>
    </div>
    <div className={`${styles.ExpandedDivHalf} ${styles.ExpandedDivHalf2}`}>
      <p>Delivery Address</p>
      <p className={`${styles.PickName}`}>{data?.dropoff_details?.name}</p>
      <p className={`${styles.DetailsList}`}><MapPin/>{data?.dropoff_details?.address}</p>
      <p className={`${styles.DetailsList}`}><Mail/>{data?.dropoff_details?.email}</p>
      <p className={`${styles.DetailsList}`}><Phone/>{data?.dropoff_details?.phone}</p>
    </div>
    <div style={{margin:'0 5px 20px'}}>
      <DataTable className='DataTable' columns={columns4} data={data?.products} customStyles={customDataTableStyles2} />
    </div>
  </div>;

  const [filterText1, setFilterText1] = React.useState('');
  const [filterText2, setFilterText2] = React.useState('');

  const filterChange1 = (e) =>{
    setFilterText1(e.target.value);
  }
  
  const filterChange2 = (e) =>{
    setFilterText2(e.target.value);
  }
  
  const clearFilter1 = () =>{
    setFilterText1('');
  }
  
  const clearFilter2 = () =>{
    setFilterText2('');
  }

  React.useEffect(()=>{
    let WHItemsTemp = WHItems;
  
    if(filterText1 !== ''){
      let inputVal = filterText1;
      inputVal = inputVal.toLowerCase();
      WHItemsTemp = WHItemsTemp.filter((item) => {
        return item.reference_id.toLowerCase().includes(inputVal);
      });
    }
  
    setWHFilterItems(WHItemsTemp);
    
  },[filterText1,WHItems]);
  
  React.useEffect(()=>{
    let containerItemsTemp = containerItems;
  
    if(filterText2 !== ''){
      let inputVal = filterText2;
      inputVal = inputVal.toLowerCase();
      containerItemsTemp = containerItemsTemp.filter((item) => {
        return item.reference_id.toLowerCase().includes(inputVal);
      });
    }
  
    setContainerFilterItems(containerItemsTemp);
    
  },[filterText2,containerItems]);

  return (<>
    {loading && <Loader /> }
    <div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>Receive Shipping</p>
          <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
            <Typography color="text.primary">Ghana Delvery</Typography>
            <Typography color="text.primary">Receive Shipping</Typography>
          </Breadcrumbs>
        </div>


      <div className={`${styles.MainCard}`}>
        <div className={`${styles.StepOneForm}`}>
            <div className={`${styles.StepOneFormRow}`}>
              <div className={`${styles.StepOneFormFull}`}>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Container*</label>
                  <div className={`${styles.FieldWrap}`}>
                    <Controller
                      control={control}
                      options={containerList}
                      {...register('container_no',{required:'Please select container_no'})}
                      render={({ field }) => (
                        <Select {...field} options={containerList} onChange={(e) => {field.onChange(e); cngContainer(e)}} className={`${styles.CustomSelect}`} styles={customStyles} />
                      )}
                    />
                  </div>
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Shipping Ref</label>
                  <input type="text" placeholder="" className={`${styles.TextField}`} {...register('reference_id')} disabled />
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Seal Ref No</label>
                  <input type="text" placeholder="" className={`${styles.TextField}`} {...register('container_ref')} disabled />
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Shipping From</label>
                  <input type="text" placeholder="" className={`${styles.TextField}`} {...register('shipping_from')} disabled />
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Shipping To</label>
                  <input type="text" placeholder="" className={`${styles.TextField}`} {...register('shipping_to')} disabled />
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Shipping Date</label>
                  <Controller
                    control={control}
                    {...register('shipping_date')}
                    className={`${styles.TextField}`}
                    disabled
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        autoComplete='off'
                        minDate={moment().toDate()}
                        selected={field.value}
                        className={`${styles.TextField}`}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => field.onChange(date)}
                        ref={(ref) => {
                          field.ref({
                            focus: ref?.setFocus
                          });
                        }}
                      />
                    )}
                  />
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Eta Of Container</label>
                  <Controller
                    control={control}
                    {...register('eta_of_container')}
                    disabled
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        autoComplete='off'
                        minDate={moment().toDate()}
                        selected={field.value}
                        className={`${styles.TextField}`}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => field.onChange(date)}
                        ref={(ref) => {
                          field.ref({
                            focus: ref?.setFocus
                          });
                        }}
                      />
                    )}
                  />
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Arrival Date</label>
                  <Controller
                    control={control}
                    {...register('arrival_date')}
                    disabled
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        autoComplete='off'
                        minDate={moment().toDate()}
                        selected={field.value}
                        className={`${styles.TextField}`}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => field.onChange(date)}
                        ref={(ref) => {
                          field.ref({
                            focus: ref?.setFocus
                          });
                        }}
                      />
                    )}
                  />
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Consignee</label>
                  <input type="text" placeholder="" className={`${styles.TextField}`} {...register('consignee')} disabled />
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Warehouse Location</label>
                  <Controller
                      control={control}
                      options={[{value:'Ghana',label:'Ghana'}]}
                      {...register('warehouse_location')}
                      render={({ field }) => (
                        <Select {...field} options={[{value:'Ghana',label:'Ghana'}]} isDisabled className={`${styles.CustomSelect}`} styles={customStyles} />
                      )}
                    />
                </div>
              </div>
              <div className={`${styles.StepBUSec}`}>
                {(shippingData?.status === 1 || shippingData?.status === '1') && <button className={`${styles.Next}`} type='button' onClick={receiveContainer}>Mark Container as Received</button>}
                {(shippingData?.status === 2 || shippingData?.status === '2') && <button className={`${styles.Next5}`} disabled type='button'>Container Received</button>}
              </div>
            </div>
        </div>
      </div>
      <div className={`${styles.MainCard2}`}>
        <div className={`${styles.MainCard2Half}`} style={{marginRight:'1%'}}>
          <p>Item In UK Warehouse</p>
          <div className="DataTableSearch" style={{marginBottom: "5px"}}>
            <TextField id="search" type="text" placeholder="Search by booking id" aria-label="Search By Booking ID" value={filterText1} onChange={filterChange1} autoComplete='off'  />
            {(filterText1 !== '') && <Button type="button"  className="CloseBU" onClick={clearFilter1} ><X /></Button>}
          </div>
          <DataTable className='DataTable' columns={columns1} data={WHFilterItems} customStyles={customDataTableStyles} expandableRows expandableRowsComponent={ExpandedComponent1} />
        </div>
        <div className={`${styles.MainCard2Half}`} style={{marginLeft:'1%'}}>
          <p>Container Details</p>
          <div className="DataTableSearch" style={{marginBottom: "5px"}}>
            <TextField id="search" type="text" placeholder="Search by booking id" aria-label="Search By Booking ID" value={filterText2} onChange={filterChange2} autoComplete='off'  />
            {(filterText2 !== '') && <Button type="button"  className="CloseBU" onClick={clearFilter2} ><X /></Button>}
          </div>
          <DataTable className='DataTable' columns={columns2} data={containerFilterItems} customStyles={customDataTableStyles} expandableRows expandableRowsComponent={ExpandedComponent2} />
        </div>
      </div>
    </div>

      <Snackbar
        open={snackOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={3000}
      >
        <Alert
          onClose={handleClose}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Please select shipping first!!
        </Alert>
      </Snackbar>
  </>);
}

export default withAdminAuth(AddShipping);