import React from 'react';
import moment from 'moment';

export const ComponentToPrint = React.forwardRef((props, ref) => {

  return (<div ref={ref} style={{padding: '20px 20px'}}>
    <div style={{fontSize:'16px', fontWeight: 'bold', marginBottom:'10px'}}>Driver Name: {props?.driverName}</div>
    <table width={'100%'}>
      <tr>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Booking Id</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Postcode</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Customer Address</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Customer Name</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Customer Phone</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Pickup Date</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Pickup Notes</th>
      </tr>
      {props?.bookingData?.map(row=>{
        return <tr>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.reference_id}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.post_code}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.address}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.customer_name}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.phone}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{moment(row.pickup_date).format('DD/MM/YYYY')}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>&nbsp;</td>
        </tr>
      })}
    </table>
  </div>);
  
});