import React ,{useState} from 'react';
import styles from './forgotpassword.module.css';
import {  useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import Loader from  '../../../admin/components/Loader';
import { Link, } from 'react-router-dom';
import { Home, Mail,Lock } from 'react-feather';

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [firstStepSubmitted, setFirstStepSubmitted] = useState(false);
  const navigate = useNavigate();
  
  const schema = yup.object().shape({
    email: yup.string().required('This field is Required').email('Invalid E-mail')
  });

  const schema2 = yup.object().shape({
    email: yup.string().required('This field is Required').email('Invalid E-mail'),
    token: yup.string().required('This field is Required')
  });
  
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(firstStepSubmitted?schema2:schema), mode: 'onChange'
  });
  
  async function onSubmit(data) {
    const token = '';
    const postData = data;
    setLoading(true);
    seterrorMsg('');
    setSuccessMsg('');

    if(firstStepSubmitted){
      return axios.post(process.env.REACT_APP_API_URL + 'v1/forgot-password/code/check', postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          const resData = response.data;
          localStorage.setItem('passwordToken', resData?.token);
          setTimeout(()=>{
            navigate('/reset-password');
          },100);
        }
        if(response.data.status === 'ERROR'){
          seterrorMsg(response?.data?.message);
        }
      }).catch(error => {
        if(error && error?.response && error?.response?.data && error?.response?.data?.message){
          seterrorMsg(error?.response?.data?.message);
        }
        setLoading(false);
      });
    }else{
      return axios.post(process.env.REACT_APP_API_URL + 'v1/forgot-password/email', postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          const resData = response.data;
          setSuccessMsg(resData.message);
          setFirstStepSubmitted(true);
        }
        if(response.data.status === 'ERROR'){
          seterrorMsg(response?.data?.message);
        }
      }).catch(error => {
        if(error && error?.response && error?.response?.data && error?.response?.data?.message){
          seterrorMsg(error?.response?.data?.message);
        }
        setLoading(false);
      });
    }
    
    
  }
  
  return (
    <React.Fragment>
      {loading && <Loader /> }
      <div className={`${styles.MainDiv}`}>
        <div className={`${styles.LoginBGSec}`}>
          <img src="/images/loginBG1.jpg" alt="" />
          <img src="/images/logo.svg" alt="" className={`${styles.LoginLogo}`} />
        </div>
        
        <div className={`${styles.LoginFormSec}`}>
          <Link to={'/'} className={`${styles.BackToHome}`}><Home/> Back to home</Link>
          <div className={`${styles.LohinForm}`}>
            <img src="/images/logo.svg" alt="" className={`${styles.LoginLogoSmRe}`} />
            <h2 className={`${styles.BookingFormTitle} ${styles.Heading}`}>Forgot Password</h2>
            <form onSubmit={handleSubmit(onSubmit)} >
              <p className={`${styles.SuccessM}`}>{successMsg}</p>
              <p className={`${styles.ErrorM}`}>{errorMsg}</p>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Enter your register email</label>
                  <label className={`${styles.login_FormLabel}`}>
                    <Mail/><input {...register("email")} className={`${styles.FormControl}`} readOnly={firstStepSubmitted} />
                  </label>
                  {errors.email && <span className={`${styles.ErrorM}`}>{errors.email.message}</span>}
                </div>
              </div>               
              {firstStepSubmitted && <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Please enter code</label>
                  <label className={`${styles.login_FormLabel}`}>
                    <Lock/><input {...register("token")} className={`${styles.FormControl}`} />
                  </label>
                  {errors.token && <span className={`${styles.ErrorM}`}>Please enter code</span>}
                </div>
              </div>}           
               
              <button type="submit" className={`${styles.LoginBU}`}><span>Forgot Password</span></button>
              <p className={`${styles.LoginFormSText}`}><Link to={'/sign-in'}>Login</Link></p>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )}


export default ForgotPassword;