import * as React from 'react';
import { Checkbox, FormControlLabel} from '@mui/material';
import styles from './add.module.css';
import Select from "react-select";
import { Trash2 } from 'react-feather';
import axios from 'axios';
import { useForm, Controller,useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import {authData} from "../../../admin/components/getAuth";

const brandColor = '#CCCCCC';
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
    }
  })
};

function Step1({ saveStep1Data, bookingData }) {
  const [isRegCompany,setIsRegCompany] = React.useState({});
  const [isDeliveryProducts,setIsDeliveryProducts] = React.useState(false);
  const [DeliveryTotal,setDeliveryTotal] = React.useState(0);
  const [SubTotal,setSubTotal] = React.useState(0);
  const [Total,setTotal] = React.useState(0);
  const [NoOfItems, setNoOfItems] = React.useState(0);
  const [SelectedCat,setSelectedCat] = React.useState([]);
  const [ProductList,setProductList] = React.useState([]);
  const [DSelectedCat,setDSelectedCat] = React.useState([]);
  const [DProductList,setDProductList] = React.useState([]);
  const [deliveryAllProducts,setDeliveryAllProducts] = React.useState([]);
  const [deliveryProductDDList,setDeliveryDDProductList] = React.useState([]);
  const [allProducts,setAllProducts] = React.useState([]);
  const [productDDList,setProductDDList] = React.useState([]);
  const VATCharge = 0;
  const InsuranceCharge = 0;

  const { register, handleSubmit,control, formState: { errors }, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      delivery_products: bookingData?.delivery_products ? bookingData?.delivery_products : [{ category_id: '', item_id: '', quantity: '', total:'' }],
      products: bookingData?.products ? bookingData?.products : [{ category_id: '', item_id: '', est_amount: '', total:'', is_fragile: false  }]
    }
  });

  const { fields: fields1, append: append1, remove: remove1 } = useFieldArray({
    control,
    name: "delivery_products"
  });

  const { fields: fields2, append: append2, remove: remove2 } = useFieldArray({
    control,
    name: "products"
  });

  React.useEffect(()=>{
    setValue(`insurance`,bookingData?.insurance);
    setValue(`notes`,bookingData?.notes);
    setValue(`is_delivery`,bookingData?.is_delivery);
    setIsDeliveryProducts(bookingData?.is_delivery);
    setValue(`pickup_date`,moment(bookingData?.pickup_date).toDate());

    if(bookingData?.registered_company){
      setIsRegCompany(bookingData?.registered_company);
      setValue(`registered_company`,bookingData?.registered_company);
      if(bookingData?.registered_company?.value === "Yes"){
        setValue(`company_name`,bookingData?.company_name);
        setValue(`company_shipping`,bookingData?.company_shipping);
      }
    }else{
      setIsRegCompany({"label":authData()?.customer?.registered_company,"value":authData()?.customer?.registered_company});
      setValue(`registered_company`,{"label":authData()?.customer?.registered_company,"value":authData()?.customer?.registered_company});
      if(authData()?.customer?.registered_company === "Yes"){
        setValue(`company_name`,authData()?.customer?.company_name);
        setValue(`company_shipping`,{"label":authData()?.customer?.company_shipping,"value":authData()?.customer?.company_shipping});
      }
    }

    let deliverytotalTemp = 0;
    let subtotalTemp = 0;
    let noOfItemsTemp = 0;
    let DSelectedCatTemp = [];
    let SelectedCatTemp = [];
    bookingData?.delivery_products?.forEach(item=>{
      if(item.total !== ''){
        deliverytotalTemp += parseFloat(item.total);
        DSelectedCatTemp.push(item?.category_id?.value);
      }
    });
    bookingData?.products?.forEach(item=>{
      subtotalTemp += parseFloat(item.total);
      noOfItemsTemp += 1;
      SelectedCatTemp.push(item?.category_id?.value);
    });

    setSelectedCat(SelectedCatTemp);
    setDSelectedCat(DSelectedCatTemp);
    setNoOfItems(noOfItemsTemp);
    setDeliveryTotal(deliverytotalTemp);
    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);
  },[bookingData, setValue]);

  React.useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/delivery-product-dd-list').then(response => {
      if(response.data.status === 'SUCCESS'){
        let productListTemp = response.data.data;
        setDeliveryAllProducts(productListTemp);
        let productCatList = response.data.data.filter(i=> i.parent_id === 0);
        productCatList = productCatList.map(i=>{
          return {value: i.id, label: i.title, amount:i.amount, no_of_child: i.no_of_child };
        });
        productCatList.push({id:0, value:999, label:'Other'})
        setDeliveryDDProductList(productCatList);
      }
    })
  },[]);

  React.useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/product-dd-list').then(response => {
      if(response.data.status === 'SUCCESS'){
        let categoryListTemp = response.data.data;
        setAllProducts(categoryListTemp);
        let productCatList = response.data.data.filter(i=> i.parent_id === 0);
        productCatList = productCatList.map(i=>{
          return {value: i.id, label: i.title, amount:i.amount, no_of_child: i.no_of_child };
        });
        productCatList.push({id:0, value:999, label:'Other'});
        setProductDDList(productCatList);
      }
    })
  },[]);

  const onSubmit = (data) =>{
    data = {...data,pickup_date: moment(data?.pickup_date).format('YYYY-MM-DD')};
    saveStep1Data(data);
  }

  const selectCategory = (e,index) =>{
    setValue(`products[${index}].est_amount`, '');
    setValue(`products[${index}].total`, '');
    if(e?.value === 999){
      setValue(`products[${index}].item`, '');
    }else{
      if(e.no_of_child > 0){
        setValue(`products[${index}].item`, null);
      }else{
        setValue(`products[${index}].item`, e);
        setValue(`products[${index}].quantity`, 1);
        setValue(`products[${index}].est_amount`, e?.amount);
        setValue(`products[${index}].total`, e?.amount);
      }
    }

    let deliverytotalTemp = 0;
    let subtotalTemp = 0;
    let noOfItemsTemp = 0;
    getValues(`delivery_products`).forEach(item=>{
      if(item.total !== ''){
        deliverytotalTemp += parseFloat(item.total);
      }
    });
    getValues(`products`).forEach(item=>{
      if(item.total !== ''){
        subtotalTemp += parseFloat(item.total);
        noOfItemsTemp += 1;
      }
    });
    setDeliveryTotal(deliverytotalTemp);
    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);
    setNoOfItems(noOfItemsTemp);

    let categoryListTemp = getValues(`products`);
    let ProductListTemp = categoryListTemp.map((item)=>{
      if(item?.category_id?.no_of_child > 0){
        let productTemp = allProducts.filter(i=> i.parent_id === item?.category_id?.value);
        productTemp =  productTemp.map(i=> { 
          return {value: i.id, label: i.title, amount:i.amount}; 
        });
        return productTemp;
      }
      return [];
    });

    let SelectedCatTemp = categoryListTemp.map((item)=>{
      return item?.category_id?.value;
    });

    setSelectedCat(SelectedCatTemp);
    setProductList(ProductListTemp);
  }

  const changeItemDet = (e)=>{
    let deliverytotalTemp = 0;
    let subtotalTemp = 0;
    let noOfItemsTemp = 0;
    getValues(`delivery_products`).forEach(item=>{
      if(item.total !== ''){
        deliverytotalTemp += parseFloat(item.total);
      }
    });
    getValues(`products`).forEach(item=>{
      if(item.total !== ''){
        subtotalTemp += parseFloat(item.total);
        noOfItemsTemp += 1;
      }
    });
    setDeliveryTotal(deliverytotalTemp);
    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);
    setNoOfItems(noOfItemsTemp);
  }
  
  const selectItem = (e,index)=>{
    setValue(`products[${index}].est_amount`, e.amount);
    setValue(`products[${index}].total`, e.amount);

    let deliverytotalTemp = 0;
    let subtotalTemp = 0;
    let noOfItemsTemp = 0;
    getValues(`delivery_products`).forEach(item=>{
      if(item.total !== ''){
        deliverytotalTemp += parseFloat(item.total);
      }
    });
    getValues(`products`).forEach(item=>{
      if(item.total !== ''){
        subtotalTemp += parseFloat(item.total);
        noOfItemsTemp += 1;
      }
    });
    setDeliveryTotal(deliverytotalTemp);
    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);
    setNoOfItems(noOfItemsTemp);
  }

  const selectDCategory = (e,index) =>{
    setValue(`delivery_products[${index}].quantity`, '');
    setValue(`delivery_products[${index}].total`, '');
    if(e?.value === 999){
      setValue(`delivery_products[${index}].item`, '');
    }else{
      if(e.no_of_child > 0){
        setValue(`delivery_products[${index}].item`, null);
      }else{
        setValue(`delivery_products[${index}].item`, e);
        setValue(`delivery_products[${index}].quantity`, 1);
        setValue(`delivery_products[${index}].total`, e?.amount);
      }
    }

    let deliverytotalTemp = 0;
    let subtotalTemp = 0;
    let noOfItemsTemp = 0;
    getValues(`delivery_products`).forEach(item=>{
      if(item.total !== ''){
        deliverytotalTemp += parseFloat(item.total);
      }
    });
    getValues(`products`).forEach(item=>{
      if(item.total !== ''){
        subtotalTemp += parseFloat(item.total);
        noOfItemsTemp += 1;
      }
    });
    setDeliveryTotal(deliverytotalTemp);
    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);
    setNoOfItems(noOfItemsTemp);


    let categoryListTemp = getValues(`delivery_products`);
    let DProductListTemp = categoryListTemp.map((item)=>{
      if(item?.category_id?.no_of_child > 0){
        let deliveryProductTemp = deliveryAllProducts.filter(i=> i.parent_id === item?.category_id?.value);
        deliveryProductTemp =  deliveryProductTemp.map(i=> { 
          return {value: i.id, label: i.title, amount:i.amount}; 
        });
        return deliveryProductTemp;
      }
      return [];
    });

    let DSelectedCatTemp = categoryListTemp.map((item)=>{
      return item?.category_id?.value;
    });

    setDSelectedCat(DSelectedCatTemp);
    setDProductList(DProductListTemp);
  }

  const selectDItem = (e,index)=>{
    setValue(`delivery_products[${index}].quantity`, 1);
    setValue(`delivery_products[${index}].total`, e.amount);

    let deliverytotalTemp = 0;
    let subtotalTemp = 0;
    let noOfItemsTemp = 0;
    getValues(`delivery_products`).forEach(item=>{
      if(item.total !== ''){
        deliverytotalTemp += parseFloat(item.total);
      }
    });
    getValues(`products`).forEach(item=>{
      if(item.total !== ''){
        subtotalTemp += parseFloat(item.total);
        noOfItemsTemp += 1;
      }
    });
    setDeliveryTotal(deliverytotalTemp);
    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);
    setNoOfItems(noOfItemsTemp);
  }

  const changeDQuantity = (e,index) => {
    if(parseInt(e.target.value) > 0 && typeof(getValues(`delivery_products[${index}].item_id`)) === 'object'){
      let quantity = parseInt(e.target.value);
      let amount = getValues(`delivery_products[${index}].item_id`)?.amount;

      let subtotalTemp = SubTotal;
      let deliverytotalTemp = DeliveryTotal;
      deliverytotalTemp -= getValues(`delivery_products`)[index]?.total;
      deliverytotalTemp += quantity*amount;
      setDeliveryTotal(deliverytotalTemp);
      setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);

      let noOfItemsTemp = 0;
      let delivery_products = getValues(`delivery_products`);
      let products = getValues(`products`);
      delivery_products.forEach(item=>{
        if(item.item_id !== ''){
          noOfItemsTemp += 1;
        }
      })
      products.forEach(item=>{
        if(item.item_id !== ''){
          noOfItemsTemp += 1;
        }
      })
      setNoOfItems(noOfItemsTemp);

      setValue(`delivery_products[${index}].total`, quantity*amount);

    }
  }
  const delDItems = (index)=>{
    if(fields1.length <=1) 
      return false;

    let deliverytotalTemp = DeliveryTotal;
    let subtotalTemp = SubTotal;
    deliverytotalTemp -= getValues(`delivery_products`)[index]?.total;
    setDeliveryTotal(deliverytotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);

    remove1(index);

    let categoryList = getValues(`delivery_products`);
    let DProductListTemp = categoryList.map((item)=>{
      if(item?.category_id?.products){
        return item?.category_id?.products;
      }
      return [];
    });
    let DSelectedCatTemp = categoryList.map((item)=>{
      return item?.category_id?.value;
    });
    setDSelectedCat(DSelectedCatTemp);
    setDProductList(DProductListTemp);
  }

  const delItems = (index)=>{
    if(fields2.length <=1) 
      return false;

    let subtotalTemp = SubTotal;
    let deliverytotalTemp = DeliveryTotal;
    subtotalTemp -= getValues(`products`)[index]?.total;
    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);

    remove2(index);

    let categoryListTemp = getValues(`products`);
    let ProductListTemp = categoryListTemp.map((item)=>{
      if(item?.category_id?.products){
        return item?.category_id?.products;
      }
      return [];
    });
    let SelectedCatTemp = categoryListTemp.map((item)=>{
      return item?.category_id?.value;
    });
    setSelectedCat(SelectedCatTemp);
    setProductList(ProductListTemp);
  }

  return (
    <><div className={`${styles.StepOneForm}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${styles.StepOneFormRow}`}>
          <div className={`${styles.StepOneFormFull}`}>
            <div className={`${styles.FormFieldHalf}`}>
              <label>Do you have a registered company in UK?</label>
              <Controller
                control={control}
                options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}
                {...register('registered_company',{required:'Please select one'})}
                render={({ field }) => (
                  <Select {...field} onChange={(e)=>{field.onChange(e); setIsRegCompany(e);}} options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}  className={`${styles.CustomSelect}`} styles={customStyles}  />
                )}
              />
              {errors.registered_company && <p className={`${styles.ErrorM}`}>{errors.registered_company.message}</p>}
            </div>
            {(isRegCompany?.value === 'Yes') && <><div className={`${styles.FormFieldHalf}`}>
              <label>If Yes, please mention the name of the company</label>
              <input type="text" placeholder="" className={`${styles.TextField}`} {...register('company_name',{required:true})} />
              {errors.company_name && <p className={`${styles.ErrorM}`}>Please enter company name</p>}
            </div>
            <div className={`${styles.FormFieldHalf}`}>
              <label>If Yes, are you shipping the goods in the name of the company?</label>
              <Controller 
                control={control}
                options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}
                {...register('company_shipping',{required:true})}
                render={({ field }) => (
                  <Select {...field} options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}  className={`${styles.CustomSelect}`} styles={customStyles}  />
                )}
              />
              {errors.company_shipping && <p className={`${styles.ErrorM}`}>Please select one</p>}
            </div></>}
            <div className={`${styles.FormFieldHalf}`}>
              <label>Have you got insurance?</label>
              <Controller
                control={control}
                options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"},{value: "Not Interested", label: "I am not interested"}]}
                {...register('insurance',{required:'Please select one'})}
                render={({ field }) => (
                  <Select {...field} options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"},{value: "Not Interested", label: "I am not interested"}]}  className={`${styles.CustomSelect}`} styles={customStyles} />
                )}
              />
              {errors.insurance && <p className={`${styles.ErrorM}`}>{errors.insurance.message}</p>}
            </div>
          </div>
          <div className={`${styles.StepOneFormBig}`}>
            <div className={`${styles.CardWiseSec}`}>
              <FormControlLabel control={<Checkbox checked={isDeliveryProducts} {...register('is_delivery')} onChange={(e)=>setIsDeliveryProducts(e.target.checked)} />} label="Book a Delivery" />

              {isDeliveryProducts && <>
                {fields1.map((item, index) => {
                return (<div className={`${styles.ItemsCard}`}  key={item.id}>
                    <div className={`${styles.FormFieldHalf}`}>
                      <label>Category</label>
                      <Controller
                          key={item.id}
                          control={control}
                          {...register(`delivery_products[${index}].category_id`,{required:true})}
                          render={({ field }) => (
                            <Select {...field} onChange={(e)=>{field.onChange(e); selectDCategory(e,index)}} styles={customStyles} options={deliveryProductDDList} className={`${styles.CustomSelect}`}  />
                          )}
                        />
                        {(errors?.delivery_products?.length > 0 && errors?.delivery_products[index]?.category_id) && <p className={`${styles.ErrorM}`}>Please select category</p>}
                    </div>
                    <div className={`${styles.FormFieldHalf}`}>
                      {(DSelectedCat[index] && DSelectedCat[index] === 999) ? <>
                      <label>Item</label>
                      <input type="text" className={`${styles.TextField}`} {...register(`delivery_products[${index}].item_id`,{required: (DSelectedCat[index] && DSelectedCat[index] === 999)})} onChange={(e)=>changeItemDet(e)} />
                      {(errors?.delivery_products?.length > 0 && errors?.delivery_products[index]?.item_id) && <p className={`${styles.ErrorM}`}>Please enter item name</p>}
                    </>:((DProductList[index] && DProductList[index].length > 0) && <>
                      <label>Item</label>
                      <Controller
                            key={item.id}
                            control={control}
                            options={(DProductList[index] && DProductList[index].length > 0)?DProductList[index] : []}
                            {...register(`delivery_products[${index}].item_id`,{required: !(DSelectedCat[index] && DSelectedCat[index] === 999)})}
                            render={({ field }) => (
                              <Select {...field} onChange={(e)=>{field.onChange(e); selectDItem(e,index)}} styles={customStyles} options={(DProductList[index] && DProductList[index].length > 0)?DProductList[index] : []}  className={`${styles.CustomSelect}`}  />
                            )}
                          />
                      {(errors?.delivery_products?.length > 0 && errors?.delivery_products[index]?.item_id) && <p className={`${styles.ErrorM}`}>Please select item</p>}
                    </>)}
                    </div>
                    <div className={`${styles.FormFieldHalf}`}>
                      <label>Quantity</label>
                      <Controller
                        key={item.id}
                          control={control}
                          {...register(`delivery_products[${index}].quantity`,{required:true})}
                          render={({ field }) => (
                            <NumericFormat {...field } onChange={(e)=>{field.onChange(e); changeDQuantity(e,index); changeItemDet(e);}} className={`${styles.TextField}`} allowLeadingZeros={false} allowNegative={false} decimalScale={0} />
                          )}
                        />
                        {(errors?.delivery_products?.length > 0 && errors?.delivery_products[index]?.quantity) && <p className={`${styles.ErrorM}`}>Please enter quantity</p>}
                    </div>
                    <div className={`${styles.FormFieldHalf}`}>
                      <label>Total</label>
                      <Controller
                        key={item.id}
                          control={control}
                          {...register(`delivery_products[${index}].total`,{required:(DSelectedCat[index] && DSelectedCat[index] === 999)})}
                          render={({ field }) => (
                            <NumericFormat {...field } className={`${styles.TextField}`} allowLeadingZeros={false} allowNegative={false} decimalScale={2} readOnly={!(DSelectedCat[index] && DSelectedCat[index] === 999)}  onChange={(e)=>{field.onChange(e); changeItemDet(e);}} />
                          )}
                        />
                    </div>
                    <button className={`${styles.ItemDeleteBU}`} type="button" onClick={delDItems.bind(this,index)}><Trash2/> Delete</button>
                  </div>);
              })}
              <button className={`${styles.ItemAddBU}`} type="button"  onClick={() => { append1({ category_id: '', item_id: '', quantity: '', total:'' }); }}>Add another item</button>
              </>}
            </div>
            
            {fields2.map((item, index) => {
              return (<div className={`${styles.ItemsCard}`} key={item.id}>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Category</label>
                  <Controller
                    key={item.id}
                    control={control}
                    options={productDDList}
                    {...register(`products[${index}].category_id`,{required:true})}
                    render={({ field }) => (
                      <Select {...field} onChange={(e)=>{field.onChange(e); selectCategory(e,index)}} styles={customStyles} options={productDDList} className={`${styles.CustomSelect}`}  />
                    )}
                  />
                  {(errors?.products?.length > 0 && errors?.products[index]?.category_id) && <p className={`${styles.ErrorM}`}>Please select category</p>}
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  {(SelectedCat[index] && SelectedCat[index] === 999) ? <>
                    <label>Item</label>
                    <input type="text" key={item.id} className={`${styles.TextField}`} {...register(`products[${index}].item`,{required: (SelectedCat[index] && SelectedCat[index] === 999)})} onChange={(e)=>changeItemDet(e)} />
                    {(errors?.products?.length > 0 && errors?.products[index]?.item) && <p className={`${styles.ErrorM}`}>Please enter item name</p>}
                  </>:((ProductList[index] && ProductList[index].length > 0) &&  <>
                    <label>Item</label>
                    <Controller
                      key={item.id}
                      control={control}
                      options={(ProductList[index] && ProductList[index].length > 0)?ProductList[index] : []}
                      {...register(`products[${index}].item`,{required: (!(SelectedCat[index] && SelectedCat[index] === 999))})}
                      render={({ field }) => (
                          <Select {...field} onChange={(e)=>{field.onChange(e); selectItem(e,index)}} styles={customStyles} options={(ProductList[index] && ProductList[index].length > 0)?ProductList[index] : []}  className={`${styles.CustomSelect}`}  />
                      )}
                    />
                    {(errors?.products?.length > 0 && errors?.products[index]?.item) && <p className={`${styles.ErrorM}`}>Please select item</p>}
                  </>)}
                </div>
                <div className={`${styles.FormFieldOneThird}`}>
                  <label>Estimated Value</label>
                  <Controller
                    key={item.id}
                    control={control}
                    {...register(`products[${index}].est_amount`,{required: (SelectedCat[index] && SelectedCat[index] === 999)})}
                    render={({ field }) => (
                      <NumericFormat {...field } className={`${styles.TextField}`} allowLeadingZeros={false} allowNegative={false} decimalScale={2} readOnly={!(SelectedCat[index] && SelectedCat[index] === 999)} onChange={(e)=>{field.onChange(e); changeItemDet(e);}} />
                    )}
                  />
                  {(errors?.products?.length > 0 && errors?.products[index]?.est_amount) && <p className={`${styles.ErrorM}`}>Please enter estimated amount</p>}
                </div>
                <div className={`${styles.FormFieldOneThird}`}>
                  <label>Total</label>
                  <Controller
                    key={item.id}
                    control={control}
                    {...register(`products[${index}].total`,{required:(SelectedCat[index] && SelectedCat[index] === 999)})}
                    render={({ field }) => (
                      <NumericFormat {...field } className={`${styles.TextField}`} allowLeadingZeros={false} allowNegative={false} decimalScale={2} readOnly={!(SelectedCat[index] && SelectedCat[index] === 999)} onChange={(e)=>{field.onChange(e); changeItemDet(e);}} />
                    )}
                  />
                  {(errors?.products?.length > 0 && errors?.products[index]?.total) && <p className={`${styles.ErrorM}`}>Please enter total amount</p>}
                </div>
                <div className={`${styles.FormFieldOneThird}`}>
                  <label>Type</label>
                  <FormControlLabel className={`${styles.CheckBox} CheckBox`} control={<Controller
                      key={item.id}
                      control={control}
                      {...register(`products[${index}].is_fragile`)}
                      render={({ field }) => (
                          <Checkbox {...field} defaultChecked={field.value} />
                      )}
                    />} label="Fragile?" />
                </div>
                <button className={`${styles.ItemDeleteBU}`} type="button" onClick={delItems.bind(this,index)}><Trash2/> Delete</button>
              </div>);
            })}
            <button className={`${styles.ItemAddBU}`}  type='button' onClick={() => { append2({ category_id: '', item_id: '', est_amount: '', total:'', is_fragile: false  }); }}>Add another item</button>
            <div className={`${styles.FormFieldFull} ${styles.AddPadd}`}>
              <label>Pickup Date<span>*</span></label>
              <Controller
                control={control}
                {...register('pickup_date',{required:'Please select pick up date'})}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    autoComplete='off'
                    minDate={moment().toDate()}
                    selected={field.value}
                    className={`${styles.TextField}`}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => field.onChange(date)}
                    ref={(ref) => {
                      field.ref({
                        focus: ref?.setFocus
                      });
                    }}
                  />
                )}
              />
              {(errors.pickup_date) && <p className={`${styles.ErrorM}`}>{errors.pickup_date.message}</p>}
            </div>
            <div className={`${styles.FormFieldFull}`}>
              <label>Booking Notes</label>
              <textarea className={`${styles.TextField}`} {...register('notes')}></textarea>
            </div>
            <div className={`${styles.FormFieldFull}`}>
              <FormControlLabel className={`${styles.CheckBox} CheckBox`} control={<Checkbox {...register('is_accept',{required:true})} />} label="I have read and agree to the Prohibited & Restricted items list*" />
              {(errors?.is_accept) && <p className={`${styles.ErrorM}`}>Please accept terms & condition</p>}
            </div>
          </div>
          
          <div className={`${styles.StepOneFormSmall}`}>
            <ul className={`${styles.CostUL}`}>
              <li>
                <p className={`${styles.CostTitle}`}>No of Items:</p>
                <p className={`${styles.CostNo}`}>{NoOfItems}</p>
              </li>
              <li>
                <p className={`${styles.CostTitle}`}>Order Subtotal:</p>
                <p className={`${styles.CostNo}`}>£{SubTotal}</p>
              </li>
              <li>
                <p className={`${styles.CostTitle}`}>Total VAT:</p>
                <p className={`${styles.CostNo}`}>£{VATCharge}</p>
              </li> 
              <li>
                <p className={`${styles.CostTitle}`}>Insurance Charges:</p>
                <p className={`${styles.CostNo}`}>£{InsuranceCharge}</p>
              </li>
              {isDeliveryProducts && <li>
                <p className={`${styles.CostTitle}`}>Delivery Total:</p>
                <p className={`${styles.CostNo}`}>£{DeliveryTotal}</p>
            </li>}
            <li>
                <p className={`${styles.CostTitle} ${styles.Big}`}>Total:</p>
                <p className={`${styles.CostNo} ${styles.Big}`}>£{Total}</p>
              </li>
            </ul>
          </div>
          
          <div className={`${styles.StepBUSec}`}>
            <button className={`${styles.Next}`} type='submit'>Next</button>
          </div>
        </div>
      </form>
    </div>
    
    
    </>
  );
}

export default Step1;