import * as React from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab, Typography, Box} from '@mui/material';
import styles from './add.module.css';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import withAdminAuth from "../../../admin/components/withAdminAuth";
import { ChevronLeft } from 'react-feather';
import { Link } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function AddBooking({ history, match }) {
  const [tabValue, setTabValue] = React.useState(0);
  const [bookingData, setBookingData] = React.useState({});
  
  const saveStep1Data = (data)=>{
    let bookingDataTemp = bookingData;
    bookingDataTemp = {...bookingDataTemp, ...data};

    setBookingData(bookingDataTemp);
    setTabValue(prevValue => prevValue+1);
  }


  const saveStep2Data = (pickUpData)=>{
    let bookingDataTemp = bookingData;
    bookingDataTemp = {...bookingDataTemp, pickUpData:pickUpData};

    setBookingData(bookingDataTemp);
    setTabValue(prevValue => prevValue+1);
  }

  const saveStep3Data = (dropOffData) => {
    let bookingDataTemp = bookingData;
    bookingDataTemp = {...bookingDataTemp, dropOffData:dropOffData};

    setBookingData(bookingDataTemp);
    setTabValue(prevValue => prevValue+1);
  }

  const backToTab = ()=>{
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    setTabValue(prevValue => prevValue-1);
  }

  return (
      <>
      <div className={`${styles.MainDivCustomer}`}>
        <img src="/images/booking-bg.jpg" alt="" className={`${styles.BackGroundGra}`} />
        <div className={`${styles.Container}`}>
          <Link to={'/customer/my-bookings'} className={`${styles.BackLink}`}><ChevronLeft/> Back to all booking</Link>
          <div className={`${styles.MainCard}`}>
            <Box sx={{ width: '100%' }} className="AddBookingTab">
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} aria-label="basic tabs example">
                  <Tab label="Parcel Details" {...a11yProps(0)} />
                  <Tab label="Pickup Address" {...a11yProps(1)} />
                  <Tab label="Recipient Address" {...a11yProps(2)} />
                  <Tab label="Order Summary" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <Step1 saveStep1Data={saveStep1Data.bind(this)} bookingData={bookingData} />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Step2  saveStep2Data={saveStep2Data.bind(this)} backToTab={backToTab.bind(this)} bookingData={bookingData} />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Step3  saveStep3Data={saveStep3Data.bind(this)} backToTab={backToTab.bind(this)} bookingData={bookingData} />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <Step4  bookingData={bookingData} backToTab={backToTab.bind(this)} />
              </TabPanel>
            </Box>
          </div>
        </div>
      </div>
      </>
    );
}

export default withAdminAuth(AddBooking);