import React from 'react';
import styles from './list.module.css';
import { TextField, Button } from '@mui/material';
import DataTable from 'react-data-table-component';
import {  X } from 'react-feather';
import axios from 'axios';
import { TableLoader } from  '../../components/LoaderC';
import {authData} from "../../components/getAuth";
import withRouter from "../../components/withRouter";
import withAdminAuth from "../../components/withAdminAuth";

class BookingEnqueryList extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.state = { allData: [], data: [],filterText:'', contentLoading:true  };

    this.authData = authData();
    this.bookingId = this.props?.params?.bookingId;

    this.columns = [
      {
        name: 'Booking Id',
        selector: row => this.bookingId,
        sortable: true,
        width: '9%'
      },
      {
        name: 'Item Id',
        selector: row => row.item_id,
        sortable: true,
        width: '9%'
      },
      {
        name: 'Items',
        selector: row => row.product_title_new,
        sortable: true,
        width: '9%'
      },
      {
        name: 'Paid',
        selector: row => '0.00',
        sortable: true,
        width: '9%'
      },
      {
        name: 'Status',
        selector: row => row?.booking_status?.status_text,
        sortable: true,
        width: '9%'
      },
      {
        name: 'UK Driver',
        selector: row => row?.driver ? row?.driver?.name:'',
        sortable: true,
        width: '9%'
      },
      {
        name: 'GHANA Driver',
        selector: row => '-',
        sortable: true,
        width: '9%'
      },
      {
        name: 'Recepient Address',
        selector: row => row?.recepient_data?.address,
        sortable: true,
        width: '10%'
      },
      {
        name: 'Recepient Name',
        selector: row => row?.recepient_data?.name,
        sortable: true,
        width: '9%'
      },
      {
        name: 'Recepient Mobile',
        selector: row => row?.recepient_data?.phone,
        sortable: true,
        width: '9%'
      },
      {
        name: 'Container',
        selector: row => '-',
        sortable: true,
        width: '9%'
      }
    
    ];

    this.customDataTableStyles = {
      head: {
        style: {
          zIndex:0
        },
      },
      headCells: {
        style: {
            backgroundColor: '#1e1e2d',
            color: '#fff'
        },
      },
    } 
  }
  
  
  async componentDidMount() {
    try {
      axios(process.env.REACT_APP_API_URL + 'v1/bookings-enquery/'+this.bookingId, {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        }
      }).then(response => {
        if(response.data.status === 'SUCCESS'){
          let allData = response.data.data;
          this.setState({ ...this.state, allData: allData, data: allData,contentLoading:false});
        }else{
          this.setState({ ...this.state, contentLoading:false});
        }
      }).catch(error=>{
        this.setState({ ...this.state, contentLoading:false});
      });

    } catch (error) {
      this.setState({ ...this.state, contentLoading:false});
    }
  }
  onFilter(e){
    this.setState({ ...this.state, filterText: e.target.value },this.filterHandler);
  }
  clearFilter(){
    this.setState({ ...this.state, filterText: '' },this.filterHandler);
  }

  filterHandler() {
    let filteredData = this.state.allData;

    if (this.state.filterText !== '') {
      let inputVal = this.state.filterText;
      inputVal = inputVal.toLowerCase();
      filteredData = filteredData.filter((item) => {
        return item.reference_id.toLowerCase().includes(inputVal)|| item.key_value.toLowerCase().includes(inputVal) || item.customer_name.toLowerCase().includes(inputVal) ;
      });
    }

    this.setState({ ...this.state, data: filteredData });
  }

  render() {
    return (
      <>
      {this.state.contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>}
      {!this.state.contentLoading && <div className={`${styles.MainDiv}`}>
        <div className={`${styles.MainCard}`}>
          <div className='TableFilterHead'>
            <div className="DataTableSearch">
              <TextField id="search" type="text" placeholder="Search" aria-label="Search Input" value={this.state.filterText} onChange={this.onFilter.bind(this)} />
              {this.state.filterText && <Button type="button"  className="CloseBU" onClick={this.clearFilter.bind(this)}><X/></Button>}
            </div>
          </div>
          
          <div className='TableContent'>
            <DataTable className='DataTable'
              columns={this.columns}
              data={this.state.data}
              pagination
              customStyles={this.customDataTableStyles}
            />
          </div>
        </div>
      </div>}
      </>
    );
  }
}

export default withAdminAuth(withRouter(BookingEnqueryList));