import * as React from 'react';
import styles from './add.module.css';
import axios from 'axios';
import { useForm, Controller,useFieldArray } from "react-hook-form";
import { Plus, Trash2 } from 'react-feather';
import {isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import Select,{ components } from "react-select";
import {authData} from "../../../admin/components/getAuth";
import Autocomplete from "react-google-autocomplete";
import MapComponent from '../../../frontend/components/Map';

function Step3({ saveStep3Data,backToTab,bookingData }) {
  const [defaultAddr, setDefaultAddr] = React.useState({});
  const [userAddressList, setUserAddressList] = React.useState([]);
  const [mapCenter, setMapCenter] = React.useState({ lat: 5.614818, lng: -0.205874 });
  const [isMarker, setIsMarker] = React.useState(false);
  const [zoom, setZoom] = React.useState(12);
  const { register, handleSubmit,control, formState: { errors },setValue } = useForm({
    mode: 'onChange'
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "alt_contact_nos"
  });

  React.useEffect(()=>{
    setValue(`name`,bookingData?.dropOffData?.name);
    setValue(`email`,bookingData?.dropOffData?.email);
    setValue(`phone`,bookingData?.dropOffData?.phone);
    setValue(`address`,bookingData?.dropOffData?.address);
    setValue(`latitude`,parseFloat(bookingData?.dropOffData?.latitude));
    setValue(`longitude`,parseFloat(bookingData?.dropOffData?.longitude));
    setDefaultAddr(bookingData?.dropOffData?.addressObj);

    if(bookingData?.dropOffData?.alt_contact_nos && bookingData?.dropOffData?.alt_contact_nos?.length){
      replace(bookingData?.dropOffData?.alt_contact_nos);
    }

    if(bookingData?.dropOffData?.latitude && bookingData?.dropOffData?.longitude){
      setMapCenter({ lat: parseFloat(bookingData?.dropOffData?.latitude), lng: parseFloat(bookingData?.dropOffData?.longitude) });
      setIsMarker(true);
      setZoom(18);
    }

  },[bookingData,setValue,replace]);

  React.useState(()=>{
    if(authData()?.customer?.id){
      axios.get(process.env.REACT_APP_API_URL + 'v1/adresses/'+authData()?.customer?.id+'/dropoff').then(response => {
        if(response.data.status === 'SUCCESS'){
          let addrListTemp = response.data.data;
          addrListTemp = [{value: -1, label:'Select or add record'}].concat(addrListTemp);
          setUserAddressList(addrListTemp);
        }
      });
    }
  },[]);

  const onSubmit = (data) =>{
    data = {...data, addressObj:defaultAddr };
    saveStep3Data(data);
  }

  const Option = props => {
    return (
      <components.Option {...props}>
        <div>{props.data.label}</div>
        <div style={{ fontSize: 12 }}>{props.data.address}</div>
      </components.Option>
    );
  };

  const chnageAdrsOption = (e)=>{
    setDefaultAddr(e);

    if(e?.value === -1){
      setValue(`name`, '');
      setValue(`email`, '');
      setValue(`phone`, '');
      setValue(`address`, '');
      setIsMarker(false);
      setZoom(18);

      fields.forEach((item,index)=>{
        remove(index);
      });
    }else{
      setValue(`address`,e?.address);
      setValue(`name`,e?.label);
      setValue(`email`,e?.email);
      setValue(`phone`,e?.phone);
      setValue(`latitude`,parseFloat(e?.latitude));
      setValue(`longitude`,parseFloat(e?.longitude));
      
      if(e?.alt_phones && e?.alt_phones?.length){
        replace(e?.alt_phones);
      }
      if(e?.latitude && e?.longitude){
        setMapCenter({ lat: parseFloat(e?.latitude), lng: parseFloat(e?.longitude) });
        setIsMarker(true);
        setZoom(18);
      }
    }
  }

  const removePhn = (e,index)=>{
    remove(index);
  }
  
  return (
    <><div className={`${styles.StepOneForm}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${styles.StepOneFormRow}`}>
          <div className={`${styles.StepOneFormBig}`}>
            <div className={`${styles.FormFieldFull}`}>
              <label>Select or add record</label>
              <Select 
                onChange={(e)=> chnageAdrsOption(e)}
                options={userAddressList}
                components={{ Option }}
                className={`${styles.CustomSelect}`}
                value={defaultAddr}
              />
            </div>
            <div className={`${styles.FormFieldFull}`}>
              <label>Name<span>*</span></label>
              <input type="text" placeholder="" className={`${styles.TextField}`} {...register('name',{required:'Please enter name'})} />
              {errors.name && <p className={`${styles.ErrorM}`}>{errors.name.message}</p>}
            </div>
            <div className={`${styles.FormFieldFull}`}>
              <label>Recipient Email Address</label>
              <input type="text" className={`${styles.TextField}`} {...register('email')} />
              {errors.email && <p className={`${styles.ErrorM}`}>{errors.email.message}</p>}
            </div>
            <div className={`${styles.FormFieldFull}`}>
              <label>Contact No<span>*</span></label>
              <Controller
                control={control}
                {...register('phone',{required:'Please enter phone no',validate:isValidPhoneNumber})}
                className={`${styles.TextField}`}
                render={({ field }) => (
                    <Input 
                      {...field}
                      country="GH"
                      international
                      withCountryCallingCode
                      className={`${styles.TextField}`}
                    />
                  )}
                />
              {(errors.phone && errors.phone.type !== "validate") && <p className={`${styles.ErrorM}`}>{errors.phone.message}</p>}
              {(errors.phone && errors.phone.type === "validate") && <p className={`${styles.ErrorM}`}>Please enter valid phone</p>}
            </div>

            {fields.map((item, index) => {
              return (<div className={`${styles.FormFieldFull}`} key={item.id}>
                    <label>Alt Contact No</label>
                    <div className={`${styles.AltConSec}`}>
                    <Controller
                      key={item.id}
                      control={control}
                      {...register(`alt_contact_nos[${index}].value`,{required:'Please enter phone no',validate:isValidPhoneNumber})}
                      className={`${styles.TextField}`}
                      render={({ field }) => (
                          <Input 
                            {...field}
                            country="GH"
                            international
                            withCountryCallingCode
                            className={`${styles.TextField}`}
                          />
                        )}
                      />
                      <button type='button' onClick={(e)=>removePhn(this,index)} className={`${styles.ConNoBU}`}><Trash2/></button>
                    </div>
                  {(errors?.alt_contact_nos?.length > 0 && errors?.alt_contact_nos[index]?.value && errors?.alt_contact_nos[index]?.value?.type !== "validate") && <p className={`${styles.ErrorM}`}>{errors?.alt_contact_nos[index]?.value?.message}</p>}
                  {(errors?.alt_contact_nos?.length > 0 && errors?.alt_contact_nos[index]?.value && errors?.alt_contact_nos[index]?.value?.type === "validate") && <p className={`${styles.ErrorM}`}>Please enter valid phone</p>}
                </div>)
            })}
            <button type='button' onClick={() => { append({ value: '' }); }} className={`${styles.AddAltConNO}`}><Plus/> Alt contact no</button>

            <div className={`${styles.FormFieldFull} ${styles.AddAnoPadd}`}>
              <label>Address<span>*</span></label>
              <Controller
                control={control}
                {...register(`address`,{required:'Please enter address'})}
                render={( { field } ) => (
                  <Autocomplete
                    {...field}
                    className={`${styles.TextField} ${styles.TextFieldAutoComplete}`}
                    apiKey={process.env.REACT_APP_MAP_API_KEY}
                    onPlaceSelected={(place) => {
                      setValue('address',place.formatted_address);
                      setValue('latitude',place.geometry.location.lat());
                      setValue('longitude',place.geometry.location.lng());

                     setMapCenter({ lat: parseFloat(place.geometry.location.lat()), lng: parseFloat(place.geometry.location.lng()) });
                     setIsMarker(true);
                     setZoom(18);
                    }}
                    options={{
                      types: ["address"],
                      componentRestrictions: { country: "gh" },
                    }}
                  />
                )}
              />
            {(errors?.address) && <p className={`${styles.ErrorM}`}>{errors?.address?.message}</p>}
            </div>
          </div>
          
          <div className={`${styles.StepOneFormSmall2}`}>
            <div className={`${styles.FormFieldFull}`}>
              <label>Add Recipient location<span>*</span></label>
              <MapComponent center={mapCenter} zoom={zoom} isMarker={isMarker} />
            </div>
            <div className={`${styles.FormFieldFull}`}>
              <div className={`${styles.FormInsideRow}`}>
                <input type="text" placeholder="Please enter post code" className={`${styles.TextField}`} />
                <button className={`${styles.FindLocationBU}`}>Find Location</button>
              </div>
            </div>
          </div>
          
          <div className={`${styles.StepBUSec}`}>
            <button type='button' onClick={backToTab} className={`${styles.Previous}`}>Back</button>
            <button  type='submit' className={`${styles.Next}`}>Next</button>
          </div>
          
        </div>
      </form>
    </div></>
  );
}

export default Step3;