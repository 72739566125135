import React, {useState,useEffect} from 'react';
import styles from './add.module.css';
import { Button  } from '@mui/material';
import { Link } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { FromLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import Select from "react-select";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UserAddForm({ onSubmit,isAddMode, id,modalClose }) {
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});

const userTypeOptions= [
  {label:'Super Admin',value:'Super Admin'},
  {label:'UK Admin',value:'UK Admin'},
  //{label:'Pickup Driver',value:'Pickup Driver'},
  {label:'GH Admin',value:'GH Admin'},
  //{label:'GH Driver',value:'GH Driver'},
  {label:'Receptionist',value:'Receptionist'}
];

let schema;
  if(isAddMode){
    schema = yup.object().shape({
      name: yup.string().required('This field is Required').max(50, 'Name cannot be more than 50 characters'),
      user_type: yup.object().required('This field is Required'),
      email: yup.string().required('This field is Required').email('Invalid email'),
      phone: yup.string().required('This field is Required'),
      password: yup.string().required('This field is Required').min(6,'Password length should be 6-15').max(15,'Password length should be 6-15'),
      conf_password: yup.string().when('password', (password, field) =>
        password ? field.oneOf([yup.ref('password')],'Passwords must match') : field
      )
    });
  }else{
    schema = yup.object().shape({
      name: yup.string().required('This field is Required').max(50, 'Name cannot be more than 50 characters'),
      user_type: yup.object().required('This field is Required'),
      email: yup.string().required('This field is Required').email('Invalid email'),
      phone: yup.string().required('This field is Required'),
      password: yup.string(),
      conf_password: yup.string().when('password', (password, field) =>
        password ? field.oneOf([yup.ref('password')],'Passwords must match') : field
      )
    });
  }

  const { register, handleSubmit,control, watch,setValue,  formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  useEffect(()=>{
    if(!isAddMode){
      if(contentLoading){
        axios.get(process.env.REACT_APP_API_URL + 'v1/users/'+id, {
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
          }
        }).then(response => {
          setContentLoading(false);
          if(response.data.status === 'SUCCESS'){
            let itemData = response.data.data;
            setValue('name',itemData.name);
            setValue('email',itemData.email);
            setValue('phone',itemData.phone);
            setValue('user_type',{ value: itemData.user_type, label: itemData.user_type });
          }
        }).catch(error => {
          setContentLoading(false);
        });
      }
    }else{
      setContentLoading(false);
    }
  },[isAddMode,id,setValue,contentLoading,API_TOKEN]);
  
  async function onSubmitLocal(data) {
    setValidationErrors({});
    delete data.conf_password;

    if(!isAddMode){
      if(data.password === ''){
        delete data.password;
      }
    }
    
    let postData = data;

    if(data.user_type ){
      postData = {...postData,user_type:data.user_type.value};
    }

    setLoading(true);

    if(isAddMode){
      return axios.post(process.env.REACT_APP_API_URL + 'v1/users', postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          onSubmit();
        }
        if(response.data.status === 'ERROR'){
          setValidationErrors(response.data.messages);
        }
      }).catch(error => {
        setLoading(false);
        if(error && error.response && error.response.data && error.response.data.messages){
          setValidationErrors(error.response.data.messages);
        }
      });
    }else{
      return axios.post(process.env.REACT_APP_API_URL + 'v1/users/'+id, postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          onSubmit();
        }
      }).catch(error => {
        setLoading(false);
      });
    }
  }
  
  return (
      <React.Fragment>
        {loading && <Loader /> }
        {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div> }
        {!contentLoading && <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmitLocal)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Full Name*</label>
                <input {...register("name")} className={`${styles.FormControl}`} />
                {errors.name && !watch().name && <span className={`${styles.ErrorM}`}>{errors.name.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>User Type*</label>
                <Controller
                  control={control}
                  options={userTypeOptions}
                  {...register('user_type')}
                  render={({ field }) => (
                    <Select {...field} options={userTypeOptions}  />
                  )}
                />
                {errors.user_type && <span className={`${styles.ErrorM}`}>{errors.user_type.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Email*</label>
                <input {...register("email")} className={`${styles.FormControl}`} autoComplete="off" readOnly={!isAddMode} />
                {errors.email && <span className={`${styles.ErrorM}`}>{errors.email.message}</span>}
                {(validationErrors?.email && validationErrors?.email[0]) && <span className={`${styles.ErrorM}`}>{validationErrors?.email[0]}</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Phone No*</label>
                <input {...register("phone")} className={`${styles.FormControl}`} />
                {errors.phone && !watch().phone && <span className={`${styles.ErrorM}`}>{errors.phone.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Password*</label>
                <input type="password" {...register("password")} className={`${styles.FormControl}`} autoComplete="off" />
                {errors.password && <span className={`${styles.ErrorM}`}>{errors.password.message}</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Confirm Password*</label>
                <input type="password" {...register("conf_password")} className={`${styles.FormControl}`} />
                {errors.conf_password && <span className={`${styles.ErrorM}`}>{errors.conf_password.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormButtonArea}`}>
              <Link onClick={modalClose} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
              <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
            </div>
          </form>
        </div>}
      </React.Fragment>
  )}


export default UserAddForm;