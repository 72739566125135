import React, {useState,useEffect} from 'react';
import styles from './add.module.css';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import { FromLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import Select from "react-select";
import {isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from "react-google-autocomplete";

function CustomerAddForm({ onSubmit,isAddMode, id,modalClose }) {
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [isRegCompany,setIsRegCompany] = React.useState({});

  const { register, handleSubmit,control, watch,setValue,  formState: { errors } } = useForm({
    mode: 'onChange'
  });

  
  useEffect(()=>{
    if(!isAddMode){
      if(contentLoading){
        axios.get(process.env.REACT_APP_API_URL + 'v1/customers/'+id, {
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
          }
        }).then(response => {
          setContentLoading(false);
          if(response.data.status === 'SUCCESS'){
            let itemData = response.data.data;
            setValue('name',itemData.name);
            setValue('email',itemData.email);
            setValue('phone',itemData.phone);
            setValue('address',itemData.address);
            setValue('post_code',itemData.post_code);
            setIsRegCompany(itemData?.registered_company);
            setValue('registered_company',{ value: itemData.registered_company, label: itemData.registered_company });
            if( itemData.registered_company === 'Yes'){
              setValue('company_shipping',{ value: itemData.company_shipping, label: itemData.company_shipping });
              setValue('company_name',itemData.company_name);
            }
          }
        }).catch(error => {
          setContentLoading(false);
        });
      }
    }else{
      setContentLoading(false);
    }
  },[API_TOKEN,contentLoading,id,isAddMode,setValue]);
  
  async function onSubmitLocal(data) {
    setValidationErrors({});

    let postData = {address:data?.address, email:data?.email, name:data?.name, phone:data?.phone, post_code:data?.post_code, registered_company: data?.registered_company?.value };

    if(data?.latitude){
      postData = {...postData, latitude:data?.latitude };
    }
    if(data?.longitude){
      postData = {...postData, longitude:data?.longitude };
    }

    if(data?.registered_company?.value === 'Yes'){
      postData = {...postData, company_name:data?.company_name,company_shipping: data?.company_shipping?.value };
    }

    setLoading(true);

    if(isAddMode){
      return axios.post(process.env.REACT_APP_API_URL + 'v1/customers', postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          modalClose();
          onSubmit();
        }
        if(response.data.status === 'ERROR'){
          setValidationErrors(response.data.messages);
        }
      }).catch(error => {
        setLoading(false);
        if(error && error.response && error.response.data && error.response.data.messages){
          setValidationErrors(error.response.data.messages);
        }
      });
    }else{
      return axios.post(process.env.REACT_APP_API_URL + 'v1/customers/'+id, postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          onSubmit();
        }
      }).catch(error => {
        setLoading(false);
      });
    }
  }
  
  return (<React.Fragment>
    {loading && <Loader /> }
    {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div> }
    {!contentLoading && <div className={`${styles.AddUserMain}`}>
      <form onSubmit={handleSubmit(onSubmitLocal)}>
        <div className={`${styles.FormRow}`}>
          <div className={`${styles.FormGroup}`}>
            <label className={`${styles.FormLabel}`}>Name*</label>
            <input {...register('name',{required:'Please enter name'})} className={`${styles.FormControl}`} autoComplete="off" />
            {errors.name && <span className={`${styles.ErrorM}`}>{errors.name.message}</span>}
          </div>
          <div className={`${styles.FormGroup}`}>
            <label className={`${styles.FormLabel}`}>Email*</label>
            <input className={`${styles.FormControl}`} autoComplete="off" readOnly={!isAddMode} {...register('email',{required:'Please enter email',pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address"
            }})} />
            {errors.email && <span className={`${styles.ErrorM}`}>{errors.email.message}</span>}
            {(validationErrors?.email && validationErrors?.email[0]) && <span className={`${styles.ErrorM}`}>{validationErrors?.email[0]}</span>}
          </div>
        </div>
        <div className={`${styles.FormRow}`}>
          <div className={`${styles.FormGroup}`}>
            <label className={`${styles.FormLabel}`}>Phone*</label>
            <Controller
              control={control}
              {...register('phone',{required:'Please enter mobile no',validate:isValidPhoneNumber})}
              className={`${styles.TextField}`}
              render={({ field }) => (<Input {...field} country="GB" international withCountryCallingCode className={`${styles.FormControl}`} />)}
            />
            {(errors.phone && errors.phone.type !== "validate") && <p className={`${styles.ErrorM}`}>{errors.phone.message}</p>}
            {(errors.phone && errors.phone.type === "validate") && <p className={`${styles.ErrorM}`}>Please enter valid phone</p>}
          </div>
          <div className={`${styles.FormGroup}`}>
            <label className={`${styles.FormLabel}`}>Address*</label>
            <Controller
              control={control}
              {...register("address",{required:'Please enter address'})}
              className={`${styles.FormControl}`}
              autoComplete="off"
              render={({ field }) => (<Autocomplete
                {...field}
                placeholder=''
                className={`${styles.FormControl}`}
                apiKey={process.env.REACT_APP_MAP_API_KEY}
                onPlaceSelected={(place) => {
                  setValue('address',place.formatted_address);
                  setValue('latitude',place.geometry.location.lat());
                  setValue('longitude',place.geometry.location.lng());
                  
                  place.address_components.forEach(item=>{
                    if(item.types.indexOf('postal_code') > -1){
                      setValue('post_code',item.long_name);
                    }
                  })
                }}
                options={{types: ["address"], componentRestrictions: { country: "uk" }, }}
              />)}
            />
            {errors.address && <span className={`${styles.ErrorM}`}>{errors.address.message}</span>}
          </div>
        </div>
        
        <div className={`${styles.FormRow}`}>
          <div className={`${styles.FormGroup}`}>
            <label className={`${styles.FormLabel}`}>Post Code*</label>
            <input {...register("post_code",{required:'Please enter post code'})} className={`${styles.FormControl}`} />
            {errors.post_code && !watch().post_code && <span className={`${styles.ErrorM}`}>{errors.post_code.message}</span>}
          </div>
          <div className={`${styles.FormGroup}`}>
            <label className={`${styles.FormLabel}`}>Have a Registered Company?*</label>
            <Controller
              control={control}
              options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}
              {...register('registered_company',{required:'Please select one'})}
              render={({ field }) => (<Select {...field} onChange={(e)=>{field.onChange(e); setIsRegCompany(e);}} options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]} />)}
            />
            {errors.registered_company && <span className={`${styles.ErrorM}`}>{errors.registered_company.message}</span>}
          </div>
        </div>
        
        <div className={`${styles.FormRow}`}>
          {(isRegCompany?.value === 'Yes') && <>
            <div className={`${styles.FormGroup}`}>
              <label className={`${styles.FormLabel}`}>Shipping by company name?*</label>
              <Controller
                control={control}
                options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}
                {...register('company_shipping',{required:'Please select one'})}
                render={({ field }) => (<Select {...field} options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}  />)}
              />
              {errors.company_shipping && !watch().company_shipping && <span className={`${styles.ErrorM}`}>{errors.company_shipping.message}</span>}
            </div>
            <div className={`${styles.FormGroup}`}>
              <label className={`${styles.FormLabel}`}>Registered Company Name*</label>
              <input {...register("company_name",{required:'Please enter company name'})} className={`${styles.FormControl}`} />
              {errors.company_name && !watch().post_code && <span className={`${styles.ErrorM}`}>{errors.company_name.message}</span>}
            </div>
          </>}
        </div>
        <div className={`${styles.FormButtonArea}`}>
          <button onClick={modalClose} type="button" className={`${styles.CancelBU}`}>Cancel</button>
          <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
        </div>
      </form>
    </div>}
  </React.Fragment>)
}

export default CustomerAddForm;