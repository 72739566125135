import React, {useState} from 'react';
import styles from './add.module.css';
import { Button  } from '@mui/material';
import { Link } from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ChangePasswordForm({ id,modalClose }) {
  const auth = authData();
  const [loading, setLoading] = useState(false);

  let schema = yup.object().shape({
    password: yup.string().required('This field is Required').min(6,'Password length should be 6-15').max(15,'Password length should be 6-15'),
    conf_password: yup.string().required('This field is Required').oneOf([yup.ref('password'), null], 'Password does not match')
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  
  
  async function onSubmitLocal(data) {
    setLoading(true);

    axios.post(process.env.REACT_APP_API_URL + 'v1/drivers-password-change/'+id, {password: data?.password}, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      setLoading(false);
      if(response.data.status === 'SUCCESS'){
        modalClose();
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    }).catch(error => {
      setLoading(false);
    });
    /*setValidationErrors({});

    let postData = data;

    postData = {...postData, dob: moment(postData?.dob).utc().format('YYYY-MM-DD'), driver_type: postData?.driver_type?.value, wage_type: postData?.wage_type?.value, location: postData?.location?.value };

    setLoading(true);

    if(isAddMode){
      return axios.post(process.env.REACT_APP_API_URL + 'v1/drivers', postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          modalClose();
          onSubmit();
        }
        if(response.data.status === 'ERROR'){
          setValidationErrors(response.data.messages);
        }
      }).catch(error => {
        setLoading(false);
        if(error && error.response && error.response.data && error.response.data.messages){
          setValidationErrors(error.response.data.messages);
        }
      });
    }else{
      return axios.post(process.env.REACT_APP_API_URL + 'v1/drivers/'+id, postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          onSubmit();
        }
      }).catch(error => {
        setLoading(false);
      });
    }*/
  }
  
  return (
      <React.Fragment>
        {loading && <Loader /> }
        <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmitLocal)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
              <label className={`${styles.FormLabel}`}>Password*</label>
                <input type="password" {...register("password")} className={`${styles.FormControl}`} autoComplete="off"  />
                {errors.password && <span className={`${styles.ErrorM}`}>{errors.password.message}</span>}
              </div>
            </div>

            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
              <label className={`${styles.FormLabel}`}>Confirm Password*</label>
                <input type="password" {...register("conf_password")} className={`${styles.FormControl}`} autoComplete="off"  />
                {errors.conf_password && <span className={`${styles.ErrorM}`}>{errors.conf_password.message}</span>}
              </div>
            </div>
            
            
            
            
            <div className={`${styles.FormButtonArea}`}>
              <Link onClick={modalClose} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
              <input className={`${styles.SubmitBU}`} value="Update" type="submit" />
            </div>
          </form>
        </div>
      </React.Fragment>
  )}


export default ChangePasswordForm;