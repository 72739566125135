import React from 'react';
import styles from './list.module.css';
import axios from 'axios';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";

export default function PaymentCancelPopup ({modalClose,bookingId,paymentItem}){
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = React.useState(false);

  const onSubmitLocal = ()=>{
    modalClose();
    setLoading(true);

    axios.put(process.env.REACT_APP_API_URL + 'v1/bookings/'+bookingId, {note:'', payment_status:0} , {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        paymentItem(response.data.message,bookingId);
      }else{
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }


  return (<React.Fragment>
    {loading && <Loader /> }
    <div className={`${styles.FullDiv}`}>
      <h2 className={`${styles.PopupTitle}`}>Do you want to make payment now?</h2>
      <div className={`${styles.FormButtonArea}`}>
        <input className={`${styles.CancelBU}`} value="No" type="button" onClick={modalClose.bind(this)} />
        <input className={`${styles.SubmitBU}`} value="Yes" type="button" onClick={onSubmitLocal} />
      </div>
    </div>
  </React.Fragment>)
}