import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styles from './sideBar.module.css';
import { User, Calendar, FileText, Truck, Power } from 'react-feather';
import {authData} from "../../admin/components/getAuth";

function SideBar() {
  const auth = authData();
  const navigate = useNavigate();

  const logout=()=>{
    localStorage.removeItem('authData');
    navigate('/');
  }
  
    return (

      <div className={`${styles.CustoSideNav}`}>
        <ul>
          <li className={`${styles.ListHead}`}>
            <div className={`${styles.CusAvatar}`}>
              <span>{auth?.customer?.name_initials}</span>
            </div>
            <p>{auth?.customer?.name}</p>
          </li>
          {/*<li><Link><PieChart/> Dashboard</Link></li>*/}
          <li><Link to={'/customer/my-bookings'}><FileText/> My booking</Link></li>
          <li><Link to={'/customer/add-booking'}><Calendar/> Add a booking</Link></li>
          <li><Link to={'/customer/track-booking'}><Truck/> Track a booking</Link></li>
          <li><Link to={'/customer/my-profile'}><User/> My profile</Link></li>
          <li><button onClick={logout.bind(this)}><Power/> Logout</button></li>
        </ul>
      </div>
      
  );
}


export default SideBar;