import React,{useEffect} from 'react';
import styles from './updateprofile.module.css';
import { useForm,Controller } from "react-hook-form";
import withCustomerAuth from "../../components/withCustomerAuth";
import {authData} from "../../../admin/components/getAuth";
import Loader from  '../../../admin/components/Loader';
import {  useNavigate} from "react-router-dom";
import axios from 'axios';
import {isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Select from "react-select";
import SideBar from "../../components/sideBar";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MyProfile() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, control, formState: { errors },setValue, watch } = useForm();
  const [isRegCompany,setIsRegCompany] = React.useState({});

  useEffect(()=>{
    setValue('name',authData()?.customer?.name);
    setValue('email',authData()?.customer?.email);
    setValue('phone',authData()?.customer?.phone);
    setValue('address',authData()?.customer?.address);
    setValue('post_code',authData()?.customer?.post_code);
    if(moment(authData()?.customer?.dob).isValid())
      setValue('dob',moment(authData()?.customer?.dob).toDate());
    setIsRegCompany({ value: authData()?.customer?.registered_company, label: authData()?.customer?.registered_company });
    setValue('registered_company',{ value: authData()?.customer?.registered_company, label: authData()?.customer?.registered_company });
    if( authData()?.customer?.registered_company === 'Yes'){
      setValue('company_shipping',{ value: authData()?.customer?.company_shipping, label: authData()?.customer?.company_shipping });
      setValue('company_name',authData()?.customer?.company_name);
    }
},[setValue]);

  const onSubmit = (data) => {
    setLoading(true);

    let postData = {address:data?.address, email:data?.email, name:data?.name, phone:data?.phone, post_code:data?.post_code, dob: moment(data?.dob).utc().format('YYYY-MM-DD'), registered_company: data?.registered_company?.value };

    if(data?.registered_company?.value === 'Yes'){
      postData = {...postData, company_name:data?.company_name,company_shipping: data?.company_shipping?.value };
    }

    return axios.post(process.env.REACT_APP_API_URL + 'v1/update-profile', postData, {
      headers: {
        Authorization: `Bearer ${authData()?.api_token}`,
      }
    }).then(response => {
      setLoading(false);
      if(response.data.status === 'SUCCESS'){
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        let resData = response.data.data;
        let authData = localStorage.getItem('authData');
        authData = JSON.parse(authData);
        authData = {...authData, 'customer':resData};
        localStorage.setItem('authData', JSON.stringify(authData));
        navigate('/customer/my-profile');
      }
    }).catch(error => {
      setLoading(false);
    });
  };
  
  return (
    <>
    {loading && <Loader /> }
    <div className={`${styles.MainDivCustomer}`}>
      <img src="/images/booking-bg.jpg" alt="" className={`${styles.BackGroundGra}`} />
      <div className={`${styles.Container}`}>
        <div className={`${styles.CusRow}`}>
          <div className={`${styles.CustomerSideBar}`}><SideBar/></div>
          <div className={`${styles.UserProCard}`}>
            <div className={`${styles.UserHead}`}>
              <p className={`${styles.UserProName}`}>Update Profile</p>
            </div>
            <div className={`${styles.UserProDe}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label class={`${styles.FormLabel}`}>Name*</label>
                    <input type="text" {...register("name", {required:'Please enter name'})} className={`${styles.FormControl}`} />
                    {errors?.name && <span className={`${styles.ErrorM}`}>{errors.name.message}</span>}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label class={`${styles.FormLabel}`}>Email*</label>
                    <input type="text" {...register("email")} className={`${styles.FormControl}`} readOnly={true} />
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label class={`${styles.FormLabel}`}>Phone Number*</label>
                    <Controller
                      control={control}
                      {...register('phone',{required:'Please enter mobile no',validate:isValidPhoneNumber})}
                      className={`${styles.FormControl}`}
                      render={({ field }) => (
                          <Input 
                            {...field}
                            country="GB"
                            international
                            withCountryCallingCode
                            className={`${styles.FormControl}`}
                          />
                        )}
                    />
                    {(errors.phone && errors.phone.type !== "validate") && <span className={`${styles.ErrorM}`}>{errors.phone.message}</span>}
                    {(errors.phone && errors.phone.type === "validate") && <span className={`${styles.ErrorM}`}>Please enter valid phone</span>}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label class={`${styles.FormLabel}`}>Date Of Birth*</label>
                    <Controller
                      control={control}
                      {...register('dob',{required:'Please select date of birth'})}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          showYearDropdown
                          yearDropdownItemNumber={50}
                          scrollableYearDropdown
                          autoComplete='off'
                          maxDate={moment().toDate()}
                          selected={field.value}
                          className={`${styles.FormControl}`}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => field.onChange(date)}
                          ref={(ref) => {
                            field.ref({
                              focus: ref?.setFocus
                            });
                          }}
                        />
                      )}
                    />
                    {errors.dob && !watch().dob && <span className={`${styles.ErrorM}`}>{errors.dob?.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label class={`${styles.FormLabel}`}>Address*</label>
                    <input type="text" {...register("address",{required:'Please enter address'})} className={`${styles.FormControl}`} />
                    {errors?.address && <span className={`${styles.ErrorM}`}>{errors.address.message}</span>}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label class={`${styles.FormLabel}`}>Post Code*</label>
                    <input type="text" {...register("post_code")} className={`${styles.FormControl}`} />
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label class={`${styles.FormLabel}`}>Do you have a Registered Company?**</label>
                    <Controller
                      control={control}
                      options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}
                      {...register('registered_company',{required:'Please select one'})}
                      render={({ field }) => (
                        <Select {...field} onChange={(e)=>{field.onChange(e); setIsRegCompany(e);}} options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}  />
                      )}
                    />
                    {errors?.registered_company && <span className={`${styles.ErrorM}`}>{errors.registered_company.message}</span>}
                  </div>
                  {(isRegCompany?.value === 'Yes') && <div className={`${styles.FormGroup}`}>
                    <label class={`${styles.FormLabel}`}>Registered Company Name*</label>
                    <input type="text" {...register("company_name",{required:'Please enter company name'})} className={`${styles.FormControl}`} />
                    {errors?.company_name && <span className={`${styles.ErrorM}`}>{errors.company_name.message}</span>}
                  </div>}
                </div>
                {(isRegCompany?.value === 'Yes') && <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label class={`${styles.FormLabel}`}>Do you have a Registered Company?**</label>
                    <Controller
                      control={control}
                      options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}
                      {...register('company_shipping',{required:'Please select one'})}
                      render={({ field }) => (
                        <Select {...field} options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}  />
                      )}
                    />
                    {errors?.company_shipping && <span className={`${styles.ErrorM}`}>{errors.company_shipping.message}</span>}
                  </div>
                </div>}
                <div className={`${styles.MyProBUSec}`}>
                  <button className={`${styles.MyProUpdateBU}`}>Update Profile</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default withCustomerAuth(MyProfile);