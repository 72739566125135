import React from 'react';
import styles from './booking.module.css';
import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import {FormControlLabel, Checkbox} from '@mui/material';
import {formatPhoneNumberIntl } from 'react-phone-number-input';
import moment from 'moment';
import { useForm } from "react-hook-form";

export default function Step4({bookingData,saveStep4Data}){
  const [DeliveryTotal,setDeliveryTotal] = React.useState(0);
  const [SubTotal,setSubTotal] = React.useState(0);
  const [Total,setTotal] = React.useState(0);
  const VATCharge = 0;
  const InsuranceCharge = 0;

  const { register, handleSubmit, formState: { errors } } = useForm({mode: 'onChange'});


  React.useState(()=>{
    let deliverytotalTemp = 0;
    let subtotalTemp = 0;
    if(bookingData?.is_delivery){
      bookingData?.delivery_products?.forEach(item=>{
        deliverytotalTemp += parseFloat(item.total);
      });
    }
    bookingData?.products?.forEach(item=>{
      subtotalTemp += parseFloat(item.total);
    });
    setDeliveryTotal(deliverytotalTemp);
    setSubTotal(subtotalTemp);
    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);

  },[bookingData]);

  const onSubmit = (data) =>{
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
    saveStep4Data();
  }

  
return <>
  
  <div className={`${styles.SummerySec}`}>
    <div className={`${styles.SummerySecUnder}`}>
      <div className={`${styles.SummerySecRow}`}>

        <div className={`${styles.SummerySecHalf}`}>
          <p className={`${styles.StepBoxTitle2} ${styles.Heading}`}>CONFIRM YOUR USER DETAILS</p>
          <ul className={`${styles.SummeryListSec}`}>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Name:</p>
              <p className={`${styles.SummeryHeadText}`}>{bookingData?.pickUpData?.name}</p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Address:</p>
              <p className={`${styles.SummeryHeadText}`}>{bookingData?.pickUpData?.address}</p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Post Code:</p>
              <p className={`${styles.SummeryHeadText}`}>{bookingData?.pickUpData?.post_code}</p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Contact No:</p>
              <p className={`${styles.SummeryHeadText}`}>{bookingData?.pickUpData?.phone && formatPhoneNumberIntl(bookingData?.pickUpData?.phone)}</p>
            </li>
            {bookingData?.pickUpData?.alt_contact_nos?.map((item,index)=>{
              return (<li>
                <p className={`${styles.SummeryHeadTitle}`}>Alt Contact No:</p>
                <p className={`${styles.SummeryHeadText}`}>{item?.value && formatPhoneNumberIntl(item?.value)}</p>
              </li>)
            })}
          </ul>
        </div>

        <div className={`${styles.SummerySecHalf}`}>
          <p className={`${styles.StepBoxTitle2} ${styles.Heading}`}>CONFIRM YOUR RECIPIENT DETAILS</p>
          <ul className={`${styles.SummeryListSec}`}>
          <li>
              <p className={`${styles.SummeryHeadTitle}`}>Name:</p>
              <p className={`${styles.SummeryHeadText}`}>{bookingData?.dropOffData?.name}</p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Address:</p>
              <p className={`${styles.SummeryHeadText}`}>{bookingData?.dropOffData?.address}</p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Contact No:</p>
              <p className={`${styles.SummeryHeadText}`}>{bookingData?.dropOffData?.phone && formatPhoneNumberIntl(bookingData?.dropOffData?.phone)}</p>
            </li>
            {bookingData?.dropOffData?.alt_contact_nos?.map((item,index)=>{
              return (<li>
                <p className={`${styles.SummeryHeadTitle}`}>Alt Contact No:</p>
                <p className={`${styles.SummeryHeadText}`}>{item?.value && formatPhoneNumberIntl(item?.value)}</p>
              </li>)
            })}
          </ul>
        </div>

      </div>
    </div>
  </div>

  <p className={`${styles.StepBoxTitle} ${styles.Heading}`}>CONFIRM YOUR ITEM DETAILS</p>

  <div className={`${styles.TableSec}`}>
    <table>
      {bookingData?.is_delivery && <><tr>
        <td className={`${styles.TableHead}`}>Delivery Item</td>
        {/*<td className={`${styles.TableHead}`}>Delivery Item</td>*/}
        <td className={`${styles.TableHead}`}>Quantity</td>
        <td className={`${styles.TableHead}`}>Total</td>
      </tr>
      {bookingData?.is_delivery && bookingData?.delivery_products?.map((item,index)=>{
        return (<tr>
          <td className={`${styles.TableCon}`}>{item?.category_id?.label}{(item?.category_id?.value && item?.category_id?.value === 999) ? ' - '+item?.item : ((item?.category_id?.no_of_child > 0)?' - '+item?.item?.label:'')}</td>
          {/*<td className={`${styles.TableCon}`}>{(item?.category_id?.value && item?.category_id?.value === 999) ?item?.item_id:item?.item_id?.label}</td>*/}
          <td className={`${styles.TableCon}`}>{item?.quantity}</td>
          <td className={`${styles.TableCon}`}>&pound; {(parseFloat(item.total)).toFixed(2)}</td>
        </tr>)
      })}
      </>}
      <tr>
        <td className={`${styles.TableHead}`} style={{background:'#002e5b', color: '#fff'}}>Pickup Item</td>
        {/*<td className={`${styles.TableHead}`} style={{background:'#002e5b', color: '#fff'}}>Pickup Item</td>*/}
        <td className={`${styles.TableHead}`} style={{background:'#002e5b', color: '#fff'}}>Fragile</td>
        <td className={`${styles.TableHead}`} style={{background:'#002e5b', color: '#fff'}}>Estimated Value</td>
      </tr>
      {bookingData?.products?.map((item,index)=>{
        return (<tr>
          <td className={`${styles.TableCon}`}>{item?.category_id?.label}{(item?.category_id?.value && item?.category_id?.value === 999) ? ' - '+item?.item : ((item?.category_id?.no_of_child > 0)?' - '+item?.item?.label:'')}</td>
          {/*<td className={`${styles.TableCon}`}>{(item?.category_id?.value && item?.category_id?.value === 999) ?item?.item_id:item?.item_id?.label}</td>*/}
          <td className={`${styles.TableCon}`}>{item?.is_fragile ? 'Yes':'No'}</td>
          <td className={`${styles.TableCon}`}>&pound; {(parseFloat(item.total)).toFixed(2)}</td>
        </tr>)
      })}
      <tr>
        <td colSpan="2" className={`${styles.TableCon}`}>
          <p className={`${styles.SummeryPPTI}`}>Order Subtotal:</p>
          <p className={`${styles.SummeryPPTI}`}>Total VAT:</p>
          <p className={`${styles.SummeryPPTI}`}>Insurance Charges:</p>
          {bookingData?.is_delivery && <p className={`${styles.SummeryPPTI}`}>Delivery Total:</p>}
          <p className={`${styles.SummeryPPTI} ${styles.Big}`}>Total:</p>
        </td>
        <td className={`${styles.TableCon}`}>
          <p className={`${styles.SummeryPPTE}`}>£{SubTotal.toFixed(2)}</p>
          <p className={`${styles.SummeryPPTE}`}>£{VATCharge.toFixed(2)}</p>
          <p className={`${styles.SummeryPPTE}`}>£{InsuranceCharge.toFixed(2)}</p>
          {bookingData?.is_delivery && <p className={`${styles.SummeryPPTE}`}>£{DeliveryTotal.toFixed(2)}</p>}
          <p className={`${styles.SummeryPPTE} ${styles.Big}`}>£{Total.toFixed(2)}</p>
        </td>
      </tr>
    </table>
  </div>

  <div className={`${styles.TableConSec}`}>
    <p className={`${styles.TableConSecTitle}`}>To be collected:</p>
    <p className={`${styles.TableConSecText}`}>{moment(bookingData.pickup_date).utc().format('ddd MMM D, YYYY')}</p>
  </div>

  <div className={`${styles.TableConSec}`}>
    <p className={`${styles.TableConSecTitle}`}>Booking Notes:</p>
    <p className={`${styles.TableConSecText}`}>{bookingData?.notes}</p>
  </div>

  <form id="step4-form" onSubmit={handleSubmit(onSubmit)}>

  <FormControlLabel control={<Checkbox {...register('is_accept',{required:true})} />} label="I confirm that the order summary above is correct *" className={`${styles.CheckBox} CheckBox`} />
  {(errors?.is_accept) && <p className={`${styles.ErrorM}`}>Please accept terms & condition</p>}
  </form>

  </>
}