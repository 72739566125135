import React, {useState,useEffect} from 'react';
import styles from './add.module.css';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { FromLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
//import withAdminAuth from "../../components/withAdminAuth";
import {authData} from "../../components/getAuth";
import Select from "react-select";
import {isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from "react-google-autocomplete";

function DriverAddForm({ onSubmit,isAddMode, id,modalClose }) {
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [driverType, setDriverType] = useState('GB');
  const [driverCountry, setDriverCountry] = useState('uk');

  let schema;
  if(isAddMode){
    schema = yup.object().shape({
      name: yup.string().required('This field is Required').max(50, 'Name cannot be more than 50 characters'),
      password: yup.string().required('This field is Required').min(6,'Password length should be 6-15').max(15,'Password length should be 6-15'),
      email: yup.string().required('This field is Required').email('Invalid email'),
      address: yup.string().required('This field is Required'),
      house_no: yup.string().required('This field is Required'),
      driver_type: yup.object().required('This field is Required'),
      wage_type: yup.object().required('This field is Required'),
      location: yup.object().required('This field is Required'),
    });
  }else{
    schema = yup.object().shape({
      name: yup.string().required('This field is Required').max(50, 'Name cannot be more than 50 characters'),
      email: yup.string().required('This field is Required').email('Invalid email'),
      address: yup.string().required('This field is Required'),
      house_no: yup.string().required('This field is Required'),
      driver_type: yup.object().required('This field is Required'),
      wage_type: yup.object().required('This field is Required'),
    });
  }

  const { register, handleSubmit,control, watch,setValue,  formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange', defaultValues:{
      location: {'label':'UK Warehouse','value':'UK Warehouse'},
      mobile: ""
    }
  });

  useEffect(()=>{
    if(!isAddMode){
      if(contentLoading){
        axios.get(process.env.REACT_APP_API_URL + 'v1/drivers/'+id, {
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
          }
        }).then(response => {
          setContentLoading(false);
          if(response.data.status === 'SUCCESS'){
            let itemData = response.data.data;
            setValue('name',itemData.name);
            setValue('email',itemData.email);
            setValue('mobile',itemData.mobile);
            setValue('address',itemData.address);
            setValue('house_no',itemData.house_no);
            setValue('dob',moment(itemData.dob).toDate());
            setValue('driver_type',{ value: itemData.driver_type, label: itemData.driver_type });
            setValue('wage_type',{ value: itemData.wage_type, label: itemData.wage_type });
            setValue('location',{ value: itemData.location, label: itemData.location });

            if(itemData?.location === 'Ghana Warehouse'){
              setDriverType('GH');
              setDriverCountry('gh');
            }else{
              setDriverType('GB');
              setDriverCountry('uk');
            }
          }
        }).catch(error => {
          setContentLoading(false);
        });
      }
    }else{
      setContentLoading(false);
    }
  },[API_TOKEN,contentLoading,id,isAddMode,setValue]);
  
  async function onSubmitLocal(data) {
    setValidationErrors({});

    let postData = data;

    postData = {...postData, dob: moment(postData?.dob).utc().format('YYYY-MM-DD'), driver_type: postData?.driver_type?.value, wage_type: postData?.wage_type?.value, location: postData?.location?.value };

    setLoading(true);

    if(isAddMode){
      return axios.post(process.env.REACT_APP_API_URL + 'v1/drivers', postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          modalClose();
          onSubmit();
        }
        if(response.data.status === 'ERROR'){
          setValidationErrors(response.data.messages);
        }
      }).catch(error => {
        setLoading(false);
        if(error && error.response && error.response.data && error.response.data.messages){
          setValidationErrors(error.response.data.messages);
        }
      });
    }else{
      return axios.post(process.env.REACT_APP_API_URL + 'v1/drivers/'+id, postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          onSubmit();
        }
      }).catch(error => {
        setLoading(false);
      });
    }
  }
  
  return (
      <React.Fragment>
        {loading && <Loader /> }
        {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div> }
        {!contentLoading && <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmitLocal)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Select Location*</label>
                <Controller
                  control={control}
                  options={[{'label':'UK Warehouse','value':'UK Warehouse'},{'label':'Ghana Warehouse','value':'Ghana Warehouse'}]}
                  {...register('location')}
                  render={({ field }) => (
                    <Select {...field} options={[{'label':'UK Warehouse','value':'UK Warehouse'},{'label':'Ghana Warehouse','value':'Ghana Warehouse'}]} onChange={(e)=>{ 
                      field.onChange(e);
                      setValue('mobile','');
                      if(e?.value === 'Ghana Warehouse'){
                        setDriverType('GH');
                        setDriverCountry('gh');
                      }else{
                        setDriverType('GB');
                        setDriverCountry('uk');
                      }
                    }}  />
                  )}
                />
                {errors.location && <span className={`${styles.ErrorM}`}>{errors.location.message}</span>}
              </div>

              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Name*</label>
                <input {...register("name")} className={`${styles.FormControl}`} autoComplete="off" />
                {errors.name && <span className={`${styles.ErrorM}`}>{errors.name.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Email*</label>
                <input {...register("email")} className={`${styles.FormControl}`} autoComplete="off" readOnly={!isAddMode} />
                {errors.email && <span className={`${styles.ErrorM}`}>{errors.email.message}</span>}
                {(validationErrors?.email && validationErrors?.email[0]) && <span className={`${styles.ErrorM}`}>{validationErrors?.email[0]}</span>}
              </div>
              
              {isAddMode && <div className={`${styles.FormGroup}`}>
              <label className={`${styles.FormLabel}`}>Password*</label>
                <input type="password" {...register("password")} className={`${styles.FormControl}`} autoComplete="off"  />
                {errors.password && <span className={`${styles.ErrorM}`}>{errors.password.message}</span>}
              </div>}
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Phone*</label>
                <Controller
                  control={control}
                  {...register('mobile',{required:'Please enter mobile no',validate:isValidPhoneNumber})}
                  className={`${styles.FormControl}`}
                  render={({ field }) => (
                      <Input 
                        {...field}
                        country={driverType}
                        international
                        withCountryCallingCode
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />
                {(errors.mobile && errors.mobile.type !== "validate") && <p className={`${styles.ErrorM}`}>{errors.mobile.message}</p>}
                {(errors.mobile && errors.mobile.type === "validate") && <p className={`${styles.ErrorM}`}>Please enter valid phone</p>}
              </div>

              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Date Of Birth*</label>
                <Controller
                  control={control}
                  {...register('dob',{required:'Please select date of birst'})}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      showYearDropdown
                      yearDropdownItemNumber={50}
                      scrollableYearDropdown
                      autoComplete='off'
                      maxDate={moment().toDate()}
                      selected={field.value}
                      className={`${styles.FormControl}`}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => field.onChange(date)}
                      ref={(ref) => {
                        field.ref({
                          focus: ref?.setFocus
                        });
                      }}
                    />
                  )}
                />
                {errors.dob && !watch().dob && <span className={`${styles.ErrorM}`}>{errors.dob.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Address*</label>
                <Controller
                  control={control}
                  {...register("address")}
                  className={`${styles.FormControl}`}
                  autoComplete="off"
                  render={({ field }) => (<Autocomplete
                    {...field}
                    placeholder=''
                    className={`${styles.FormControl}`}
                    apiKey={process.env.REACT_APP_MAP_API_KEY}
                    onPlaceSelected={(place) => {
                      setValue('address',place.formatted_address);
                    }}
                    options={{types: ["address"], componentRestrictions: { country: driverCountry } }}
                  />)}
            />
                {errors.address && <span className={`${styles.ErrorM}`}>{errors.address.message}</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>House No*</label>
                <input {...register("house_no")} className={`${styles.FormControl}`} />
                {errors.house_no && !watch().house_no && <span className={`${styles.ErrorM}`}>{errors.house_no.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Driver Type*</label>
                <Controller
                  control={control}
                  options={[{'label':'Direct Payroll','value':'Direct Payroll'},{'label':'3rd Party Payroll','value':'3rd Party Payroll'}]}
                  {...register('driver_type')}
                  render={({ field }) => (
                    <Select {...field} options={[{'label':'Direct Payroll','value':'Direct Payroll'},{'label':'3rd Party Payroll','value':'3rd Party Payroll'}]}  />
                  )}
                />
                {errors.driver_type && <span className={`${styles.ErrorM}`}>{errors.driver_type.message}</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Wage Type*</label>
                <Controller
                  control={control}
                  options={[{'label':'Hourly Basis','value':'Hourly Basis'},{'label':'Monthly Basis','value':'Monthly Basis'}]}
                  {...register('wage_type')}
                  render={({ field }) => (
                    <Select {...field} options={[{'label':'Hourly Basis','value':'Hourly Basis'},{'label':'Monthly Basis','value':'Monthly Basis'}]}  />
                  )}
                />
                {errors.wage_type && !watch().wage_type && <span className={`${styles.ErrorM}`}>{errors.wage_type.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormButtonArea}`}>
              <button onClick={modalClose} className={`${styles.CancelBU}`}>Cancel</button>
              <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
            </div>
          </form>
        </div>}
      </React.Fragment>
  )}


export default DriverAddForm;