import React, {useState} from 'react';
import styles from './addForm.module.css';
import { Button  } from '@mui/material';
import { Link } from '@mui/material';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from "react-google-autocomplete";
import {isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';

function UserAddForm({ onSubmit,modalClose }) {
  const auth = authData();
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  let schema = yup.object().shape({
    first_name: yup.string().required('This field is Required').max(50, 'Name cannot be more than 50 characters'),
    last_name: yup.string().required('This field is Required').max(50, 'Name cannot be more than 50 characters'),
    email: yup.string().required('This field is Required').email('Invalid email'),
    phone: yup.string().required('This field is Required')
  });

  const { register, handleSubmit, watch, formState: { errors }, control,setValue } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  async function onSubmitLocal(data) {
    setValidationErrors({});
    
    let postData = data;
    postData = {...postData,name:data.first_name+' '+data.last_name,user_type: 'Customer', password:'123456'};

    setLoading(true);

    return axios.post(process.env.REACT_APP_API_URL + 'v1/users', postData, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      setLoading(false);
      if(response.data.status === 'SUCCESS'){
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        onSubmit(response.data.data);
      }
      if(response.data.status === 'ERROR'){
        setValidationErrors(response.data.messages);
      }
    }).catch(error => {
      setLoading(false);
      if(error && error.response && error.response.data && error.response.data.messages){
        setValidationErrors(error.response.data.messages);
      }
    });
  }
  
  return (<React.Fragment>
    {loading && <Loader /> }
    <div className={`${styles.AddUserMain}`}>
      <form onSubmit={handleSubmit(onSubmitLocal)}>
        <div className={`${styles.FormRow}`}>
          <div className={`${styles.FormGroup}`}>
            <label className={`${styles.FormLabel}`}>First Name*</label>
            <input {...register("first_name")} className={`${styles.FormControl}`} />
            {errors.first_name && !watch().first_name && <span className={`${styles.ErrorM}`}>{errors.first_name.message}</span>}
          </div>
          <div className={`${styles.FormGroup}`}>
            <label className={`${styles.FormLabel}`}>Last Name*</label>
            <input {...register("last_name")} className={`${styles.FormControl}`} />
            {errors.last_name && !watch().last_name && <span className={`${styles.ErrorM}`}>{errors.last_name.message}</span>}
          </div>
        </div>
        <div className={`${styles.FormRow}`}>
          <div className={`${styles.FormGroup}`}>
            <label className={`${styles.FormLabel}`}>Email*</label>
            <input {...register("email")} className={`${styles.FormControl}`} autoComplete="off" />
            {errors.email && <span className={`${styles.ErrorM}`}>{errors.email.message}</span>}
            {(validationErrors?.email && validationErrors?.email[0]) && <span className={`${styles.ErrorM}`}>{validationErrors?.email[0]}</span>}
          </div>
          <div className={`${styles.FormGroup}`}>
            <label className={`${styles.FormLabel}`}>Phone No*</label>
            <Controller
              control={control}
              {...register('phone',{required:'Please enter phone no',validate:isValidPhoneNumber})}
              className={`${styles.FormControl}`}
              render={({ field }) => (<Input {...field} country="GB" international withCountryCallingCode className={`${styles.FormControl}`} />)}
            />
            {errors.phone && !watch().phone && <span className={`${styles.ErrorM}`}>{errors.phone.message}</span>}
          </div>
        </div>
        <div className={`${styles.FormRow}`}>
          <div className={`${styles.FormGroup}`}>
            <label className={`${styles.FormLabel}`}>Address*</label>
            <Controller
              control={control}
              {...register(`address`,{required:'Please enter address'})}
              className={`${styles.FormControl}`}
              render={( {field} ) => (<Autocomplete
                {...field}
                className={`${styles.FormControl} `}
                apiKey={process.env.REACT_APP_MAP_API_KEY}
                onPlaceSelected={(place) => {
                  setValue('address',place.formatted_address);
                  setValue('latitude',place.geometry.location.lat());
                  setValue('longitude',place.geometry.location.lng());
                  
                  place.address_components.forEach(item=>{
                    if(item.types.indexOf('postal_code') > -1){
                      setValue('post_code',item.long_name);
                    }
                  })
                }}
                options={{
                  types: ["address"],
                  componentRestrictions: { country: "uk" },
                }}
              />)}
            />
            {errors.address && <span className={`${styles.ErrorM}`}>{errors.address.message}</span>}
          </div>
          <div className={`${styles.FormGroup}`}>
            <label className={`${styles.FormLabel}`}>Post Code*</label>
            <input {...register("post_code")} className={`${styles.FormControl}`} />
            {errors.post_code && <span className={`${styles.ErrorM}`}>{errors.post_code.message}</span>}
          </div>
        </div>
        <div className={`${styles.FormButtonArea}`}>
          <Link onClick={modalClose} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
          <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
        </div>
      </form>
    </div>
  </React.Fragment>);
  
}

export default UserAddForm;