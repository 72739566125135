import React from 'react';
import styles from './LoginForm.module.css';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import Loader from  '../../../admin/components/Loader';
import { useForm } from "react-hook-form";

const schema = yup.object().shape({
  password: yup.string().required('This field is Required').min(6,'Password length should be 6-15').max(15,'Password length should be 6-15'),
  conf_password: yup.string().when('password', (password, field) =>
    password ? field.oneOf([yup.ref('password')],'Passwords must match') : field
  ),
  //chooseCb: yup.bool().oneOf([true], 'Please accept agreement.'),
});

export default function BookinSignUpForm({onSubmit,bookingData}) {
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, seterrorMsg] = React.useState('');

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  React.useEffect(()=>{
    setValue(`name`,bookingData?.pickUpData?.name);
    setValue(`email`,bookingData?.pickUpData?.email);
  },[bookingData?.pickUpData?.name,bookingData?.pickUpData?.email,setValue]);
  
  async function onSubmitLocal(data) {
    const token = '';
    delete data.conf_password;
    data = {...data,user_type:'Customer', phone: bookingData?.pickUpData?.phone, alt_phones: bookingData?.pickUpData?.alt_contact_nos, address: bookingData?.pickUpData?.address, post_code: bookingData?.pickUpData?.post_code, registered_company: bookingData?.registered_company?.value};

    if(bookingData?.pickUpData?.latitude){
      data = {...data, latitude:bookingData?.pickUpData?.latitude };
    }
    if(bookingData?.pickUpData?.longitude){
      data = {...data, longitude:bookingData?.pickUpData?.longitude };
    }

    if(bookingData?.registered_company?.value === 'Yes'){
      data = {...data, company_name: bookingData?.company_name, company_shipping: bookingData?.company_shipping?.value};
    }
    const postData = data;

    setLoading(true);
    seterrorMsg('');
      
    return axios.post(process.env.REACT_APP_API_URL + 'v1/add-customer', postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
      setLoading(false);
      if(response.data.status === 'SUCCESS'){
        const resData = response.data.data;
        localStorage.setItem('authData', JSON.stringify(resData));
        onSubmit();
      }
      if(response.data.status === 'ERROR'){
        seterrorMsg(response?.data?.message);
      }
    }).catch(error => {
      if(error && error?.response && error?.response?.data && error?.response?.data?.message){
        seterrorMsg(error?.response?.data?.message);
      }
      setLoading(false);
    });
  }

  return (
    <>
    {loading && <Loader /> }
    <form onSubmit={handleSubmit(onSubmitLocal)} >
      <p className={`${styles.ErrorM}`}>{errorMsg}</p>
      <div className={`${styles.FormRow}`}>
        <div className={`${styles.FormGroup}`}>
          <label className={`${styles.FormLabel}`}>Name*</label>
          <input {...register("name")} className={`${styles.FormControl}`} readOnly={true} />
        </div>
        <div className={`${styles.FormGroup}`}>
          <label className={`${styles.FormLabel}`}>Email*</label>
          <input {...register("email")} className={`${styles.FormControl}`} readOnly={true} />
        </div>
      </div>
      <div className={`${styles.FormRow}`}>
        <div className={`${styles.FormGroup}`}>
          <label className={`${styles.FormLabel}`}>Password</label>
          <input {...register("password")} type="password" className={`${styles.FormControl}`} />
          {errors.password && <span className={`${styles.ErrorM}`}>{errors.password.message}</span>}
        </div>
        <div className={`${styles.FormGroup}`}>
          <label className={`${styles.FormLabel}`}>Confirm Password</label>
          <input {...register("conf_password")} type="password" className={`${styles.FormControl}`} />
          {errors.conf_password && <span className={`${styles.ErrorM}`}>{errors.conf_password.message}</span>}
        </div>
      </div>
      <button type="submit" className={`${styles.LoginBU}`}><span>Register</span></button>
    </form>
    </>
  )
}