import React, {useState,useEffect} from 'react';
import styles from './add.module.css';
import { Button  } from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { FromLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import { Link } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CategoryAddForm({ onSubmit,isAddMode, id, modalClose }) {
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);

  let schema;
  schema = yup.object().shape({
    title: yup.string().required('This field is Required').max(50, 'Title cannot be more than 50 characters'),
  });
  
  const { register, handleSubmit, watch,setValue,  formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });
  
  useEffect(()=>{
    if(!isAddMode){
      if(contentLoading){
        axios.get(process.env.REACT_APP_API_URL + 'v1/categories/'+id, {
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
          }
        }).then(response => {
          setContentLoading(false);
          if(response.data.status === 'SUCCESS'){
            let itemData = response.data.data;
            setValue('title',itemData.title);
          }
        }).catch(error => {
          setContentLoading(false);
        });
      }
    }else{
      setContentLoading(false);
    }
  },[API_TOKEN,contentLoading,id,isAddMode,setValue]);
  
  async function onSubmitLocal(data) {
    const postData = data;
    setLoading(true);
    if(isAddMode){
      return axios.post(process.env.REACT_APP_API_URL + 'v1/categories', postData, {
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          onSubmit();
        }
      }).catch(error => {
        setLoading(false);
      });
    }else{
      return axios.post(process.env.REACT_APP_API_URL + 'v1/categories/'+id, postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message);
          onSubmit();
        }
      }).catch(error => {
        setLoading(false);
      });
    }
  }
  
  return (
    <React.Fragment>
      {loading && <Loader /> }
      {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div> }
      {!contentLoading && <div className={`${styles.AddUserMain}`}>
        <form onSubmit={handleSubmit(onSubmitLocal)}>
          <div className={`${styles.FormRow}`}>
            <div className={`${styles.FormGroupFull}`}>
              <label className={`${styles.FormLabel}`}>Title*</label>
              <input {...register("title")} className={`${styles.FormControl}`} />
              {errors.title && !watch().title && <span className={`${styles.ErrorM}`}>{errors.title.message}</span>}
            </div>
          </div>
          <div className={`${styles.FormButtonArea}`}>
            <Link onClick={modalClose} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
            <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
          </div>
        </form>
      </div>}
    </React.Fragment>
  )}


export default CategoryAddForm;