import React, { useEffect, useState } from 'react';
import moment from 'moment';

export const ComponentToPrintList = React.forwardRef((props, ref) => {
  const [allData,setAlldata] = useState([]);
  
  useEffect(()=>{
    if(props?.data?.length){
      let totalData = props?.data;

      let dataReducer = (totalData, key) => 
        Object.values(totalData.reduce((newItem,item)=>{
          let value = item[key]?.id;
          newItem[value] = newItem[value] ||  {'diver_id':0, 'diver_name':'','bookings':[]};

          newItem[value].diver_id = item[key]?.id;
          newItem[value].diver_name = item[key]?.name;

          newItem[value].bookings.push({booking_id: item?.reference_id, postcode: item?.post_code, address: item?.pickup_details?.address, customer_name: item?.pickup_details?.name, phone: item?.pickup_details?.phone, pickup_date: item?.pickup_date, notes: ''})

          return newItem;
        },{})
      );

      setAlldata(dataReducer(totalData,'driver'));
        
    }
  },[props?.data]);


  return (<div ref={ref}>
    {allData?.map(item=>{
      return (<div className={`printCardNew`} style={{padding: '20px 20px'}}>
        <div style={{fontSize:'16px', fontWeight: 'bold', marginBottom:'10px'}}>Driver Name: {item?.diver_name}</div>
        <table width={'100%'}>
          <tr>
            <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Booking Id</th>
            <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Postcode</th>
            <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Customer Address</th>
            <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Customer Name</th>
            <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Customer Phone</th>
            <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Pickup Date</th>
            <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Pickup Notes</th>
          </tr>
          {item?.bookings?.map(row=>{
            return <tr>
            <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.booking_id}</td>
            <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.postcode}</td>
            <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.address}</td>
            <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.customer_name}</td>
            <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.phone}</td>
            <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{moment(row?.pickup_date).format('DD/MM/YYYY')}</td>
            <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>&nbsp;</td>
            </tr>
          })}
        </table>
      </div>)
    })}
    
  </div>);
  
});