import React from 'react';
import styles from './booking.module.css';
import { Trash2 } from 'react-feather';
import "react-multi-carousel/lib/styles.css";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import {FormControlLabel, Checkbox} from '@mui/material';
import { useForm, Controller,useFieldArray } from "react-hook-form";
import { NumericFormat } from 'react-number-format';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const brandColor = '#CCCCCC';
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
    }
  })
};

export default function Step1({saveStep1Data, bookingData,deliveryAllProducts,allProducts}){
  const [isRegCompany,setIsRegCompany] = React.useState({value: "No", label: "No"});
  const [isDeliveryProducts,setIsDeliveryProducts] = React.useState(false);
  const [productDDList,setProductDDList] = React.useState([]);
  const [deliveryProductDDList,setDeliveryDDProductList] = React.useState([]);
  const [DProductList,setDProductList] = React.useState([]);
  const [ProductList,setProductList] = React.useState([]);
  const [DSelectedCat,setDSelectedCat] = React.useState([]);
  const [SelectedCat,setSelectedCat] = React.useState([]);
  const [SubTotal,setSubTotal] = React.useState(0);
  const [DeliveryTotal,setDeliveryTotal] = React.useState(0);
  const [Total,setTotal] = React.useState(0);
  const [NoOfItems, setNoOfItems] = React.useState(0);
  const VATCharge = 0;
  const InsuranceCharge = 0;

  const { register, handleSubmit,control, formState: { errors }, getValues, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      registered_company: {value: "No", label: "No"},
      delivery_products: bookingData?.delivery_products ? bookingData?.delivery_products : [{ category_id: '', item_: '', quantity: '', total:'' }],
      products: bookingData?.products ? bookingData?.products : [{ category_id: '', item: '', est_amount: '', total:'', is_fragile: false  }]
    }
  });

  const { fields: fields1, append: append1, remove: remove1 } = useFieldArray({
    control,
    name: "delivery_products"
  });

  const { fields: fields2, append: append2, remove: remove2 } = useFieldArray({
    control,
    name: "products"
  });

  React.useState(()=>{
    setValue(`registered_company`,bookingData?.registered_company);
    setValue(`insurance`,bookingData?.insurance);
    setValue(`notes`,bookingData?.notes);
    if(bookingData?.registered_company?.value === "Yes"){
      setValue(`company_name`,bookingData?.company_name);
      setValue(`company_shipping`,bookingData?.company_shipping);
    }
    setIsRegCompany(bookingData?.registered_company);
    setValue(`is_delivery`,bookingData?.is_delivery);
    setIsDeliveryProducts(bookingData?.is_delivery);
    setValue(`pickup_date`,moment(bookingData?.pickup_date).toDate());

    let deliverytotalTemp = 0;
    let subtotalTemp = 0;
    let noOfItemsTemp = 0;
    let DSelectedCatTemp = [];
    let SelectedCatTemp = [];
    bookingData?.delivery_products?.forEach(item=>{
      if(item.total !== ''){
        deliverytotalTemp += parseFloat(item.total);
        DSelectedCatTemp.push(item?.category_id?.value);
      }
    });
    bookingData?.products?.forEach(item=>{
      subtotalTemp += parseFloat(item.total);
      noOfItemsTemp += 1;
      SelectedCatTemp.push(item?.category_id?.value);
    });

    let DProductListTemp = [];

    if(bookingData?.delivery_products){
      DProductListTemp = bookingData?.delivery_products?.map((item)=>{
        if(item?.category_id?.no_of_child > 0){
          let deliveryProductTemp = deliveryAllProducts.filter(i=> i.parent_id === item?.category_id?.value);
          deliveryProductTemp =  deliveryProductTemp.map(i=> { 
            return {value: i.id, label: i.title, amount:i.amount}; 
          });
          return deliveryProductTemp;
        }
        return [];
      });
    }

    let ProductListTemp = [];

    if(bookingData?.products){
      ProductListTemp = bookingData?.products?.map((item)=>{
        if(item?.category_id?.no_of_child > 0){
          let productTemp = allProducts.filter(i=> i.parent_id === item?.category_id?.value);
          productTemp =  productTemp.map(i=> { 
            return {value: i.id, label: i.title, amount:i.amount}; 
          });
          return productTemp;
        }
        return [];
      });
    }

    setDProductList(DProductListTemp);
    setProductList(ProductListTemp);

    setDeliveryTotal(deliverytotalTemp);
    setSelectedCat(SelectedCatTemp);
    setDSelectedCat(DSelectedCatTemp);
    setNoOfItems(noOfItemsTemp);
    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);

  },[bookingData,deliveryAllProducts,allProducts]);

  React.useEffect(()=>{
    let productCatList = deliveryAllProducts.filter(i=> i.parent_id === 0);
        productCatList = productCatList.map(i=>{
          return {value: i.id, label: i.title, amount:i.amount, no_of_child: i.no_of_child };
        });
        productCatList.push({id:0, value:999, label:'Other'})
        setDeliveryDDProductList(productCatList);
  },[deliveryAllProducts]);

  React.useEffect(()=>{
    let productCatList = allProducts.filter(i=> i.parent_id === 0);
        productCatList = productCatList.map(i=>{
          return {value: i.id, label: i.title, amount:i.amount, no_of_child: i.no_of_child };
        });
        productCatList.push({id:0, value:999, label:'Other'});
        setProductDDList(productCatList);
  },[allProducts]);

  const onSubmit = (data) =>{
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
    saveStep1Data(data);
  }

  const selectDCategory = (e,index) =>{
    if(e?.value !== 999){
      if(e.no_of_child === 0){
        setValue(`delivery_products[${index}].item`, e);
        setValue(`delivery_products[${index}].quantity`, 1);
        setValue(`delivery_products[${index}].total`, e?.amount);
      }
    }

    let categoryListTemp = getValues(`delivery_products`);
    let DProductListTemp = categoryListTemp.map((item)=>{
      if(item?.category_id?.no_of_child > 0){
        let deliveryProductTemp = deliveryAllProducts.filter(i=> i.parent_id === item?.category_id?.value);
        deliveryProductTemp =  deliveryProductTemp.map(i=> { 
          return {value: i.id, label: i.title, amount:i.amount}; 
        });
        return deliveryProductTemp;
      }
      return [];
    });
    let DSelectedCatTemp = categoryListTemp.map((item)=>{
      return item?.category_id?.value;
    });
    setDSelectedCat(DSelectedCatTemp);
    setDProductList(DProductListTemp);
  }

  const selectCategory = (e,index) =>{
    if(e?.value !== 999){
      if(e.no_of_child === 0){
        setValue(`products[${index}].item`, e);
        setValue(`products[${index}].est_amount`, e?.amount);
        setValue(`products[${index}].total`, e?.amount);
      }
    }
    let categoryListTemp = getValues(`products`);
    let ProductListTemp = categoryListTemp.map((item)=>{
      if(item?.category_id?.no_of_child > 0){
        let productTemp = allProducts.filter(i=> i.parent_id === item?.category_id?.value);
        productTemp =  productTemp.map(i=> { 
          return {value: i.id, label: i.title, amount:i.amount}; 
        });
        return productTemp;
      }
      return [];
    });
    let SelectedCatTemp = categoryListTemp.map((item)=>{
      return item?.category_id?.value;
    });
    setSelectedCat(SelectedCatTemp);
    setProductList(ProductListTemp);
  }

  const selectDItem = (e,index)=>{
    setValue(`delivery_products[${index}].quantity`, 1);
    setValue(`delivery_products[${index}].total`, e.amount);

    let subtotalTemp = 0;
    let noOfItemsTemp = 0;
    let deliverytotalTemp = 0;
    getValues(`delivery_products`).forEach(item=>{
      if(item.total !== ''){
        deliverytotalTemp += parseFloat(item.total);
      }
    });
    getValues(`products`).forEach(item=>{
      if(item.total !== ''){
        subtotalTemp += parseFloat(item.total);
        noOfItemsTemp += 1;
      }
    });
    setDeliveryTotal(deliverytotalTemp);
    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);
    setNoOfItems(noOfItemsTemp);
  }
  
  const selectItem = (e,index)=>{
    setValue(`products[${index}].est_amount`, e.amount);
    setValue(`products[${index}].total`, e.amount);

    let deliverytotalTemp = 0;
    let subtotalTemp = 0;
    let noOfItemsTemp = 0;
    getValues(`delivery_products`).forEach(item=>{
      if(item.total !== ''){
        deliverytotalTemp += parseFloat(item.total);
      }
    });
    getValues(`products`).forEach(item=>{
      if(item.total !== ''){
        subtotalTemp += parseFloat(item.total);
        noOfItemsTemp += 1;
      }
    });
    setDeliveryTotal(deliverytotalTemp);
    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);
    setNoOfItems(noOfItemsTemp);
  }

  const changeItemDet = (e)=>{
    let deliverytotalTemp = 0;
    let subtotalTemp = 0;
    let noOfItemsTemp = 0;
    getValues(`delivery_products`).forEach(item=>{
      if(item.total !== ''){
        deliverytotalTemp += parseFloat(item.total);
      }
    });
    getValues(`products`).forEach(item=>{
      if(item.total !== ''){
        subtotalTemp += parseFloat(item.total);
        noOfItemsTemp += 1;
      }
    });
    setDeliveryTotal(deliverytotalTemp);
    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);
    setNoOfItems(noOfItemsTemp);
  }

  const changeDQuantity = (e,index) => {
    if(parseInt(e.target.value) > 0 && typeof(getValues(`delivery_products[${index}].item`)) === 'object'){
      let quantity = parseInt(e.target.value);
      let amount = getValues(`delivery_products[${index}].item`)?.amount;

      let subtotalTemp = SubTotal;
      let deliverytotalTemp = DeliveryTotal;
      deliverytotalTemp -= getValues(`delivery_products`)[index]?.total;
      deliverytotalTemp += quantity*amount;

      setDeliveryTotal(deliverytotalTemp);
      setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);

      let noOfItemsTemp = 0;
      let products = getValues(`products`);
      products.forEach(item=>{
        if(item.item !== ''){
          noOfItemsTemp += 1;
        }
      })
      setNoOfItems(noOfItemsTemp);

      setValue(`delivery_products[${index}].total`, quantity*amount);

    }
  }

  const delDItems = (index)=>{
    if(fields1.length <=1) 
      return false;

    let subtotalTemp = SubTotal;
    let deliverytotalTemp = DeliveryTotal;
    deliverytotalTemp -= getValues(`delivery_products`)[index]?.total;

    setDeliveryTotal(deliverytotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);

    remove1(index);

    let categoryList = getValues(`delivery_products`);
    let DProductListTemp = categoryList.map((item)=>{
      if(item?.category_id?.no_of_child > 0){
        let deliveryProductTemp = deliveryAllProducts.filter(i=> i.parent_id === item?.category_id?.value);
        deliveryProductTemp =  deliveryProductTemp.map(i=> { 
          return {value: i.id, label: i.title, amount:i.amount}; 
        });
        return deliveryProductTemp;
      }
      return [];
    });
    let DSelectedCatTemp = categoryList.map((item)=>{
      return item?.category_id?.value;
    });
    setDSelectedCat(DSelectedCatTemp);
    setDProductList(DProductListTemp);
  }

  const delItems = (index)=>{
    if(fields2.length <=1) 
      return false;

    let subtotalTemp = SubTotal;
    let deliverytotalTemp = DeliveryTotal;
    subtotalTemp -= getValues(`products`)[index]?.total;
    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp+VATCharge+InsuranceCharge+deliverytotalTemp);

    remove2(index);

    let categoryListTemp = getValues(`products`);
    let ProductListTemp = categoryListTemp.map((item)=>{
      if(item?.category_id?.no_of_child > 0){
        let productTemp = allProducts.filter(i=> i.parent_id === item?.category_id?.value);
        productTemp =  productTemp.map(i=> { 
          return {value: i.id, label: i.title, amount:i.amount}; 
        });
        return productTemp;
      }
      return [];
    });
    let SelectedCatTemp = categoryListTemp.map((item)=>{
      return item?.category_id?.value;
    });
    setSelectedCat(SelectedCatTemp);
    setProductList(ProductListTemp);
  }

  return (<>
  <p className={`${styles.StepBoxTitle} ${styles.Heading}`}>MY PACKAGE DETAILS</p>

  <form id="step1-form" onSubmit={handleSubmit(onSubmit)}>

    <div className={`${styles.FormRow1}`}>
      <div className={`${styles.FormRow1Half}`}>
        <div className={`${styles.FormField}`}>
          <label>Do you have a registered company in UK?</label>
          <Controller
            control={control}
            options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}
            {...register('registered_company',{required:'Please select one'})}
            render={({ field }) => (
              <Select {...field} onChange={(e)=>{field.onChange(e); setIsRegCompany(e);}} options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}  className={`${styles.CustomSelect}`}  />
            )}
          />
          {errors.registered_company && <p className={`${styles.ErrorM}`}>{errors.registered_company.message}</p>}
        </div>
        {(isRegCompany?.value === 'Yes') && <><div className={`${styles.FormField}`}>
          <label>If Yes, please mention the name of the company</label>
          <input type="text" placeholder="" className={`${styles.TextField}`} {...register('company_name',{required:true})} />
          {errors.company_name && <p className={`${styles.ErrorM}`}>Please enter company name</p>}
        </div>
        <div className={`${styles.FormField}`}>
          <label>If Yes, are you shipping the goods in the name of the company?</label>
          <Controller
            control={control}
            options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}
            {...register('company_shipping',{required:true})}
            render={({ field }) => (
              <Select {...field} options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"}]}  className={`${styles.CustomSelect}`}  />
            )}
          />
          {errors.company_shipping && <p className={`${styles.ErrorM}`}>Please select one</p>}
        </div></>}
      </div>

      <div className={`${styles.FormRow1Half}`}>
        <div className={`${styles.FormField}`}>
          <label>Have you got insurance?</label>
          <Controller
            control={control}
            options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"},{value: "Not Interested", label: "I am not interested"}]}
            {...register('insurance',{required:'Please select one'})}
            render={({ field }) => (
              <Select {...field} options={[{value: "Yes", label: "Yes"},{value: "No", label: "No"},{value: "Not Interested", label: "I am not interested"}]}  className={`${styles.CustomSelect}`}  />
            )}
          />
          {errors.insurance && <p className={`${styles.ErrorM}`}>{errors.insurance.message}</p>}
        </div>
      </div>
    </div>

    <div className={`${styles.BookADelivery}`}>
      <FormControlLabel control={<Checkbox defaultChecked={isDeliveryProducts} {...register('is_delivery')} onChange={(e)=>setIsDeliveryProducts(e.target.checked)} />} label="Book a Delivery" className={`${styles.CheckBox} CheckBox`} />

      {isDeliveryProducts && <div className={`${styles.BookADeliveryCardArea}`}>
        {fields1.map((item, index) => {
          return (
            <div className={`${styles.BookDeliveryCard}`}  key={item.id} style={{background:'#F9DAD0'}}>
              <div className={`${styles.BookDeliveryCardUnder}`}>
                <div className={`${styles.BookDeliveryCardUnderRow}`}>
                  <div className={`${styles.BookDeliveryFormCard30}`}>
                    <div className={`${styles.FormField}`}>
                      <label>CATEGORY</label>
                      <Controller
                        key={item.id}
                        control={control}
                        {...register(`delivery_products[${index}].category_id`,{required:true})}
                        render={({ field }) => (
                          <Select {...field} onChange={(e)=>{field.onChange(e); selectDCategory(e,index)}} styles={customStyles} options={deliveryProductDDList} className={`${styles.CustomSelect}`}  />
                        )}
                      />
                      {(errors?.delivery_products?.length > 0 && errors?.delivery_products[index]?.category_id) && <p className={`${styles.ErrorM}`}>Please select category</p>}
                    </div>
                  </div>
                  <div className={`${styles.BookDeliveryFormCard30}`}>
                    {(DSelectedCat[index] && DSelectedCat[index] === 999) ? <div className={`${styles.FormField}`}>
                      <label>ITEM</label>
                      <input type="text" className={`${styles.TextField}`} {...register(`delivery_products[${index}].item`,{required: (DSelectedCat[index] && DSelectedCat[index] === 999)})} onChange={(e)=>changeItemDet(e)} />
                      {(errors?.delivery_products?.length > 0 && errors?.delivery_products[index]?.item) && <p className={`${styles.ErrorM}`}>Please enter item name</p>}
                      </div>:((DProductList[index] && DProductList[index].length > 0) && <div className={`${styles.FormField}`}>
                        <label>ITEM</label>
                        <Controller
                          key={item.id}
                          control={control}
                          options={(DProductList[index] && DProductList[index].length > 0)?DProductList[index] : []}
                          {...register(`delivery_products[${index}].item`,{required: !(DSelectedCat[index] && DSelectedCat[index] === 999)})}
                          render={({ field }) => (
                            <Select {...field} onChange={(e)=>{field.onChange(e); selectDItem(e,index)}} styles={customStyles} options={(DProductList[index] && DProductList[index].length > 0)?DProductList[index] : []}  className={`${styles.CustomSelect}`}  />
                          )}
                        />
                        {(errors?.delivery_products?.length > 0 && errors?.delivery_products[index]?.item) && <p className={`${styles.ErrorM}`}>Please select item</p>}
                    </div>)}
                  </div>
                  <div className={`${styles.BookDeliveryFormCard20}`}>
                    <div className={`${styles.FormField}`}>
                      <label>QUANTITY</label>
                      <Controller
                       key={item.id}
                        control={control}
                        {...register(`delivery_products[${index}].quantity`,{required:true})}
                        render={({ field }) => (
                          <NumericFormat {...field } onChange={(e)=>{field.onChange(e); changeDQuantity(e,index); changeItemDet(e);}} className={`${styles.TextField}`} allowLeadingZeros={false} allowNegative={false} decimalScale={0} />
                        )}
                      />
                      {(errors?.delivery_products?.length > 0 && errors?.delivery_products[index]?.quantity) && <p className={`${styles.ErrorM}`}>Please enter quantity</p>}
                    </div>
                  </div>
                  <div className={`${styles.BookDeliveryFormCard20}`}>
                    <div className={`${styles.FormField}`}>
                      <label>TOTAL</label>
                      <Controller
                       key={item.id}
                        control={control}
                        {...register(`delivery_products[${index}].total`,{required:(DSelectedCat[index] && DSelectedCat[index] === 999)})}
                        render={({ field }) => (
                          <NumericFormat {...field } className={`${styles.TextField}`} allowLeadingZeros={false} allowNegative={false} decimalScale={2} readOnly={!(DSelectedCat[index] && DSelectedCat[index] === 999)}  onChange={(e)=>{field.onChange(e); changeItemDet(e);}} />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button className={`${styles.CardTrashBU}`}  type="button" onClick={delDItems.bind(this,index)}><Trash2/></button>
            </div>
          );
        })}
        <button type='button' className={`${styles.HomeBU}`} onClick={() => { append1({ category_id: '', item: '', quantity: '', total:'' }); }}>Add Items</button>
      </div>}
    </div>

    <div className={`${styles.BookADelivery}`}>

      <div className={`${styles.BookADeliveryCardArea}`}>
      {fields2.map((item, index) => {
        return (<div className={`${styles.BookDeliveryCard}`} key={item.id} style={{background:'#D1E7FC'}}>
          <div className={`${styles.BookDeliveryCardUnder}`}>
            <div className={`${styles.BookDeliveryCardUnderRow}`}>

              <div className={`${styles.BookDeliveryFormCard25}`}>
                <div className={`${styles.FormField}`}>
                  <label>CATEGORY</label>
                  <Controller
                    key={item.id}
                    control={control}
                    options={productDDList}
                    {...register(`products[${index}].category_id`,{required:true})}
                    render={({ field }) => (
                      <Select {...field} onChange={(e)=>{field.onChange(e); selectCategory(e,index)}} styles={customStyles} options={productDDList} className={`${styles.CustomSelect}`}  />
                    )}
                  />
                  {(errors?.products?.length > 0 && errors?.products[index]?.category_id) && <p className={`${styles.ErrorM}`}>Please select category</p>}
                </div>
              </div>
              <div className={`${styles.BookDeliveryFormCard25}`}>
                {(SelectedCat[index] && SelectedCat[index] === 999) ? <div className={`${styles.FormField}`}>
                  <label>ITEM</label>
                  <input type="text" key={item.id} className={`${styles.TextField}`} {...register(`products[${index}].item`,{required: (SelectedCat[index] && SelectedCat[index] === 999)})} onChange={(e)=>changeItemDet(e)} />
                  {(errors?.products?.length > 0 && errors?.products[index]?.item) && <p className={`${styles.ErrorM}`}>Please enter item name</p>}
                </div>:((ProductList[index] && ProductList[index].length > 0) && <div className={`${styles.FormField}`}>
                  <label>ITEM</label>
                  <Controller
                    key={item.id}
                    control={control}
                    options={(ProductList[index] && ProductList[index].length > 0)?ProductList[index] : []}
                    {...register(`products[${index}].item`,{required: (!(SelectedCat[index] && SelectedCat[index] === 999))})}
                    render={({ field }) => (
                        <Select {...field} onChange={(e)=>{field.onChange(e); selectItem(e,index)}} styles={customStyles} options={(ProductList[index] && ProductList[index].length > 0)?ProductList[index] : []}  className={`${styles.CustomSelect}`}  />
                    )}
                  />
                  {(errors?.products?.length > 0 && errors?.products[index]?.item) && <p className={`${styles.ErrorM}`}>Please select item</p>}
                </div>)}                
              </div>
              <div className={`${styles.BookDeliveryFormCard10}`}>
                <div className={`${styles.FormField}`}>
                  <label>TYPE</label>
                  {/*<FormControlLabel label="Fragile?" className={`${styles.CheckBox} CheckBox`} control={
                    <Checkbox 
                      key={item.id}
                      control={control}
                      {...register(`products[${index}].is_fragile`)}
                    />
                  } />*/}
                  <FormControlLabel label="Fragile?" className={`${styles.CheckBox} CheckBox`} control={
                    <Controller
                      key={item.id}
                      control={control}
                      {...register(`products[${index}].is_fragile`)}
                      render={({ field }) => (
                          <Checkbox {...field} defaultChecked={field.value} />
                      )}
                    />
                  } />
                </div>
              </div>
              <div className={`${styles.BookDeliveryFormCard20}`}>
                <div className={`${styles.FormField}`}>
                  <label>ESTIMATED VALUE</label>
                  <Controller
                    key={item.id}
                    control={control}
                    {...register(`products[${index}].est_amount`,{required: (SelectedCat[index] && SelectedCat[index] === 999)})}
                    render={({ field }) => (
                      <NumericFormat {...field } className={`${styles.TextField}`} allowLeadingZeros={false} allowNegative={false} decimalScale={2} readOnly={!(SelectedCat[index] && SelectedCat[index] === 999)} onChange={(e)=>{field.onChange(e); changeItemDet(e);}} />
                    )}
                  />
                  {(errors?.products?.length > 0 && errors?.products[index]?.est_amount) && <p className={`${styles.ErrorM}`}>Please enter estimated amount</p>}
                </div>
              </div>
              <div className={`${styles.BookDeliveryFormCard20}`}>
                <div className={`${styles.FormField}`}>
                  <label>AMOUNT TO PAY</label>
                  <Controller
                    key={item.id}
                    control={control}
                    {...register(`products[${index}].total`,{required:(SelectedCat[index] && SelectedCat[index] === 999)})}
                    render={({ field }) => (
                      <NumericFormat {...field } className={`${styles.TextField}`} allowLeadingZeros={false} allowNegative={false} decimalScale={2} readOnly={!(SelectedCat[index] && SelectedCat[index] === 999)} onChange={(e)=>{field.onChange(e); changeItemDet(e);}} />
                    )}
                  />
                  {(errors?.products?.length > 0 && errors?.products[index]?.total) && <p className={`${styles.ErrorM}`}>Please enter total amount</p>}
                </div>
              </div>
              {/*<div className={`${styles.BookDeliveryFormCard100}`}>
                
                <div className={`${styles.UploadImage}`}>
                  <div className={`${styles.UploadImageArea}`}>
                    <div className={`${styles.UploadImageAreaU}`}>
                      <UploadCloud/>
                      <p>Upload an image</p>
                    </div>
                  </div>
                  <div className={`${styles.UploadImageArea}`}>
                    <div className={`${styles.UploadImageUS}`}>
                      <img src="/images/banner_img_1.jpg" alt='bannerimg3' />
                      <button><X/></button>
                    </div>
                  </div>
                </div>

              </div>*/}

            </div>
          </div>
          <button className={`${styles.CardTrashBU}`}  type="button" onClick={delItems.bind(this,index)}><Trash2/></button>
        </div>);
        })}
        
        <button className={`${styles.HomeBU}`} type='button' onClick={() => { append2({ category_id: '', item: '', est_amount: '', total:'', is_fragile: false  }); }}>Add Items</button>
      </div>

    </div>

    <div className={`${styles.BookingNotesArea}`}>

      <div className={`${styles.BookingNotesSec65}`}>
        <div className={`${styles.FormField2}`}>
          <label>Pickup Date<span>*</span></label>
          <Controller
            control={control}
            {...register('pickup_date',{required:'Please select pick up date'})}
            render={({ field }) => (
              <DatePicker
                {...field}
                autoComplete='off'
                minDate={moment().toDate()}
                selected={field.value}
                className={`${styles.TextField}`}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => field.onChange(date)}
                ref={(ref) => {
                  field.ref({
                    focus: ref?.setFocus
                  });
                }}
              />
            )}
          />
          {(errors.pickup_date) && <p className={`${styles.ErrorM}`}>{errors.pickup_date.message}</p>}
        </div>
        <div className={`${styles.FormField2}`}>
          <label>Booking Notes</label>
          <textarea className={`${styles.TextField}`} {...register('notes')}></textarea>
        </div>
        <FormControlLabel control={<Checkbox {...register('is_accept',{required:true})} />} label="I have read and agree to the Prohibited & Restricted items list*" className={`${styles.CheckBox} CheckBox`} />
        {(errors?.is_accept) && <p className={`${styles.ErrorM}`}>Please accept terms & condition</p>}
      </div>

      <div className={`${styles.BookingNotesSec35}`}>
        <ul className={`${styles.CostUL}`}>
          <li>
            <p className={`${styles.CostTitle}`}>No of Items:</p>
            <p className={`${styles.CostNo}`}>{NoOfItems}</p>
          </li>
          <li>
            <p className={`${styles.CostTitle}`}>Order Subtotal:</p>
            <p className={`${styles.CostNo}`}>£{SubTotal}</p>
          </li>
          <li>
            <p className={`${styles.CostTitle}`}>Total VAT:</p>
            <p className={`${styles.CostNo}`}>£{VATCharge}</p>
          </li>
          <li>
            <p className={`${styles.CostTitle}`}>Insurance Charges:</p>
            <p className={`${styles.CostNo}`}>£{InsuranceCharge}</p>
          </li>
          {isDeliveryProducts && <li>
            <p className={`${styles.CostTitle}`}>Delivery Total:</p>
            <p className={`${styles.CostNo}`}>£{DeliveryTotal}</p>
          </li>}
          <li>
            <p className={`${styles.CostTitle} ${styles.Big}`}>Total:</p>
            <p className={`${styles.CostNo} ${styles.Big}`}>£{Total}</p>
          </li>
        </ul>
      </div>

    </div>
  </form>
  </>)
}