import React, { useEffect } from 'react';
import styles from './list.module.css';
import { Breadcrumbs, Button, TextField, Typography  } from '@mui/material';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { TableLoader } from  '../../components/LoaderC';
import {authData} from "../../components/getAuth";
import moment from 'moment';
import withAdminAuth from "../../components/withAdminAuth";
import { X } from 'react-feather';
import { DateRangePicker } from 'rsuite';
import { ComponentToPrint } from './ComponentToPrint';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

const DriverReport = () => {
  const auth = authData();
  const [contentLoading, setContentLoading] = React.useState(false);
  const [allData, setAllData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [filterText, setFilterText] = React.useState('');
  const [filterDate, setFilterDate] = React.useState(null);
  const componentRef1 = [];
  const componentRef2 = [];
  const componentRef3 = [];
  const componentRef4 = [];
  const componentRef5 = [];
  
  const columns = [{
    name: 'Driver Id',
    selector: row =>  row?.driver_id,
    sortable: true
  },{
    name: 'Driver Name',
    selector: row => row?.name,
    sortable: true
  },{
    name: 'Total Assigned',
    cell: (tableProps, index)  => (tableProps?.assigned_bookings?.length ? <>
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={el => (componentRef1[index] = el)} driverName={tableProps?.name} bookingData={tableProps?.assigned_bookings} />
      </div>
      <ReactToPrint content={() => componentRef1[index]}>
        <PrintContextConsumer>{({ handlePrint }) => ( <Button onClick={handlePrint}>{tableProps?.assigned_bookings?.length}</Button> )}</PrintContextConsumer>
      </ReactToPrint>
    </>: <><Button>0</Button></> ),
    sortable: true
  },{
    name: 'Confirmed Pickup',
    cell: (tableProps, index)  => (tableProps?.assigned_bookings.filter(i=> i?.is_picked === 1)?.length ? <>
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={el => (componentRef2[index] = el)} driverName={tableProps?.name} bookingData={tableProps?.assigned_bookings.filter(i=> i?.is_picked === 1)} />
      </div>
      <ReactToPrint content={() => componentRef2[index]}>
        <PrintContextConsumer>{({ handlePrint }) => ( <Button onClick={handlePrint}>{tableProps?.assigned_bookings.filter(i=> i?.is_picked === 1)?.length}</Button> )}</PrintContextConsumer>
      </ReactToPrint>
    </>: <><Button>0</Button></> ),
    sortable: true
  },{
    name: 'Failed Pickup',
    cell: (tableProps, index)  => (tableProps?.assigned_bookings.filter(i=> i?.is_cancel === 1)?.length ? <>
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={el => (componentRef3[index] = el)} driverName={tableProps?.name} bookingData={tableProps?.assigned_bookings.filter(i=> i?.is_cancel === 1)} />
      </div>
      <ReactToPrint content={() => componentRef3[index]}>
        <PrintContextConsumer>{({ handlePrint }) => ( <Button onClick={handlePrint}>{tableProps?.assigned_bookings.filter(i=> i?.is_cancel === 1)?.length}</Button> )}</PrintContextConsumer>
      </ReactToPrint>
    </>: <><Button>0</Button></> ),
    sortable: true
  },{
    name: 'Total Unpaid',
    cell: (tableProps, index)  => (tableProps?.assigned_bookings.filter(i=> i?.is_picked === 1 && parseInt(i?.payment_status) === 0)?.length ? <>
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={el => (componentRef4[index] = el)} driverName={tableProps?.name} bookingData={tableProps?.assigned_bookings.filter(i=> i?.is_picked === 1 && parseInt(i?.payment_status) === 0)} />
      </div>
      <ReactToPrint content={() => componentRef4[index]}>
        <PrintContextConsumer>{({ handlePrint }) => ( <Button onClick={handlePrint}>{tableProps?.assigned_bookings.filter(i=> i?.is_picked === 1 && parseInt(i?.payment_status) === 0)?.length}</Button> )}</PrintContextConsumer>
      </ReactToPrint>
    </>: <><Button>0</Button></> ),
    sortable: true
  },{
    name: 'Total Paid',
    cell: (tableProps, index)  => (tableProps?.assigned_bookings.filter(i=> i?.is_picked === 1 && parseInt(i?.payment_status) === 1)?.length ? <>
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={el => (componentRef5[index] = el)} driverName={tableProps?.name} bookingData={tableProps?.assigned_bookings.filter(i=> i?.is_picked === 1 && parseInt(i?.payment_status) === 1)} />
      </div>
      <ReactToPrint content={() => componentRef5[index]}>
        <PrintContextConsumer>{({ handlePrint }) => ( <Button onClick={handlePrint}>{tableProps?.assigned_bookings.filter(i=> i?.is_picked === 1 && parseInt(i?.payment_status) === 1)?.length}</Button> )}</PrintContextConsumer>
      </ReactToPrint>
    </>: <><Button>0</Button></> ),
    sortable: true
  },{
    name: 'Unpaid Amount',
    cell: row => <>&pound; {row?.assigned_bookings.filter(i=> i?.is_picked === 1).reduce((accumulator, current) => accumulator + (current.total - current.paid_amount), 0)?.toFixed(2)}</>,
    sortable: true
  },{
    name: 'Paid Amount',
    cell: row => <>&pound; {row?.assigned_bookings.filter(i=> i?.is_picked === 1).reduce((accumulator, current) => accumulator + current.paid_amount, 0)?.toFixed(2)}</>,
    sortable: true
  },{
    name: 'Grand Total',
    cell: row => <>&pound; {row?.assigned_bookings.filter(i=> i?.is_picked === 1).reduce((accumulator, current) => accumulator + current.total, 0)?.toFixed(2)}</>,
    sortable: true
  }];

  const customDataTableStyles = {
    headRow: {
      style: {
          backgroundColor: '#1e1e2d',
          color: '#fff'
      },
    },
    head: {
      style: {
        zIndex:0
      },
    },
    headCells: {
      style: {
          backgroundColor: '#1e1e2d',
          color: '#fff'
      },
    },
  }

  useEffect(()=>{
    try {
      setContentLoading(true);
      axios(process.env.REACT_APP_API_URL + 'v1/driver-report', {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setContentLoading(false);
        if(response.data.status === 'SUCCESS'){
          let allData = response.data.data;
          setAllData(allData);
        }
      });
    } catch (error) {
      setContentLoading(false);
    }
  },[auth?.api_token]);

  useEffect(()=>{
    let allDataTemp = allData;

    if (filterText !== '') {
      let inputVal = filterText;
      inputVal = inputVal.toLowerCase();

      allDataTemp = allDataTemp.filter((item) => {
        return item?.driver_id.toLowerCase().includes(inputVal) || item?.name.toLowerCase().includes(inputVal);
      });
    }

    if(filterDate){
      allDataTemp = allDataTemp.map(item=>{
        let bookings = item?.assigned_bookings.filter((i) => moment(i.pickup_date).isBetween(moment(filterDate[0]),moment(filterDate[1]), 'days', '[]'));

        return {...item,assigned_bookings: bookings};
      });
    }

    setData(allDataTemp);    
  },[allData,filterText,filterDate]);

  return (<>
    {contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>}
    {!contentLoading && <div className={`${styles.MainDiv}`}>
        <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>Driver Reports</p>
          <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
            <Typography color="text.primary">Bookings</Typography>
            <Typography color="text.primary">Driver Reports</Typography>
          </Breadcrumbs>
        </div>
        
        <div className={`${styles.MainCard}`}>
        <div className='TableFilterHead'>
          <div className="DataTableSearch">
            <TextField id="search" type="text" placeholder="Search" aria-label="Search Input" value={filterText} onChange={(e)=>setFilterText(e.target.value)} autoComplete='off' />
            {filterText && <Button type="button"  className="CloseBU" onClick={(e)=>setFilterText('')}><X /></Button>}
          </div>
          <div style={{width:'320px', marginLeft:'16px'}}>
              <DateRangePicker
                showOneCalendar
                format="dd/MM/yyyy"
                placeholder="Search by date"
                ranges={[]}
                onChange={(e)=>setFilterDate(e)}
              /> 
            </div>
        </div>
        
        <div className='TableContent'>
          <DataTable
            className='DataTable'
            columns={columns}
            data={data}
            pagination
            customStyles={customDataTableStyles}
          />
        </div>
      </div>
    </div>}
  </>);
}

export default withAdminAuth(DriverReport);