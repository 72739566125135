import React from 'react';
import styles from './booking.module.css';
import { Plus, Trash2 } from 'react-feather';
import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller,useFieldArray } from "react-hook-form";
import {isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-google-autocomplete";
import MapComponent from '../../components/Map';

export default function Step2({saveStep2Data, bookingData}){
  const [mapCenter, setMapCenter] = React.useState({ lat: 51.509865, lng: -0.118092 });
  const [isMarker, setIsMarker] = React.useState(false);
  const [zoom, setZoom] = React.useState(12);
  const { register, handleSubmit,control, formState: { errors },setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      alt_contact_nos: bookingData?.pickUpData?.alt_contact_nos ? bookingData?.pickUpData?.alt_contact_nos : [],
    }
  });

  const { fields: fields1, append: append1, remove: remove1 } = useFieldArray({
    control,
    name: "alt_contact_nos"
  });

  React.useState(()=>{
    setValue(`name_title`,bookingData?.pickUpData?.name_title);
    setValue(`name`,bookingData?.pickUpData?.name);
    setValue(`address`,bookingData?.pickUpData?.address);
    setValue(`post_code`,bookingData?.pickUpData?.post_code);
    if(bookingData?.pickUpData?.email){
      setValue(`email`,bookingData?.pickUpData?.email);
    }else if(bookingData?.keyType === 'email'){
      setValue(`email`,bookingData?.keyValue);
    }
    if(bookingData?.pickUpData?.phone){
      setValue(`phone`,bookingData?.pickUpData?.phone);
    }else if(bookingData?.keyType === 'phone'){
      setValue(`phone`,bookingData?.keyValue);
    }
  },[bookingData]);

  const onSubmit = (data)=>{
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
    saveStep2Data(data);
  }

  return <>
  <p className={`${styles.StepBoxTitle2} ${styles.Heading}`}>PICKUP DETAILS</p>
  <form id="step2-form" onSubmit={handleSubmit(onSubmit)}>
    <div className={`${styles.FormRow}`}>
      <div className={`${styles.FormRowHS}`}>
        <div className={`${styles.FormRowHSFull} ${styles.FormInsideRow}`}>
            <div className={`${styles.FormField}`}>
              <label>Name<span>*</span></label>
              <input type="text" placeholder="" className={`${styles.TextField}`} {...register('name',{required:'Please enter name'})} />
              {errors.name && <p className={`${styles.ErrorM}`}>{errors.name.message}</p>}
            </div>
          </div>

          <div className={`${styles.FormRowHSFull}`}>
            <div className={`${styles.FormField}`}>
              <label>Pickup Email Address<span>*</span></label>
              <input type="text" className={`${styles.TextField}`} {...register('email',{required:'Please enter email',pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
              }})} />
              {errors.email && <p className={`${styles.ErrorM}`}>{errors.email.message}</p>}
            </div>
          </div>

          <div className={`${styles.FormRowHSFull}`}>
            <div className={`${styles.FormField}`}>
              <label>Contact No<span>*</span></label>
              <Controller
                control={control}
                {...register('phone',{required:'Please enter phone no',validate:isValidPhoneNumber})}
                className={`${styles.TextField}`}
                render={({ field }) => (
                    <Input 
                      {...field}
                      country="GB"
                      international
                      withCountryCallingCode
                      className={`${styles.TextField}`}
                    />
                  )}
                />
              {(errors.phone && errors.phone.type !== "validate") && <p className={`${styles.ErrorM}`}>{errors.phone.message}</p>}
              {(errors.phone && errors.phone.type === "validate") && <p className={`${styles.ErrorM}`}>Please enter valid phone</p>}
            </div>
          </div>
        {fields1.map((item, index) => {
          return (<div className={`${styles.FormRowHSFull}`} key={item.id}>
              <div className={`${styles.FormField}`}>
                <label>Alt Contact No</label>
                <div className={`${styles.AltConSec}`}>
                <Controller
                  key={item.id}
                  control={control}
                  {...register(`alt_contact_nos[${index}].value`,{required:'Please enter phone no',validate:isValidPhoneNumber})}
                  className={`${styles.TextField}`}
                  render={({ field }) => (
                      <Input 
                        {...field}
                        country="GB"
                        international
                        withCountryCallingCode
                        className={`${styles.TextField}`}
                      />
                    )}
                  />
                  <button onClick={remove1} style={{position:'absolute', right:0, borderRadius: '0 6px 6px 0'}}><Trash2/></button>
                </div>
              {(errors?.alt_contact_nos?.length > 0 && errors?.alt_contact_nos[index]?.value && errors?.alt_contact_nos[index]?.value?.type !== "validate") && <p className={`${styles.ErrorM}`}>{errors?.alt_contact_nos[index]?.value?.message}</p>}
              {(errors?.alt_contact_nos?.length > 0 && errors?.alt_contact_nos[index]?.value && errors?.alt_contact_nos[index]?.value?.type === "validate") && <p className={`${styles.ErrorM}`}>Please enter valid phone</p>}
              </div>
            </div>)
        })}

        <button type='button' className={`${styles.FormLink}`} onClick={() => { append1({ value: '' }); }}><Plus/> Alt contact no.</button>
        
        <div className={`${styles.FormRowHSFull}`}>
          <div className={`${styles.FormField}`}>
            <label>Address<span>*</span></label>
            <div className={`${styles.AllAddSec}`}>
              <Controller
                control={control}
                {...register(`address`,{required:'Please enter address'})}
                autoComplete="off"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    className={`${styles.TextField} ${styles.TextFieldAutoComplete}`}
                    apiKey={process.env.REACT_APP_MAP_API_KEY}
                    onPlaceSelected={(place) => {
                      setValue('address',place.formatted_address);
                      setValue('latitude',place.geometry.location.lat());
                      setValue('longitude',place.geometry.location.lng());

                     setMapCenter({ lat: parseFloat(place.geometry.location.lat()), lng: parseFloat(place.geometry.location.lng()) });
                     setIsMarker(true);
                     setZoom(18);

                      place.address_components.forEach(item=>{
                        if(item.types.indexOf('postal_code') > -1){
                          setValue('post_code',item.long_name);
                        }
                      })
                    }}
                    options={{
                      types: ["address"],
                      componentRestrictions: { country: "uk" },
                    }}
                  />
                )}
              />
            </div>
            {(errors?.address) && <p className={`${styles.ErrorM}`}>{errors?.address?.message}</p>}
          </div>
        </div>

        <div className={`${styles.FormRowHSFull}`}>
          <div className={`${styles.FormField}`}>
            <label>Post Code<span>*</span></label>
            <input type="text" className={`${styles.TextField}`} {...register('post_code',{required:'Please enter post code'})} />
            {errors.post_code && <p className={`${styles.ErrorM}`}>{errors.post_code.message}</p>}
          </div>
        </div>
      </div>
      
      <div className={`${styles.FormRowHS}`}>
        <div className={`${styles.FormRowHSFull}`}>
          <div className={`${styles.FormField}`}>
            <label>Add pickup location<span>*</span></label>
            <MapComponent center={mapCenter} zoom={zoom} isMarker={isMarker} />
          </div>
        </div>
        <div className={`${styles.FormRowHSFull}`}>
          <div className={`${styles.FormField}`}>
            <div className={`${styles.FormInsideRow}`}>
              <input type="text" placeholder="Please enter post code" className={`${styles.TextField}`} />
              <button className={`${styles.FindLocationBU}`}>Find Location</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  </>
}