import { Navigate } from "react-router-dom";
const withAdminAuth = (WrappedComponent) => {
  return (props) => {
    if (typeof window !== "undefined") {
      const authData = localStorage.getItem("authData");
      if(authData){
        let authDataArr= JSON.parse(authData);
        if(authDataArr?.user_type === 'Customer'){
          if(props?.userScope === 'Customer'){
            return <WrappedComponent {...props} />;
          }
          return <Navigate to={'/customer/my-bookings'} />;
        }else{
          if(props?.userScope === 'Admin'){
            return <WrappedComponent {...props} />;
          }
          return <Navigate to={'/admin/dashboard'} />;
        }
      }else{
        return <Navigate to={'/sign-in'} />
      }
    }
    return null;
  };
};

export default withAdminAuth;