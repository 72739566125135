import React from 'react';
import moment from 'moment';

export const ComponentToPrintList = React.forwardRef((props, ref) => {
  return (<div ref={ref} style={{padding: '20px 20px'}}>
    
    <table>
      <tr>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Post Code</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Booking Id</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Customer Name</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Pickup Address</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Order Status</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Contact No</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Pickup Date</th>
      </tr>
      {props?.data?.map(row=>{
        return <tr>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.post_code}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.reference_id}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.customer_name}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.pickup_details?.address}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{((row?.payment_status === 1 || row?.payment_status === '1') ? 'Paid':'Unpaid')}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row?.pickup_details?.phone}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{moment(row.pickup_date).format('DD/MM/YYYY')}</td>
        </tr>
      })}
    </table>


  </div>);
});