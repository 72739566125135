import React from 'react';
import styles from './track-booking.module.css';
import withCustomerAuth from "../../components/withCustomerAuth";
import { useParams, useNavigate } from 'react-router-dom';
import SideBar from "../../components/sideBar";
import Loader from  '../../../admin/components/Loader';
import axios from 'axios';
import {authData} from "../../../admin/components/getAuth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

function TrackBooking() {
  const auth = authData();
  const navigate = useNavigate();
  const { bookigId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [bookigIdNew, setBookigIdNew] = React.useState('');
  const [bookingData, setBookingData] = React.useState({});
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [NoData, setNoData] = React.useState(false);
  const [TrackStatus, setTrackStatus] = React.useState({'booked':{status:0,date:null},'picked':{status:0,date:null},'transit':{status:0,date:null},'deliverred':{status:0,date:null}});

  React.useEffect(()=>{
    if(bookigId){
      setLoading(true);
      setBookigIdNew(bookigId);

      axios.get(process.env.REACT_APP_API_URL + 'v1/customer-booking-details/'+bookigId, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        setIsLoaded(true);
        if(response.data.status === 'SUCCESS'){
          setBookingData(response.data.data);
          setNoData(false);

          let bookedSt = {status:0,date:null};
          let pickedSt = {status:0,date:null};
          let transitSt = {status:0,date:null};
          let deliverredSt = {status:0,date:null};
          response.data.data?.tracking_details?.map(item=>{
            if(item?.status === 0){
              bookedSt = {status:1, date: item?.datetime};
            }
            if(item?.status === 2){
              pickedSt = {status:1, date: item?.datetime};
            }
            if(item?.status === 4){
              transitSt = {status:1, date: item?.datetime};
            }
            if(item?.status === 7){
              deliverredSt = {status:1, date: item?.datetime};
            }
            return false;
          });

          setTrackStatus({'booked':bookedSt,'picked':pickedSt,'transit':transitSt,'deliverred':deliverredSt});
        }
        if(response.data.status === 'EROOR'){
          setNoData(true);
          toast.info(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      }).catch(error=>{
        setLoading(false);
        setIsLoaded(true);
      })
    }
  },[bookigId,auth?.api_token]);

  const searchBooking = () =>{
    navigate('/customer/track-booking/'+bookigIdNew);
  }
  
  return (
    <>
    {loading && <Loader />}
    <div className={`${styles.MainDivCustomer}`}>
      <img src="/images/booking-bg.jpg" alt="" className={`${styles.BackGroundGra}`} />
      <div className={`${styles.Container}`}>
        <div className={`${styles.CusRow}`}>
          <div className={`${styles.CustomerSideBar}`}><SideBar/></div>
          <div className={`${styles.CustomerDeSec}`}>
            <div className={`${styles.UserProCard2}`}>
              
              <div className={`${styles.HalfSec}`}>
                <p className={`${styles.Heading} ${styles.HeadingTT}`}>Order Details</p>
                <div className={`${styles.SearchSec}`}>
                  <input type='text' placeholder='Enter Your Booking Id' value={bookigIdNew} onChange={(e)=>setBookigIdNew(e.target.value)} />
                  <button onClick={searchBooking}>Search</button>
                </div>
                {(isLoaded && !NoData) && <><p className={`${styles.TitleRe}`}>Search Results :</p>
                <ul className={`${styles.SearchDe}`}>
                  <li><span>Order ID :</span> {bookingData?.reference_id}</li>
                  <li><span>Order placed on  :</span> {moment(bookingData?.created_at).format('DD MMM YYYY')}</li>
                  <li><span>Name :</span> {bookingData?.pickup_details?.name}</li>
                  <li><span>Address :</span> {bookingData?.pickup_details?.address}</li>
                  <li><span>Post Code :</span> {bookingData?.pickup_details?.post_code}</li>
                  <li><span>Contact No :</span> {bookingData?.pickup_details?.phone}</li>
                  {bookingData?.pickup_details?.alt_phones?.map(item2=>{
                    return (<li><span>Alt Contact No :</span> {item2}</li>);
                  })}
                </ul></>}
              </div>

              {(isLoaded && !NoData) && <div className={`${styles.HalfSecTwo}`}>
                <p className={`${styles.Heading} ${styles.HeadingTT}`}>Shipment Tracking </p>
                <p className={`${styles.EstiTitle}`}>{TrackStatus?.deliverred?.status === 1?'Deliverred At':'Estimated Delivery Date' }</p>
                <p className={`${styles.TrasitDT}`}>{moment(bookingData?.estimated_delivery_date).format('dddd')}</p>
                <p className={`${styles.TrasitDMT}`}>{moment(bookingData?.estimated_delivery_date).format('MMM')}</p>
                <p className={`${styles.TrasitDateT}`}>{moment(bookingData?.estimated_delivery_date).format('DD')}</p>
                {(bookingData?.status === -1 || bookingData?.status === '-1') ? <p className={`${styles.TrasitStatusT}`}>Status : <span className={`${styles.SpanCancel}`}>CANCELLED</span></p>:(TrackStatus?.deliverred?.status ===1 ? <p className={`${styles.TrasitStatusT}`}>Status : <span className={`${styles.SpanDelivered}`}>DELIVERRED</span></p>:(TrackStatus?.transit?.status ===1 ? <p className={`${styles.TrasitStatusT}`}>Status : <span className={`${styles.SpanShipped}`}>TRANSIT</span></p>:(TrackStatus?.picked?.status ===1 ? <p className={`${styles.TrasitStatusT}`}>Status : <span className={`${styles.SpanBooked}`}>PICKED UP</span></p>:<p className={`${styles.TrasitStatusT}`}>Status : <span className={`${styles.SpanBooked}`}>BOOKED</span></p>)))}

                {(bookingData?.status === -1 || bookingData?.status === '-1') && <ul className={`${styles.StatusUL}`}>
                  {bookingData?.tracking_details?.map(item=>{
                    if(item?.status === 0)
                      return ( <li className={`${styles.Complete}`}>
                      <p className={`${styles.StatTT}`}>Ordered</p>
                      <p className={`${styles.StatTTN}`}>{moment(item?.datetime).format('DD MMM YYYY LT')}</p>
                    </li>);
                    if(item?.status === -1)
                      return ( <li className={`${styles.Complete}`}>
                      <p className={`${styles.StatTT}`}>Cancelled</p>
                      <p className={`${styles.StatTTNB}`}>Status :</p>
                      <p className={`${styles.StatTTN}`}>{item?.description}</p>
                      <p className={`${styles.StatTTN}`}>{moment(item?.datetime).format('DD MMM YYYY LT')}</p>
                    </li>);
                    return null;
                  })}
                </ul>}

                {(bookingData?.status !== -1 && bookingData?.status !== '-1') && <ul className={`${styles.StatusUL}`}>
                  {TrackStatus?.booked?.status === 1 ? <li className={`${styles.Complete}`}>
                      <p className={`${styles.StatTT}`}>Ordered</p>
                      <p className={`${styles.StatTTN}`}>{moment(TrackStatus?.booked?.date).format('DD MMM YYYY')}</p>
                    </li>:<li className={`${styles.StatTT}`}>
                      <p className={`${styles.StatTT}`}>Ordered</p>
                    </li>}
                    {TrackStatus?.picked?.status === 1 ? <li className={`${styles.Complete}`}>
                      <p className={`${styles.StatTT}`}>Picked Up</p>
                      <p className={`${styles.StatTTN}`}>{moment(TrackStatus?.picked?.date).format('DD MMM YYYY')}</p>
                    </li>:<li className={`${styles.StatTT}`}>
                      <p className={`${styles.StatTT}`}>Picked Up</p>
                    </li>}
                    {TrackStatus?.transit?.status === 1 ? <li className={`${styles.Complete}`}>
                      <p className={`${styles.StatTT}`}>In Transit</p>
                      <p className={`${styles.StatTTN}`}>{moment(TrackStatus?.transit?.date).format('DD MMM YYYY')}</p>
                    </li>:<li className={`${styles.StatTT}`}>
                      <p className={`${styles.StatTT}`}>In Transit</p>
                    </li>}
                    {TrackStatus?.deliverred?.status === 1 ? <li className={`${styles.Complete}`}>
                      <p className={`${styles.StatTT}`}>Deliverred</p>
                    </li>:<li className={`${styles.StatTT}`}>
                      <p className={`${styles.StatTT}`}>Deliverred</p>
                    </li>}
                </ul>}
                
              </div>}

              {(isLoaded && !NoData && bookingData?.products?.length) && <div className={`${styles.StatFullSec}`}>
                <p className={`${styles.Heading} ${styles.HeadingTT}`}>Order Details</p>
                <div className={`${styles.TableSec}`}>
                  <table>
                    <tr>
                      <td className={`${styles.TableHead}`}>Pickup Id</td>
                      <td className={`${styles.TableHead}`}>Item</td>
                      <td className={`${styles.TableHead}`}>Fragile</td>
                      <td className={`${styles.TableHead}`}>Estimated Value</td>
                    </tr>
                    {bookingData?.products?.map((item,index)=>{
                      return (<tr key={index}>
                        <td className={`${styles.TableCon}`}>{item?.item_id}</td>
                        <td className={`${styles.TableCon}`}>{item?.product_title_new}</td>
                        <td className={`${styles.TableCon}`}>{item?.is_fragile ? 'Yes' : 'No'}</td>
                        <td className={`${styles.TableCon}`}>£{parseFloat(item?.sub_amount)?.toFixed(2)}</td>
                      </tr>);
                    })}
                  </table>
                </div>
              </div>}

              {NoData && <div className={`${styles.NoData}`}>
                <img src="/images/no-connection.png" alt="" />
                <p>No data found</p>
              </div>}
              
            </div>
          </div>
        </div>        
      </div>
    </div>
    </>
  );
}

export default withCustomerAuth(TrackBooking);