import React from 'react';
import styles from './container.module.css';
import { TextField, Button, Tooltip, Breadcrumbs, Typography  } from '@mui/material';
import DataTable from 'react-data-table-component';
import {  X, ChevronDown, Edit, Trash2, Plus } from 'react-feather';
import axios from 'axios';
import { TableLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import withAdminAuth from "../../components/withAdminAuth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dropdown } from 'react-bootstrap';
import ContainerAddForm from './addContainer';

class CountainerList extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.state = { allData: [], data: [],filterText:'', modalShow:false, modalShow2:false, loading:false, contentLoading:true, currentItem:0, currentItemStatus:0, addModalShow: false, editModalShow:false  };

    this.audience = process.env.REACT_APP_API_URL;
    this.app_folder_path = process.env.REACT_APP_FOLDER_PATH;
    this.authData = authData();

    this.columns = [
      {
        name: 'Reference Id',
        selector: row => row.seal_ref_no,
        sortable: true,
      },
      {
        name: 'Container Number*',
        selector: row => row.container_no,
        sortable: true,
      },
      {
        name: 'Freight Forwarder',
        selector: row => row.freight,
        sortable: true,
      },
      {
        name: 'Container Address',
        selector: row => row.address,
        sortable: true,
      },
      {
        name: 'Contact Person Number',
        selector: row => row.contact_no,
        sortable: true,
      },
      {
        name: 'Status',
        cell: tableProps  => ((tableProps.status === 1 || tableProps.status === "1") ? <Dropdown className="StatusDropDown">
          <Dropdown.Toggle className={`${styles.ActiveBU}`} id={"dropdown-basic"+tableProps.id} size="sm">Active <ChevronDown /></Dropdown.Toggle>
    
          <Dropdown.Menu>
            <Dropdown.Item onClick={this.statusChange.bind(this,tableProps.id,0)}>Archived</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> :<Dropdown className="StatusDropDown">
          <Dropdown.Toggle className={`${styles.ArchiveBU}`} id={"dropdown-basic"+tableProps.id} size="sm">Archived <ChevronDown /></Dropdown.Toggle>
    
          <Dropdown.Menu>
            <Dropdown.Item onClick={this.statusChange.bind(this,tableProps.id,1)}>Active</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>),
        sortable: false,
      },
      {
        name: 'Action',
        cell: tableProps  => (
        <div className={`${styles.ActionDiv}`}>
          <Tooltip title="Edit"><Button><Edit onClick={this.openEditForm.bind(this,tableProps.id)} /></Button></Tooltip>
          <Tooltip title="Delete"><Button><Trash2 onClick={this.modalOpen.bind(this,tableProps.id)} /></Button></Tooltip>
        </div> ),
        sortable: false,
      }
    
    ];

    this.customDataTableStyles = {
      head: {
        style: {
          zIndex:0
        },
      },
      headCells: {
        style: {
            backgroundColor: '#1e1e2d',
            color: '#fff'
        },
      },
    } 
  }

  async componentDidMount() {
    try {
      axios(process.env.REACT_APP_API_URL + 'v1/containers', {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        }
      }).then(response => {
        if(response.data.status === 'SUCCESS'){
          let allData = response.data.data;
          this.setState({ ...this.state, allData: allData, data: allData,contentLoading:false});
        }else{
          this.setState({ ...this.state, contentLoading:false});
        }
      }).catch(error=>{
        this.setState({ ...this.state, contentLoading:false});
      });
    } catch (error) {
      this.setState({ ...this.state, contentLoading:false});
    }
  }
  onFilter(e){
    this.setState({ ...this.state, filterText: e.target.value },this.filterHandler);
  }
  clearFilter(){
    this.setState({ ...this.state, filterText: '' },this.filterHandler);
  }
  filterHandler() {
    let filteredData = this.state.allData;

    if (this.state.filterText !== '') {
      let inputVal = this.state.filterText;
      inputVal = inputVal.toLowerCase();
      filteredData = filteredData.filter((item) => {
        return item?.seal_ref_no.toLowerCase().includes(inputVal) || item?.container_no.toLowerCase().includes(inputVal) || item?.freight.toLowerCase().includes(inputVal) || item?.address.toLowerCase().includes(inputVal) || item?.contact_no.toLowerCase().includes(inputVal);
      });
    }

    this.setState({ ...this.state, data: filteredData });
  }
  
  statusChange(id,status){
    this.setState({...this.state,loading:true});
    axios.put(process.env.REACT_APP_API_URL + 'v1/containers/'+id,{status:status}, {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        let allData = this.state.allData;
        let data = this.state.data;
        let allDataTemp = allData.map(i=> {
          if(i.id === id)
            return {...i,status:status}
          return i;
        });
        let dataTemp = data.map(i=> {
          if(i.id === id)
            return {...i,status:status}
          return i;
        });
        this.setState({ ...this.state, allData: allDataTemp, data: dataTemp,loading:false});
      }else{
        this.setState({...this.state,loading:false});
      }
    }).catch(error => {
      this.setState({...this.state,loading:false});
    });
  }

  modalOpen(id){
    this.setState({...this.state,modalShow:true, currentItem:id});
  }

  modalOpen2(id,status){
    this.setState({...this.state,modalShow2:true, currentItem:id, currentItemStatus: status});
  }

  modalClose(){
    this.setState({...this.state, modalShow:false, modalShow2:false, currentItem:0, currentItemStatus:0, addModalShow: false, editModalShow:false});
  }
  
  deleteConfirm(){
    let currentItem = this.state.currentItem;
    this.setState({...this.state,modalShow:false, currentItem:0,loading:true});
    axios.delete(process.env.REACT_APP_API_URL + 'v1/containers/'+currentItem, {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        let allData = this.state.allData;
        let data = this.state.data;
        let allDataTemp = allData.filter(i=> i.id !== currentItem);
        let dataTemp = data.filter(i=> i.id !== currentItem);
        this.setState({ ...this.state, allData: allDataTemp, data: dataTemp,loading:false});
      }else{
        this.setState({...this.state,loading:false});
      }
    }).catch(error => {
      this.setState({...this.state,loading:false});
    });
  }

  openAddForm(){
    this.setState({...this.state, addModalShow:true});
  }

  openEditForm(id){
    this.setState({...this.state, editModalShow:true, currentItem:id});
  }

  onSubmitAddForm(conData){
    axios(process.env.REACT_APP_API_URL + 'v1/containers', {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        let allData = response.data.data;
        this.setState({ ...this.state, allData: allData, data: allData,addModalShow:false,editModalShow:false},this.filterHandler);
      }else{
        this.setState({ ...this.state, addModalShow:false,editModalShow:false});
      }
    })
  }
  
  render() {
    return (
      <>
      {this.state.loading && <Loader />}
      {this.state.contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>}
      {!this.state.contentLoading && <div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>Container List</p>
          <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
            <Typography color="text.primary">Shippings</Typography>
            <Typography color="text.primary">Container</Typography>
            <Typography color="text.primary">List</Typography>
          </Breadcrumbs>
        </div>

        <div className={`${styles.MainCard}`}>
          <div className='TableFilterHead'>
            <div className="DataTableSearch">
              <TextField id="search" type="text" placeholder="Search" aria-label="Search Input" value={this.state.filterText} onChange={this.onFilter.bind(this)} autoComplete='off' />
              {this.state.filterText && <Button type="button"  className="CloseBU" onClick={this.clearFilter.bind(this)}><X/></Button>}
            </div>
            <div className='TableFilterBUSec'>
              <Link className="TableAddBU" onClick={this.openAddForm.bind(this)}><Plus /> Container</Link>
            </div>
          </div>
          
          <div className='TableContent'>
            <DataTable className='DataTable'
              columns={this.columns}
              data={this.state.data}
              pagination
              customStyles={this.customDataTableStyles}
              fixedHeader={true}
		          fixedHeaderScrollHeight={'633px'}
            />
          </div>
        </div>
      </div>}

      <Modal show={this.state.modalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Delete Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='Message'>Are you sure you want to delete this item?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='PopUpCanBU' onClick={this.modalClose.bind(this)}>Cancel</Button>
          <Button className='PopUpSubmitBU' onClick={this.deleteConfirm.bind(this)}>Confirm Delete</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={this.state.addModalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Add Container</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContainerAddForm onSubmit={this.onSubmitAddForm.bind(this)} isAddMode={true} id={this.state.currentItem} modalClose={this.modalClose.bind(this)} />
        </Modal.Body>
      </Modal>

      <Modal show={this.state.editModalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Edit Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContainerAddForm onSubmit={this.onSubmitAddForm.bind(this)} isAddMode={false} id={this.state.currentItem} modalClose={this.modalClose.bind(this)} />
        </Modal.Body>
      </Modal>
    </>
    );
  }
}

export default withAdminAuth(CountainerList);