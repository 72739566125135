import React from 'react';
import moment from 'moment';

export const ComponentToPrintList = React.forwardRef((props, ref) => {
  return (<div ref={ref} style={{padding: '20px 20px'}}>
    
    <table>
      <tr>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Shipping Ref</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Container No</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Freight Name</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Shipping From</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Shipping To</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Shipping Date</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Arrival Date</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Consignee</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Invoice Count</th>
        <th style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>Warehouse</th>
      </tr>
      {props?.data?.map(row=>{
        return <tr style={{background: row?.status === 2 || row?.status === '2' ? 'rgba(5, 156, 42, 0.5)': 'rgba(42, 146, 245, 0.4)'}}>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.reference_id}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.container_name}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.freight}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.shipping_from}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.shipping_to}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.shipping_date ? moment(row.shipping_date).format('DD/MM/YYYY'): '-'}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.arrival_date ? moment(row.arrival_date).format('DD/MM/YYYY'): '-'}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.consignee}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.no_of_invoice}</td>
        <td style={{fontSize:'12px', padding:'5px',borderBottom: '#000 1px solid'}}>{row.warehouse_location}</td>
        </tr>
      })}
    </table>


  </div>);
});