import React, {useState,useEffect} from 'react';
import styles from './addContainer.module.css';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import { FromLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import Select from "react-select";
import { toast } from 'react-toastify';

function ContainerAddForm({ onSubmit,isAddMode, id,modalClose }) {
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [isFreightOther,setIsFreightOther] = React.useState(null);

  const { register, handleSubmit,control, setValue,  formState: { errors } } = useForm({
    mode: 'onChange'
  });

  
  useEffect(()=>{
    if(!isAddMode){
      if(contentLoading){
        axios.get(process.env.REACT_APP_API_URL + 'v1/containers/'+id, {
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
          }
        }).then(response => {
          setContentLoading(false);
          if(response.data.status === 'SUCCESS'){
            let itemData = response.data.data;
            setValue('container_no',itemData.container_no);
            setValue('seal_ref_no',itemData.seal_ref_no);
            setValue('address',itemData.address);
            setValue('contact_no',itemData.contact_no);
            if(itemData.freight === 'Freight' || itemData.freight === 'Bennett McMahon'){
              setValue('freight',{ value: itemData.freight, label: itemData.freight });
            }else{
              setIsFreightOther({ value: 'Other', label: 'Other' });
              setValue('freight',{ value: 'Other', label: 'Other' });
              setValue('freight_other',itemData.freight);
            }
          }
        }).catch(error => {
          setContentLoading(false);
        });
      }
    }else{
      setContentLoading(false);
    }
  },[API_TOKEN,contentLoading,id,isAddMode,setValue]);
  
  async function onSubmitLocal(data) {
    let postData = {seal_ref_no: data?.seal_ref_no,container_no: data?.container_no, freight: data?.freight?.value};
    if(data?.address !== ''){
      postData = {...postData, address:data?.address };
    }
    if(data?.contact_no !== ''){
      postData = {...postData, contact_no: data?.contact_no };
    }
    if(data?.freight?.value === 'Other'){
      postData = {...postData, freight:data?.freight_other };
    }

    setLoading(true);

    if(isAddMode){
      return axios.post(process.env.REACT_APP_API_URL + 'v1/containers', postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          modalClose();
          onSubmit(response.data.data);
        }
      }).catch(error => {
        setLoading(false);
      });
    }else{
      return axios.put(process.env.REACT_APP_API_URL + 'v1/containers/'+id, postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          onSubmit();
        }
      }).catch(error => {
        setLoading(false);
      });
    }
  }
  
  return (
      <React.Fragment>
        {loading && <Loader /> }
        {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div> }
        {!contentLoading && <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmitLocal)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Seal Ref No*</label>
                <input {...register('seal_ref_no',{required:'Please enter Seal Ref No'})} className={`${styles.FormControl}`} autoComplete="off" />
                {errors.seal_ref_no && <span className={`${styles.ErrorM}`}>{errors.seal_ref_no.message}</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Container Number*</label>
                <input {...register('container_no',{required:'Please enter Container Number'})} className={`${styles.FormControl}`} autoComplete="off" />
                {errors.container_no && <span className={`${styles.ErrorM}`}>{errors.container_no.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Container Address</label>
                <input {...register('address')} className={`${styles.FormControl}`} autoComplete="off" />
                {errors.address && <span className={`${styles.ErrorM}`}>{errors.address.message}</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Contact Person Number</label>
                <input {...register('contact_no')} className={`${styles.FormControl}`} autoComplete="off" />
                {errors.contact_no && <span className={`${styles.ErrorM}`}>{errors.contact_no.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Freight Forwarder*</label>
                <Controller
                  control={control}
                  options={[{value: "Freight", label: "Freight"},{value: "Bennett McMahon", label: "Bennett McMahon"},{value: "Other", label: "Other"}]}
                  {...register('freight',{required:'Please select one'})}
                  render={({ field }) => (
                    <Select {...field} onChange={(e)=>{field.onChange(e); setIsFreightOther(e);}} options={[{value: "Freight", label: "Freight"},{value: "Bennett McMahon", label: "Bennett McMahon"},{value: "Other", label: "Other"}]}  />
                  )}
                />
                {errors.freight && <span className={`${styles.ErrorM}`}>{errors.freight.message}</span>}
              </div>
              {(isFreightOther?.value === 'Other') && <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Other:*</label>
                <input {...register("freight_other",{required:'Please enter other freight'})} className={`${styles.FormControl}`} />
                {errors.freight_other && <span className={`${styles.ErrorM}`}>{errors.freight_other.message}</span>}
              </div>}
            </div>
            <div className={`${styles.FormButtonArea}`}>
              <button onClick={modalClose} className={`${styles.CancelBU}`}>Cancel</button>
              <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
            </div>
          </form>
        </div>}
      </React.Fragment>
  )}


export default ContainerAddForm;