import React from 'react';
import styles from './list.module.css';
import moment from 'moment';

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (<div ref={ref} style={{padding: '50px'}}>
    
    <div className={`${styles.PrintClass1}`}>
      <h4>{props?.bookingData?.driver?.name}</h4>
      <h3>{moment(props?.bookingData?.pickup_date).format('dddd, D MMM YYYY')}</h3>
    </div>

    <div className={`${styles.PrintClass2}`}>
      <div className={`${styles.PrintClass3}`}>
        SL No.<br />1
      </div>
      <div className={`${styles.PrintClass4}`}>
        <h4>Booking Ref.</h4>
        <p>{props?.bookingData?.reference_id}</p>
        <br />
        <h4>Total</h4>
        <p>£{parseFloat(props?.bookingData?.total).toFixed(2)}</p>
      </div>
      <div className={`${styles.PrintClass5}`}>
      <h4>{props?.bookingData?.pickup_details?.name}</h4>
        <p>{props?.bookingData?.pickup_details?.phone}</p>
        <br />
        <h5>Pickup Details</h5>
        <p>{props?.bookingData?.pickup_details?.address}</p>
        <div>&nbsp;</div>
        <h4>{props?.bookingData?.dropoff_details?.name}</h4>
        <p>{props?.bookingData?.dropoff_details?.phone}</p>
        <br />
        <h5>Delivery Details</h5>
        <p>{props?.bookingData?.dropoff_details?.address}</p>
      </div>
      <div className={`${styles.PrintClass6}`}>
        <h5>Pickup Items</h5>
        {props?.bookingData?.products2?.map(item=>{
          return <div className={`${styles.PrintClass7}`}>
            <strong>{item.item_id}</strong> {item.product_title_new}
            <div className={`${styles.PrintClass8}`}>{props?.bookingData?.status_text}</div>
          </div>
        })}
      </div>
    </div>

    <div className={`${styles.PrintClass9}`}>
      <h4>Booking Notes.</h4>
      <p>{props?.bookingData?.description}</p>
    </div>

  </div>);
});